import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { Button, Table } from '@themesberg/react-bootstrap'
import { formatNumber } from '../../../../redux/utils'
import { apiURL } from '../../../../redux/api'
import { Routes } from '../../../../routes'



export default function ViewContractor({ match }) {
  const history = useHistory()
  const contractor_code = match.params.contractor_code
  const [paymentScheduleTable, setPaymentScheduleTable] = useState([])
  const [tresuryDetails, setTresuryDetails] = useState({})
  const [changeAmount, setChangeAmount] = useState(false)
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)
  const [amountIndex, setAmountIndex] = useState(0)
  const [form, setForm] = useState({
    batch_number: '',
    date: '',
    no_of_mda: '',
    no_of_payments: '',
    total_amount: '',
    cheque_number: '',
    query_type: 'insert',
    status: 'uncommited',
    amount: '',
  })

  console.log(contractor_code)

  useEffect(() => {
    fetch(`${apiURL}/post_contractor_schedule`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        query_type: 'select_by_contract_id',
        contractor_code,
      }),
    })
      .then(function (response) {
        return response.json()
      })
      .then((data) => {
        console.log('data', data)
        if (data.result) {
          setPaymentScheduleTable(data.result)
        }
      })

      .catch((err) => {
        // alert(err);
        // setLoadSpinner(false);
      })
  }, [contractor_code])

  const handleApi = () => {
    fetch(`${apiURL}/post_payment_schedule_array`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        paymentScheduleTable,
        query_type: 'update',
        status: 'auditor_approved',
      }),
    })
      .then(function (response) {
        return response.json()
      })
      .then((data) => {
        if (data.success) {
          alert('Auditor Approved Successfully')
          history.push('/dashboard/intAudit')
        }
      })

      .catch((err) => {
        alert(err)
      })
  }

  const handleAllReturned = () => {
    fetch(`${apiURL}/post_payment_schedule_array`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        paymentScheduleTable,
        query_type: 'update',
        status: 'returned',
      }),
    })
      .then(function (response) {
        return response.json()
      })
      .then((data) => {
        if (data.success) {
          console.log(data)
          history.push('/dashboard/intAudit')
        }
      })

      .catch((err) => {
        alert(err)
      })
  }

  const submitIndividualItem = (id) => {
    const newArray = paymentScheduleTable.filter((itm, idx) => itm.id === id)
    console.log('newArray', newArray)
    fetch(`${apiURL}/post_payment_schedule_array`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        paymentScheduleTable: newArray,
        query_type: 'update',
        status: 'returned',
      }),
    })
      .then(function (response) {
        return response.json()
      })
      .then((data) => {
        if (data.success) {
          console.log(data)
          history.push('/dashboard/intAudit')
        }
      })

      .catch((err) => {
        alert(err)
      })
  }

  const changeAmountColumn = (id) => {
    setChangeAmount(true)
  }

  const handleChange = (id, name, value, e) => {
    // const {name, value} = e.target

    const value1 = addCommas(removeNonNumeric(value))
    // const value1 = addCommas(value)
    const newArray = []

    paymentScheduleTable.forEach((itm, idx) => {
      if (itm.id === id) {
        newArray.push({ ...itm, [name]: value })
      } else {
        newArray.push(itm)
      }
    })

    setPaymentScheduleTable(newArray)
  }

  //   useEffect(() => {
  // fetch(`${api}/get_batch_list`, {
  //   method: "POST",
  //   headers: {
  //     "Content-type": "application/json",
  //   },
  //   body: JSON.stringify({
  //     query_type : 'select_payment_list',
  //     status : 'pending',

  //   }),
  // }).then(function (response) {
  //   return response.json();
  // })
  //   .then((data) => {
  //     console.log("gg", data)
  //    if(data.result){
  //     // setPaymentDetails(data.result)
  //     const formData = data.result[0]
  //     setForm((p) => ({...p, ...formData }))
  //     // setPaymentScheduleTable(data.result);

  //    }
  //   })

  //   .catch((err) => {
  //     // alert(err);
  //     // setLoadSpinner(false);
  //   });
  // }, [])

  const num = 679909

  const addCommas = (num) =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, '')
  return (
    <>
      <Button
        onClick={() => history.goBack()}
        // style={{ backgroundColor: '#FE9C40', color: 'white' }}
      >
        Back
      </Button>

      <div className="card">
        <div
          className="card-header text-center"
        //   style={{ backgroundColor: '#FE9C40', color: 'white' }}
        >
          <h5>Contractor Details</h5>
        </div>
        <div className="card-body">
          <Table className="file-list" responsive>
            <thead 
            // style={{ backgroundColor: '#FE9C40', color: 'white' }}
            >
                 <th
                className="actn"
                // style={{ backgroundColor: '#FE9C40', color: 'white' }}
              >
                Action
              </th>
          
              <th
                className="mda"
                // style={{ backgroundColor: '#FE9C40', color: 'white' }}
              >
                Contractor
              </th>
              <th
                className="description"
                // style={{ backgroundColor: '#FE9C40', color: 'white' }}
              >
                MDA's
              </th>
              <th
                className="accn0"
                // style={{ backgroundColor: '#FE9C40', color: 'white' }}
              >
                Project Type
              </th>
              <th
                className="accn0"
                // style={{ backgroundColor: '#FE9C40', color: 'white' }}
              >
                Payment Type
              </th>

              <th
                className="amount"
                // style={{ backgroundColor: '#FE9C40', color: 'white' }}
              >
                Amount
              </th>
             
            </thead>
            <tbody>
              {paymentScheduleTable.map((file, index) => (
                <tr className="file-list-item" key={index}>
                  <td className="reject">
                    <Button
                      className="btn-success mx-1"
                      size="sm"
                      onClick={() =>
                        history.push(
                            `${Routes.paymentSchedule.viewStScheduleDetails}/${file.contract_code}`
                        )
                      }
                    >
                      View
                    </Button>
                  </td> 

                  <td className="batch-number">{file.contractor}</td>
                  <td className="MDA">{file.mda_name}</td>

                  <td className="MDA">{file.project_type}</td>

                  <td className="MDA">{file.payment_type}</td>

                  <td className="MDA">{formatNumber(file.amount)}</td>

                
                </tr>
              ))}
            </tbody>{' '}
          </Table>
          <br />
        </div>
      </div>
    </>
  )
}
