import { postApi } from '../../../redux/api'

export function getApprovalCollectList(
  form = { query_type: 'select' },
  callback = (f) => f,
  error = (f) => f,
) {
  postApi(`post_approval_collection`, {
    form,
  })
    .then((data) => {
      callback(data)
    })
    .catch((err) => {
      console.log(err)
      error(err)
    })
}
