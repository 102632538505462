import React from 'react'
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
  LineChart,
} from 'recharts'

const colors = ['#F2BA4B', '#5887B8']

function LineChartComponent({ data = [], bars = [] }) {
  // Custom tick formatter function
  const formatYTick = (value) => {
    if (value >= 1000000000000) {
      return (value / 1000000000000).toFixed(1) + 'T'
    } else if (value >= 1000000000) {
      return (value / 1000000000).toFixed(1) + 'B'
    } else if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + 'M'
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + 'K'
    }
    return value
  }

  const formatXTick = (value) => {
    const maxLength = 7
    if (value.length > maxLength) {
      const words = value.split(' ')
      const abbreviated = words.map((word) => word.charAt(0)).join('')
      //   return abbreviated
      return value.substring(0, maxLength) + '...'
    }
    return value
  }

  const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value, data } = props
    const radius = 10

    return (
      <g>
        {/* <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" /> */}
        <text
          x={x + width / 2}
          y={y - radius}
          fill="#000"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {value}
        </text>
      </g>
    )
  }

  return (
    <div className="bg-light py-2">
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          //   right: 30,
          left: 10,
          bottom: 5,
        }}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis dataKey="name" tickFormatter={formatXTick} />
        <YAxis tickFormatter={formatYTick} />
        <Tooltip formatter={formatYTick} />
        <Legend />
        {bars.map((b, i) => {
          if (b.hide) return null
          return (
            <Line dataKey={b.title} fill={colors[i]}>
              {/* {b.label ? <LabelList dataKey={b.title} content={renderCustomizedLabel} /> : null} */}
            </Line>
          )
        })}

        {/* <Bar dataKey="2023" fill="#2db200" /> */}
      </LineChart>
    </div>
  )
}

export default LineChartComponent
