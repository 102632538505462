import { Form } from "@themesberg/react-bootstrap";
import React from "react";

function RadioBox(props) {
  const { container = "", label = "" } = props;

  return (
    <div className={`custom-control custom-radio mb-3 ${container}`}>
      <Form.Check
        {...props}
        className="custom-control-input custom-control-input-default mr-2"
        id={`${props.label}${props.name}-1`}
        type="radio"
      /> 
      <Form.Label className="custom-control-label" htmlFor={`${props.label}${props.name}-1`}>
      {/* {"   "} {label} */}
      </Form.Label>
    </div>
    // <Label
    //   className={`font-weight-bold ${container}`}
    //   style={{ cursor: "pointer" }}
    // >
    //   <Input type="radio" />
    //   {label}
    // </Label>
  );
}

export default RadioBox;
