import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleLeft,
  faEnvelope,
  faUnlockAlt,
} from '@fortawesome/free-solid-svg-icons'
import {
  faFacebookF,
  faGithub,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons'
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  InputGroup,
  Image,
} from '@themesberg/react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import logo from '../../assets/img/state-govt-logo.png'
import { Routes } from '../../routes'
import { useDispatch } from 'react-redux'
import CustomButton from '../../components/CustomButton'
import { login } from '../../redux/actions/auth'
import BgImage from '../../assets/img/state-govt-logo.png'
// import BgImage from '../../assets/img/illustrations/signin.svg'

export default () => {
  const [form, setForm] = useState({})
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()

  const handleChange = ({ target: { name, value } }) =>
    setForm((prev) => ({ ...prev, [name]: value }))

  const handleSubmit = (e) => {
    e.preventDefault()

    setLoading(true)

    dispatch(
      login(
        form,
        (user) => {
          setLoading(false)
          console.log(user)
          // if (user.role === "main_treasury") {
          history.push(Routes.DashboardOverview.path)
          // } else {
          //   history.push("/dashboard/contract_schedule_List");
          // }
        },
        () => {
          setLoading(false)
        },
      ),
    )
  }

  return (
    <main>
      <img
        src={logo}
        style={{
          width: '100%',
          height: '100vh',
          opacity: 0.2,
          position: 'absolute',
          alignItems: 'center',
          zIndex: -1,
        }}
        className="img-fluid"
        alt="logo-img"
      />
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          {/* <p className="text-center">
            <Card.Link as={Link} to={Routes.DashboardOverview.path} className="text-gray-700">
              <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to homepage
            </Card.Link>
          </p> */}
          <Row
            className="justify-content-center form-bg-image"
            // style={{ backgroundImage: `url(${BgImage})`, }}
          >
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white   shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <Image
                    src={logo}
                    style={{
                      borderRadius: 50,
                      height: 100,
                      width: 100,
                      marginTop: -120,
                    }}
                  />
                  <h5 className="mb-0 mt-2">
                    TREASURY - BUDGET EXPENDITURE AND ACCOUNTABILITY MANAGEMENT
                    SYSTEM (T-BEAMS)
                  </h5>
                </div>
                <Form className="mt-4" onSubmit={handleSubmit}>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Your Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control
                        value={form.username}
                        name="username"
                        onChange={handleChange}
                        autoFocus
                        required
                        type="text"
                        placeholder="johndoe"
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Your Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          value={form.password}
                          name="password"
                          onChange={handleChange}
                          type="password"
                          placeholder="Password"
                        />
                      </InputGroup>
                    </Form.Group>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <Form.Check type="checkbox">
                        <FormCheck.Input id="defaultCheck5" className="me-2" />
                        <FormCheck.Label
                          htmlFor="defaultCheck5"
                          className="mb-0"
                        >
                          Remember me
                        </FormCheck.Label>
                      </Form.Check>
                      <Card.Link className="small text-end">
                        Lost password?
                      </Card.Link>
                    </div>
                  </Form.Group>
                  <CustomButton
                    loading={loading}
                    variant="primary"
                    type="submit"
                    className="w-100"
                  >
                    Sign in
                  </CustomButton>
                </Form>

                {/* <div className="mt-3 mb-4 text-center">
                  <span className="fw-normal">or login with</span>
                </div> */}
                {/* <div className="d-flex justify-content-center my-4">
                  <Button variant="outline-light" className="btn-icon-only btn-pill text-facebook me-2">
                    <FontAwesomeIcon icon={faFacebookF} />
                  </Button>
                  <Button variant="outline-light" className="btn-icon-only btn-pill text-twitter me-2">
                    <FontAwesomeIcon icon={faTwitter} />
                  </Button>
                  <Button variant="outline-light" className="btn-icon-only btn-pil text-dark">
                    <FontAwesomeIcon icon={faGithub} />
                  </Button>
                </div> */}
                {/* <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Not registered?
                    <Card.Link
                      as={Link}
                      to={Routes.Signup.path}
                      className="fw-bold"
                    >
                      {` Create account `}
                    </Card.Link>
                  </span>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  )
}
