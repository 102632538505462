import React, { useCallback, useEffect, useRef, useState } from 'react'
import moment from 'moment-timezone'
import Datetime from 'react-datetime'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  InputGroup,
} from '@themesberg/react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'
import useQuery from '../../../hooks/useQuery'
import CustomForm from '../../../components/CustomForm'
import { useDropzone } from 'react-dropzone'
import { fetchApi, postApi } from '../../../redux/api'
import BatchModal from './components/BatchModal'
import { Typeahead } from 'react-bootstrap-typeahead'
import { formatNumber } from '../../../redux/utils'
import Attachment from '../../../components/Attachment'
import TsaFundingBatchList from './components/TsaFundingBatchList'
import BackButton from '../../../components/BackButton'
import RadioGroup from '../../../components/RadioGroup'

export default ({ match }) => {
  const today = moment().format('YYYY-MM-DD')
  const query = useQuery()
  const { types } = useParams()
  const [treasuryAccount, setTreasuryAccount] = useState([])
  const [
    ,
    // mdaAccount
    setMdaAccount,
  ] = useState([])

  const [
    // economicCode
    setEconomicCode,
  ] = useState([])

  const [batchCode, setBatchCode] = useState('')
  const tresuryTypeahead = useRef()
  const mdaCodeTypeahead = useRef()
  const [
    mdaBudgetBalance,
    // ,setMdaBudgetBalance
  ] = useState(true)
  const [modal, setModal] = useState(false)
  const [mdaSourceAcctlist, setMdaSourceAcctlist] = useState([])
  const history = useHistory()
  const [faacLists, setFaacList] = useState([])
  const [loading, setLoading] = useState(false)
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: 'image/*, .pdf, text/plain' })

  const toggle = () => {
    setModal(!modal)
    if (modal === true) {
      history.goBack()
    }
  }

  const [form, setForm] = useState({
    reference_number: '',
    fund_date: today,
    mda_source_account: '',
    mda_account_no: '',
    mda_bank_name: '',
    mda_sort_code: '',
    treasury_source_account: '',
    treasury_account_name: '',
    treasury_account_no: '',
    treasury_bank_name: '',
    amount: '',
    accType: 'FAAC',
    types,
  })

  const [tsaBatch, setTsaBatch] = useState([])

  const id = match.params.id

  const param = Object.entries(match.params).length

  const updateBudgetCode = (description, query_type) => {
    postApi(`update-budget-code`, {
      description,
      query_type,
    })
      .then((d) => {
        console.log(d)
        if (d.result) {
          setBatchCode(d.reference_number)
          console.log('batchCode', batchCode)
        }
      })
      .catch((err) => console.log(err))
  }

  const getList = () => {
    postApi(`get_mdabank_details?query_type=select`)
      .then((d) => {
        console.log('d', d)
        if (d.result) {
          setMdaSourceAcctlist(d.result)
        }
      })
      .catch((err) => console.log(err))
  }

  const getTSAcode = useCallback(() => {
    fetchApi(`tsa-code?types=${form.accType}`)
      .then((d) => {
        console.log('d', d)
        if (d.results) {
          setFaacList(d.results)
        }
      })
      .catch((err) => console.log(err))
  }, [form.accType])

  useEffect(() => {
    getList()
    getTSAcode()
  }, [getTSAcode])

  const handleChange = ({ target: { name, value } }) => {
    setForm((prev) => ({ ...prev, [name]: value }))
  }

  const handleReset = () => {
    setForm((p) => ({
      ...p,

      mda_source_account: '',
      mda_account_no: '',
      mda_bank_name: '',
      mda_sort_code: '',
      amount: '',
    }))

    // clearEconomicTypeahead()
    // clearMdaTypeahead()
    clearMdaCodeTypeahead()
  }
  console.log(match)

  const clearMdaCodeTypeahead = () => {
    mdaCodeTypeahead.current.clear()
  }

  const handleTresuryAcct = (selected) => {
    if (selected.length) {
      console.log(selected)
      const selectedItem = selected[0]
      // setOptions(selected)
      setForm((p) => ({
        ...p,
        treasury_account_name: selectedItem.account_name,
        treasury_account_no: selectedItem.account_number,
        treasury_bank_name: selectedItem.bank_name,
        treasury_source_account: selectedItem.account_info,
      }))
      // const group_id =  selected[0].group_id
      // fetchEventList(group_id)
    } else {
      console.log('no item selected')
    }
    console.log(selected)
  }

  const handleApi = () => {
    setLoading(true)
    for (let i = 0; i < tsaBatch.length; i++) {
      const { formData, ...otherData } = tsaBatch[i]
    }
    console.log(tsaBatch, 'LSLLS')
    postApi(`post_tsa_funding`, {
      tsaBatch,
      query_type: 'insert',
      // status: 'pending',
    })
      .then((data) => {
        updateBudgetCode('reference_number', 'update')
        setBatchCode(data.reference_number)
        console.log(data)
        toggle()
        handleReset()
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  useEffect(() => {
    postApi(`select_mda_bank_details/${id}`, {
      query_type: 'select_by_id',
    }).then((data) => {
      console.log(data)
      if (data?.result?.length) {
        setForm(data.result[0])
      }
    })
  }, [id])

  useEffect(() => {
    postApi(`select_mda_bank_details`, {
      query_type: 'select',
    }).then((data) => {
      if (data.result) {
        console.log('tty', data.result)

        const treasuryAccount = data.result.filter(
          (item) => item.account_type === 'treasury_source_account',
        )
        setTreasuryAccount(treasuryAccount)

        const mdaAccount = data.result.filter(
          (item) => item.account_type === 'mda_source_account',
        )
        setMdaAccount(mdaAccount)
      }
    })
  }, [])

  const handleDelete = (index) => {
    const newArray = tsaBatch.filter((itm, idx) => index !== idx)
    setTsaBatch(newArray)
  }

  const handleEdit = (index) => {
    const newArray = tsaBatch.filter((itm, idx) => index === idx)
    // form(newArray[0])
    console.log(newArray[0])
  }

  const handleArray = () => {
    const formData = new FormData()
    for (let i = 0; i < acceptedFiles.length; i++) {
      formData.append('files', acceptedFiles[i])
    }
    formData.append('treasury_account_name', form.treasury_account_name)
    formData.append('description', form.description)
    formData.append('fund_date', form.fund_date)

    setTsaBatch([...tsaBatch, { ...form, formData }])
    handleReset()
  }

  const getList2 = useCallback(
    (mda_code) => {
      fetchApi(
        `get-budget-summary?query_type=select_economic_code&&mda_code=${mda_code}`,
      )
        .then((raw) => raw.json())
        .then((d) => {
          console.log(d)
          if (d.result) {
            console.log(d)
            setEconomicCode(d.result)
          }
        })
        .catch((err) => console.log(err))
    },
    [setEconomicCode],
  )

  const handleMdaCode = (selected) => {
    if (selected.length) {
      console.log('selected', selected)
      const selectedItem = selected[0]
      // setOptions(selected)
      setForm((p) => ({
        ...p,
        mda_source_account: selectedItem.account_name,
        mda_account_no: selectedItem.account_number,
        mda_bank_name: selectedItem.bank_name,
        mda_sort_code: selectedItem.sort_code,
        // : selectedItem.mda_name,
        // mda_economic_code : selectedItem.economic_code
      }))

      getList2(selectedItem.mda_code)
    } else {
      console.log('no item selected')
    }
  }

  const radioType = types === 'FAAC TSA Funding' ? 'FAAC' : 'JAAC'

  return (
    <>
      <BackButton />

      <Card border="light" className="bg-white shadow-sm mb-4">
        <Card.Body>
          <h5 className="mb-4">{types}</h5>

          <BatchModal toggle={toggle} modal={modal} batchCode={batchCode} />

          {/* {JSON.stringify(faacLists)} */}
          <form className="form-group">
            <Row>
              <Col md="4">
                <label className="mb-2">Date</label>
                <Form.Control
                  size="sm"
                  type="date"
                  name="fund_date"
                  value={form.fund_date}
                  onChange={handleChange}
                />
              </Col>

              {/* <br /> */}
              <Col md="8">
                <RadioGroup
                  value={form.accType}
                  options={[
                    { name: 'FAAC', label: 'FAAC' },
                    {
                      name: 'Other Treasury Accounts',
                      label: 'Other Treasury Accounts',
                    },
                  ]} name='accType'
                  onChange={(key, val) =>{
                    console.log(key, val)
                    setForm((p) => ({ ...p, [key]: val }))
                  }}
                />
                {/* <div className="row mb-2">
                  <label className="col-md-4 ">
                    <Form.Check
                      type="radio"
                      checked={form.accType === 'FAAC'}
                      onChange={() =>
                        setForm((p) => ({ ...p, accType: 'FAAC' }))
                      }
                    />
                    {radioType}
                  </label>
                  <label className="col-md-8 ">
                    <Form.Check
                      type="radio"
                      checked={form.accType === 'Other Treasury Accounts'}
                      onChange={() =>
                        setForm((p) => ({
                          ...p,
                          accType: 'Other Treasury Accounts',
                        }))
                      }
                    />
                    Other Treasury Accounts
                  </label>
                </div> */}
                {/* <label>Other Treasuries Account</label> */}

                <Typeahead
                  size="sm"
                  // disabled={id ? true : false}
                  id="20"
                  // onChange={(selected) => handleMdaCode(selected)}
                  onChange={(selected) => {
                    if (selected && selected.length) {
                      setForm((p) => ({
                        ...p,
                        selectedAccountType: selected[0].types,
                        mda_source_account: selected[0].account_name,
                        mda_account_no: selected[0].account_no,
                        fund_code: selected[0].account_code,
                        mda_bank_name: selected[0].bank_name,
                      }))
                    }
                  }}
                  onInputChange={(text) => console.log(text)}
                  options={faacLists}
                  labelKey={(option) => `${option.account_name}`}
                  placeholder="Select---"
                  ref={mdaCodeTypeahead}
                />
              </Col>

              {form.accType === 'FAAC' ? null : (
                <>
                  <Col md="4">
                    <label>Account Number</label>
                    <Form.Control
                      size="sm"
                      type="text"
                      name="mda_account_no"
                      value={form.mda_account_no}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col md="4">
                    <label>Bank Name</label>
                    <Form.Control
                      size="sm"
                      type="text"
                      name="mda_bank_name"
                      value={form.mda_bank_name}
                      onChange={handleChange}
                      disabled={true}
                    />
                  </Col>
                  <Col md="4">
                    <label>Sort Code</label>
                    <Form.Control
                      size="sm"
                      type="text"
                      name="mda_sort_code"
                      value={form.mda_sort_code}
                      onChange={handleChange}
                    />
                  </Col>
                </>
              )}
              {/* {form.accType === 'FAAC' ? ( */}
              <Col md="4">
                <label>Code </label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="fund_code"
                  value={form.fund_code}
                  onChange={handleChange}
                  disabled={true}
                />
              </Col>
              {/* // ) : null} */}
              <Col md="4">
                <label>Budget Balance </label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="mda_budget_balance"
                  value={form.mda_budget_balance}
                  onChange={handleChange}
                  disabled={
                    mdaBudgetBalance === 'Budget Exausted' ? false : true
                  }
                />
              </Col>

              <Col md="4">
                <label>Treasury Single Account (Central Account)</label>

                <Typeahead
                  size="sm"
                  disabled={id ? true : false}
                  id="20"
                  onChange={(selected) => handleTresuryAcct(selected)}
                  onInputChange={(text) => console.log(text)}
                  options={treasuryAccount}
                  labelKey={(option) => `${option.account_info}`}
                  placeholder="Select---"
                  ref={tresuryTypeahead}
                />
              </Col>

              <Col md="4">
                <label>Account Name</label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="treasury_account_name"
                  value={form.treasury_account_name}
                  onChange={handleChange}
                  disabled={true}
                />
              </Col>
              <Col md="4">
                <label>Account No</label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="treasury_account_no"
                  value={form.treasury_account_no}
                  onChange={handleChange}
                  disabled={true}
                />
              </Col>
              <Col md="4">
                <label>Bank Name</label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="treasury_bank_name"
                  value={form.treasury_bank_name}
                  onChange={handleChange}
                  disabled={true}
                />
              </Col>

              <Col md="4">
                <label>Enter Amount: ({formatNumber(form.amount)})</label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="amount"
                  value={form.amount}
                  onChange={handleChange}
                />
              </Col>
              <Col md="4">
                <label>Description</label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="description"
                  value={form.description}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <br />
            <Attachment
              acceptedFiles={acceptedFiles}
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              isDragActive={isDragActive}
              isDragAccept={isDragAccept}
              isDragReject={isDragReject}
            />
            <br />
            <center>
              <Button
                size="sm"
                className="bg-success text-white"
                onClick={handleArray}
              >
                Add to List
              </Button>
            </center>

            <TsaFundingBatchList
              tsaBatch={tsaBatch}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
            <Row>
              <Col md="12 text-center">
                <br />
                <Button
                  className="bg-success text-white"
                  loading={loading}
                  disabled={loading}
                  onClick={handleApi}
                >
                  {param > 0 ? 'Update' : 'Save'}
                </Button>
              </Col>
            </Row>
          </form>
        </Card.Body>
      </Card>
    </>
  )
}
