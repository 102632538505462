import React, { useContext } from 'react'
import { Button, ButtonGroup, Card, Modal, ModalBody, ModalFooter } from '@themesberg/react-bootstrap'

import { useState } from 'react'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import { postApi } from '../../../redux/api'
import CustomTable from '../../../components/CustomTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Routes } from '../../../routes'
import { SearchContext } from '../../HomePage'
import ModalHeader from '@themesberg/react-bootstrap/lib/esm/ModalHeader'

function BankIndex() {
  const history = useHistory()
  const [details, setDetails] = useState([])
  // const [filterText, setFilterText] = useState('uba')
  // const [list, setList] = useState([])
  const { searchTerm, setSearchTerm } = useContext(SearchContext)
  const [selected, setSelected] = useState({})
  const [showWarning, setShowWarning] = useState(false)

  // const handleChange = ({ target }) => {
  //   const { value } = target
  //   setFilterText(value)
  // }

  const getList = () => {
    postApi('select_mda_bank_details', {
      query_type: 'select',
    }).then((data) => {
      console.log(data)
      setDetails(data.result)
    })
  }

  useEffect(() => {
    getList()
  }, [])

  const deleteBank = (obj) => {
    postApi('select_mda_bank_details', {
      query_type: 'DELETE',
      ...obj
    }).then(() => {
      setShowWarning(false)
      getList()
    })
  }

  const list = []
  details && details.length && details.forEach(item => {
    if(item.bank_name
                      ?.toLowerCase()
                      ?.indexOf(searchTerm.toLowerCase())  === -1
                      &&
                    item.account_name
                      ?.toLowerCase()
                      ?.indexOf(searchTerm.toLowerCase())===-1 &&
                    item.account_number
                      ?.toLowerCase()
                      ?.indexOf(searchTerm.toLowerCase())===-1
                    //  &&
                    // item.sort_code
                    //   ?.toLowerCase()
                    //   ?.indexOf(searchTerm.toLowerCase()===-1)
    ) return;
    list.push(item)
  })

  // const syncList = useCallback(() => {
  //   details &&
  //     details.length &&
  //     setList(
  //       searchTerm.length > 3 && details.length
  //         ? details.filter((item) => {
  //             return (
  //               item.bank_name
  //                 ?.toLowerCase()
  //                 ?.includes(searchTerm.toLowerCase()) ||
  //               item.account_name
  //                 ?.toLowerCase()
  //                 ?.includes(searchTerm.toLowerCase()) ||
  //               item.account_number
  //                 ?.toLowerCase()
  //                 ?.includes(searchTerm.toLowerCase()) ||
  //               item.sort_code
  //                 ?.toLowerCase()
  //                 ?.includes(searchTerm.toLowerCase())
  //             )
  //           })
  //         : details,
  //     )
  // }, [searchTerm, details])

  // useEffect(() => {
  //   syncList()
  // }, [syncList])

  const fields = [
    {
      title: 'Bank name',
      value: 'bank_name',
    },
    {
      title: 'Account Name',
      value: 'account_name',
    },
    {
      title: 'Account Number',
      value: 'account_number',
    },
    {
      title: 'Sort code',
      value: 'sort_code',
    },
    {
      title: 'Action',
      custom: true,
      component: (item, index) => (
        <div className="text-center">
          <Button
            color="success"
            size="sm"
            onClick={() => history.push(`${Routes.bank.editBank}/${item.id}`)}
          >
            <FontAwesomeIcon icon={faEdit} className="me-1" /> Edit
          </Button>
          <Button color="success" size="sm" className="ms-1" onClick={() => {
            setSelected(item)
            setShowWarning(true)
          }} >
            <FontAwesomeIcon icon={faTrash} className="text-danger me-1" />{' '}
            Delete
          </Button>
        </div>
      ),
    },
  ]

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <Button onClick={() => history.push(Routes.bank.editBank)} className='me-2'>
            Add new bank details
          </Button>
        <div className="d-block mb-4 mb-md-0">
          <h4>Bank Details</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">
              Share
            </Button>
            <Button variant="outline-primary" size="sm">
              Export
            </Button>
          </ButtonGroup>
        </div>
      </div>

      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="p-0">
          <CustomTable
            fields={fields}
            data={list}
            // currentPage={currentPage}
            // handlePageChange={handlePageChange}
          />
        </Card.Body>
      </Card>

<Modal show={showWarning} onHide={() => setShowWarning(false)}>
  <ModalHeader>Warning</ModalHeader>
  <ModalBody><h3>Are you sure you want to delete this bank? This action is not reversible!</h3></ModalBody>
  <ModalFooter>
    <Button className='btn btn-danger' onClick={() => deleteBank(selected)}>Confirm</Button>
    <Button onClick={() => setShowWarning(false)}>Cancel</Button>
  </ModalFooter>
</Modal>
    </>
  )
}

export default BankIndex
