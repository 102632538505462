import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect, useLocation, useHistory } from "react-router-dom";
import { Routes } from "../routes";

// pages
import Presentation from "./Presentation";
import Upgrade from "./Upgrade";
import DashboardOverview from "./dashboard/DashboardOverview";
import Transactions from "./Transactions";
import Settings from "./Settings";
import BootstrapTables from "./tables/BootstrapTables";
import Signin from "./examples/Signin";
import Signup from "./examples/Signup";
import ForgotPassword from "./examples/ForgotPassword";
import ResetPassword from "./examples/ResetPassword";
import Lock from "./examples/Lock";
import NotFoundPage from "./examples/NotFound";
import ServerError from "./examples/ServerError";

// documentation pages
import DocsOverview from "./documentation/DocsOverview";
import DocsDownload from "./documentation/DocsDownload";
import DocsQuickStart from "./documentation/DocsQuickStart";
import DocsLicense from "./documentation/DocsLicense";
import DocsFolderStructure from "./documentation/DocsFolderStructure";
import DocsBuild from "./documentation/DocsBuild";
import DocsChangelog from "./documentation/DocsChangelog";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";

import Accordion from "./components/Accordion";
import Alerts from "./components/Alerts";
import Badges from "./components/Badges";
import Breadcrumbs from "./components/Breadcrumbs";
import Buttons from "./components/Buttons";
import Forms from "./components/Forms";
import Modals from "./components/Modals";
import Navs from "./components/Navs";
import Navbars from "./components/Navbars";
import Pagination from "./components/Pagination";
import Popovers from "./components/Popovers";
import Progress from "./components/Progress";
import Tables from "./components/Tables";
import Tabs from "./components/Tabs";
import Tooltips from "./components/Tooltips";
import Toasts from "./components/Toasts";
import FaacTsaFunding from './main-treasury/tsa-funding/FaacTsaFunding';
import NewFundingForm from './main-treasury/tsa-funding/NewFundingForm';
import BudgetUtilizationIndex from './main-treasury/budget-utilization/BudgetUtilizationIndex';
import { useContext } from 'react';
import { createContext } from 'react';
import BudgetUtilizationReport from './main-treasury/budget-utilization/BudgetUtilizationReport';
import BankIndex from './main-treasury/bank/BankIndex';
import EditBankDetails from './main-treasury/bank/EditBankDetails';
import ApprovalCollectionIndex from './main-treasury/approval-collection/ApprovalCollectionIndex';
import NewApprovalCollection from './main-treasury/approval-collection/NewApprovalCollection';
import PaymentScheduleIndex from './main-treasury/payment-schedule/PaymentScheduleIndex';
import NewSchedule from './main-treasury/payment-schedule/NewSchedule';
import BatchProcessingIndex from './main-treasury/batch-processing/BatchProcessingIndex';
import AgDashboard from './main-treasury/AgDashboard';
import { useDispatch } from 'react-redux';
import { init } from '../redux/actions/auth';
import Process from './main-treasury/batch-processing/Process';
import { MODULE_TYPES } from '../redux/utils';
import PaymentPDF from './main-treasury/batch-processing/PaymentPDF';
import PVCollectionIndex from './sub-treasury/pv-collection/PVCollectionIndex';
import NewPVCollection from './sub-treasury/pv-collection/NewPVCollection';
import ContractorScheduleIndex from './sub-treasury/contractor-schedule/ContractorScheduleIndex';
import NewContractorSchedule from './sub-treasury/contractor-schedule/NewContractorSchedule';
import PaymentAdvicePDF from './main-treasury/batch-processing/PaymentAdvicePDF';
import FundingReport from './main-treasury/tsa-funding/FundingReport';
import RemittanceSlipPDF from './main-treasury/batch-processing/RemittanceSlipPDF';
import UsersList from './examples/user-admin/UsersList';
import NewUser from './examples/user-admin/NewUser';
import STLetterPDF from './main-treasury/batch-processing/STLetterPDF';
import ViewContractor from './sub-treasury/contractor-schedule/components/ViewContractorSchedule';
import ViewContractScheduleWithTaxDetails from './sub-treasury/contractor-schedule/ViewContractScheduleWithTaxDetails';

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route {...rest} render={props => ( <> <Preloader show={loaded ? false : true} /> <Component {...props} /> </> ) } />
  );
};

export const SearchContext = createContext("")

const RouteWithSidebar = ({ component: Component, props={}, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }

  return (
    <Route {...rest} render={renderProps => (
      <>
        <Preloader show={loaded ? false : true} />
        <Sidebar />

        <main className="content">
          <Navbar />
          <Component {...props} {...renderProps} />
          <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
        </main>
      </>
    )}
    />
  );
};

export default () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState("")

  useEffect(() => {
    const initialRoute = location.pathname;
    dispatch(
      init(
        () => {
          history.push(initialRoute);
        },
        () => {
          if (initialRoute.includes("sign-in")) {
            history.push(Routes.Signin.path);
          } else if (initialRoute.includes("sign-up")) {
            console.log(".");
          } else if (initialRoute.includes("reset-password")) {
            console.log(".");
          } else {
            history.push(`${Routes.Signin.path}?rdr=` + initialRoute);
          }
        }
      )
    );
  }, [dispatch, location.pathname, history]);

  return(
    <SearchContext.Provider value={{searchTerm,setSearchTerm}}>
  <Switch>


    <RouteWithLoader exact path={Routes.Presentation.path} component={Signin} />
    <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
    <RouteWithLoader exact path={Routes.Signup.path} component={Signup} />
    <RouteWithLoader exact path={Routes.ForgotPassword.path} component={ForgotPassword} />
    <RouteWithLoader exact path={Routes.ResetPassword.path} component={ResetPassword} />
    <RouteWithLoader exact path={Routes.Lock.path} component={Lock} />
    <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
    <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} />
    
    
    <RouteWithSidebar exact path={Routes.tsafunding.faacTsaFundingWithParams} component={FaacTsaFunding} />
    <RouteWithSidebar exact path={Routes.tsafunding.newfundingWithParams} component={NewFundingForm} />
    <RouteWithSidebar exact path={Routes.tsafunding.fundingReportWithParams} component={FundingReport} />

    <RouteWithSidebar exact path={Routes.budget.budgetUtlization} component={BudgetUtilizationIndex} />
    <RouteWithSidebar exact path={Routes.budget.budgetUtlizationReport} component={BudgetUtilizationReport} />
    <RouteWithSidebar exact path={Routes.budget.budgetUtlizationReportDynamicRoute} component={BudgetUtilizationReport} />

    <RouteWithSidebar exact path={Routes.bank.bankIndex} component={BankIndex} />
    <RouteWithSidebar exact path={Routes.bank.editBank} component={EditBankDetails} />
    <RouteWithSidebar exact path={Routes.bank.editBankDynamicRoute} component={EditBankDetails} />

    <RouteWithSidebar exact path={Routes.approval.approvalIndex} component={ApprovalCollectionIndex} />
    <RouteWithSidebar exact path={Routes.approval.newApproval} component={NewApprovalCollection} />

    <RouteWithSidebar exact path={Routes.paymentSchedule.index} component={PaymentScheduleIndex} />
    <RouteWithSidebar exact path={Routes.paymentSchedule.viewSchedule} component={BatchProcessingIndex} props={{ pageType: 'committed', status: 'committed'}} />

    <RouteWithSidebar exact path={Routes.paymentSchedule.newSchedule} component={NewSchedule} />
    {/* <RouteWithSidebar exact path={Routes.paymentSchedule.newScheduleWithoutApproval} component={NewSchedule} /> */}
    <RouteWithSidebar exact path={Routes.paymentSchedule.newScheduleWithApproval} component={NewSchedule} />

    <RouteWithSidebar exact path={Routes.batchProcessing.index} component={BatchProcessingIndex} />
    <RouteWithSidebar exact path={Routes.batchProcessing.process} component={Process} />
    <RouteWithSidebar exact path={Routes.batchProcessing.processWithParams} component={Process} />
    <RouteWithSidebar exact path={Routes.batchProcessing.update} component={BatchProcessingIndex} props={{ pageType: 'update'}} />
    <RouteWithSidebar exact path={Routes.batchProcessing.viewPaymentList} component={Process} props={{ pageType: 'update'}} />
    <RouteWithSidebar exact path={Routes.batchProcessing.viewPaymentListWithParams} component={Process} props={{ pageType: 'update'}} />
    <RouteWithSidebar exact path={Routes.batchProcessing.processUpdate} component={Process} props={{ pageType: 'update'}} />
    <RouteWithSidebar exact path={Routes.batchProcessing.processUpdateWithParams} component={Process} props={{ pageType: 'update'}} />

    <RouteWithSidebar exact path={Routes.payments.index} component={BatchProcessingIndex} props={{ pageType: 'commit'}} />
    {/* <RouteWithSidebar exact path={Routes.payments.process} component={Process} props={{ pageType: 'commit'}} type={MODULE_TYPES.MAIN_TREASURY} /> */}
    <RouteWithSidebar exact path={Routes.payments.processWithParams} component={Process} props={{ pageType: 'commit', type:MODULE_TYPES.MAIN_TREASURY}}  />
    <RouteWithSidebar exact path={Routes.payments.viewPDFWithParams} component={PaymentPDF} props={{ pageType: 'commit', type:MODULE_TYPES.MAIN_TREASURY}} />
    <RouteWithSidebar exact path={Routes.payments.viewStPDFWithParams} component={STLetterPDF} props={{ pageType: 'commit', type:MODULE_TYPES.SUB_TREASURY}} />

    <RouteWithSidebar exact path={Routes.remittance.index} component={BatchProcessingIndex} props={{ pageType: 'remittance'}} />
    {/* <RouteWithSidebar exact path={Routes.remittance.process} component={Process} props={{ pageType: 'remittance'}} type={MODULE_TYPES.MAIN_TREASURY} /> */}
    <RouteWithSidebar exact path={Routes.remittance.processWithParams} component={Process} props={{ pageType: 'remittance', type:MODULE_TYPES.MAIN_TREASURY}}  />
    <RouteWithSidebar exact path={Routes.remittance.viewPDFWithParams} component={RemittanceSlipPDF} props={{ pageType: 'remittance', type:MODULE_TYPES.MAIN_TREASURY}} />


    <RouteWithSidebar exact path={Routes.remittance.paymentAdviceIndex} component={BatchProcessingIndex} props={{ pageType: 'remittance', type:MODULE_TYPES.SUB_TREASURY }} />
    {/* <RouteWithSidebar exact path={Routes.remittance.process} component={Process} props={{ pageType: 'remittance'}} type={MODULE_TYPES.MAIN_TREASURY} /> */}
    {/* <RouteWithSidebar exact path={Routes.remittance.viewPaymentAdvice} component={Process} props={{ pageType: 'remittance', type:MODULE_TYPES.SUB_TREASURY}}  /> */}
    <RouteWithSidebar exact path={Routes.remittance.viewPaymentAdviceWithParams} component={PaymentAdvicePDF} props={{ pageType: 'remittance', type:MODULE_TYPES.SUB_TREASURY}} />


    <RouteWithSidebar exact path={Routes.pv.pvlIndex} component={PVCollectionIndex} />
    <RouteWithSidebar exact path={Routes.pv.newPv} component={NewPVCollection} />

    <RouteWithSidebar exact path={Routes.paymentSchedule.stIndex} component={ContractorScheduleIndex} props={{ type: MODULE_TYPES.SUB_TREASURY}} />
    <RouteWithSidebar exact path={Routes.paymentSchedule.newStSchedule} component={NewContractorSchedule} props={{ type: MODULE_TYPES.SUB_TREASURY}} />
    <RouteWithSidebar exact path={Routes.paymentSchedule.viewStScheduleDynamic} component={ViewContractor} props={{ type: MODULE_TYPES.SUB_TREASURY}} />
    <RouteWithSidebar exact path={Routes.paymentSchedule.viewStScheduleDetailsDynamic} component={ViewContractScheduleWithTaxDetails} props={{ type: MODULE_TYPES.SUB_TREASURY}} />


    <RouteWithSidebar exact path={Routes.contractorSchedule.index} component={ContractorScheduleIndex} />
    <RouteWithSidebar exact path={Routes.contractorSchedule.newSchedule} component={ContractorScheduleIndex} />

    <RouteWithSidebar exact path={Routes.userAdmin.index} component={UsersList} />
    <RouteWithSidebar exact path={Routes.userAdmin.editUser} component={NewUser} />
    <RouteWithSidebar exact path={Routes.userAdmin.editUserDynamicRoute} component={NewUser} />

    {/* pages */}
    <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={AgDashboard} />
    <RouteWithSidebar exact path={Routes.Upgrade.path} component={Upgrade} />
    <RouteWithSidebar exact path={Routes.Transactions.path} component={Transactions} />
    <RouteWithSidebar exact path={Routes.Settings.path} component={Settings} />
    <RouteWithSidebar exact path={Routes.BootstrapTables.path} component={BootstrapTables} />

    {/* components */}
    <RouteWithSidebar exact path={Routes.Accordions.path} component={Accordion} />
    <RouteWithSidebar exact path={Routes.Alerts.path} component={Alerts} />
    <RouteWithSidebar exact path={Routes.Badges.path} component={Badges} />
    <RouteWithSidebar exact path={Routes.Breadcrumbs.path} component={Breadcrumbs} />
    <RouteWithSidebar exact path={Routes.Buttons.path} component={Buttons} />
    <RouteWithSidebar exact path={Routes.Forms.path} component={Forms} />
    <RouteWithSidebar exact path={Routes.Modals.path} component={Modals} />
    <RouteWithSidebar exact path={Routes.Navs.path} component={Navs} />
    <RouteWithSidebar exact path={Routes.Navbars.path} component={Navbars} />
    <RouteWithSidebar exact path={Routes.Pagination.path} component={Pagination} />
    <RouteWithSidebar exact path={Routes.Popovers.path} component={Popovers} />
    <RouteWithSidebar exact path={Routes.Progress.path} component={Progress} />
    <RouteWithSidebar exact path={Routes.Tables.path} component={Tables} />
    <RouteWithSidebar exact path={Routes.Tabs.path} component={Tabs} />
    <RouteWithSidebar exact path={Routes.Tooltips.path} component={Tooltips} />
    <RouteWithSidebar exact path={Routes.Toasts.path} component={Toasts} />

    {/* documentation */}
    <RouteWithSidebar exact path={Routes.DocsOverview.path} component={DocsOverview} />
    <RouteWithSidebar exact path={Routes.DocsDownload.path} component={DocsDownload} />
    <RouteWithSidebar exact path={Routes.DocsQuickStart.path} component={DocsQuickStart} />
    <RouteWithSidebar exact path={Routes.DocsLicense.path} component={DocsLicense} />
    <RouteWithSidebar exact path={Routes.DocsFolderStructure.path} component={DocsFolderStructure} />
    <RouteWithSidebar exact path={Routes.DocsBuild.path} component={DocsBuild} />
    <RouteWithSidebar exact path={Routes.DocsChangelog.path} component={DocsChangelog} />

    {/* <Redirect to={Routes.NotFound.path} /> */}
  </Switch>
  </SearchContext.Provider>
)};
