export const mainTreasuryModules = [
    'NGF Account Chart',
    'Budget',
    'Bank',
    'TSA Funding',
    'Bank',
    'Approval Collection',
    'Create Schedule',
    'Recurrent Payments',
    'Internal Audit',
    'Payments',
    'Remittance',
    'Reports',
  ]
  
  export const subTreasuryModules = [
    'Bank Details',
    'PVCollection',
    'Payment Schedule',
    'Internal Audit',
    'Commit Payment',
    'Remittance',
    'Reports',
  ]
  