import { postApi } from '../../../redux/api'

export const getPvList = (callback = (f) => f, error = (f) => f) => {
  // alert('xxx')
  postApi(`post_pv_collection`, {
    form: { query_type: 'select' },
  })
    .then(callback)

    .catch(error)
}

export const getContractorDetails = (
  { query_type = 'select', contractor_code = '' },
  callback = (f) => f,
  error = (f) => f,
) => {
  postApi(`post_contractor_details`, {
    query_type,
    contractor_code,
    // status : "Paid",
    // batch_no
  })
    .then((data) => {
      console.log('data', data)
      if (data.result) {
        callback(data.result)
      }
    })

    .catch((err) => {
      // alert(err);
      // setLoadSpinner(false);
    })
}

export const getContractorBank = (
  { query_type = 'select', contractor_code },
  callback = (f) => f,
  error = (f) => f,
) => {
  postApi(`post_contractor_bank_details`, {
    query_type,
    contractor_code,
  })
    .then((data) => {
      console.log(data)
      callback(data)
    })
    .catch((err) => {
      error(err)
    })
}

export const getContractList = (
  { query_type = 'select_by_id' },
  callback = (f) => f,
  error = (f) => f,
) => {
  postApi(`post_contractor_schedule`, {
    query_type,
    //   batch_id: id,
  })
    .then((data) => {
      if (data.result) {
        callback(data.result)

        // setData(data.result)
        // console.log(data.result)
      }
    })

    .catch((err) => {
      error(err)
      // setLoadSpinner(false);
    })
}
// SELECT * FROM `Users` WHERE pcn_no='014828'
