import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  Card,
  Button,
  Form,
  Modal,
  Container,
  Row,
  Col,
  Image,
} from '@themesberg/react-bootstrap'

import BackButton from '../../../components/BackButton'
import CustomForm from '../../../components/CustomForm'
import { useDropzone } from 'react-dropzone'
import { useHistory } from 'react-router-dom'
import moment from 'moment-timezone'
import { Typeahead } from 'react-bootstrap-typeahead'
import RadioGroup from '../../../components/RadioGroup'
import useQuery from '../../../hooks/useQuery'
import { apiURL, fetchApi, postApi } from '../../../redux/api'
import { formatNumber } from '../../../redux/utils'
import Attachment from '../../../components/Attachment'
import { getApprovalCollectList } from '../../main-treasury/payment-schedule/api'
import ScheduleTable from '../../main-treasury/payment-schedule/components/table'
import { getPvList } from './api'
import {
  paymentTypes,
  projectClassifications,
  projectTypes,
} from '../pv-collection/NewPVCollection'
import CustomTable from '../../../components/CustomTable'
import ListTable from './components/ListTable'
import ContractorModal from './components/ContractorModal'
import { Routes } from '../../../routes'
import TaxItem from './TaxItem'

export const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif']

export default ({ match }) => {
  const history = useHistory()
  const [treasuryAccount, setTreasuryAccount] = useState([])
  const [pvList, setPvList] = useState([])
  const [projectType, setProjectType] = useState([])
  const [check, setCheck] = useState(false)
  const [mdaAccount, setMdaAccount] = useState([])
  const [paymentScheduleTable, setPaymentScheduleTable] = useState([])
  const [contractScheduleTable, setContractScheduleTable] = useState([])
  const [budgetSummary, setBudgetSummary] = useState([])
  const [economicCode, setEconomicCode] = useState([])
  const [childCode, setChildCode] = useState([])
  const [batch_no, setBatch_no] = useState('')
  const tresuryTypeahead = useRef()
  const economicTypeahead = useRef()
  const mdaCodeTypeahead = useRef()
  // const projectTypeahead = useRef()
  // const contractorCodeTypeahead = useRef()
  const mdaTypeahead = useRef()
  const [modal, setModal] = useState(false)
  const [batchCode, setBatchCode] = useState('')
  const today = moment().format('MM/DD/YYYY')
  const [mdaBudgetBalance, setMdaBudgetBalance] = useState(true)
  const [contractorTable, setContractorTable] = useState([])
  // moment(testDate).format('MM/DD/YYYY');
  const id = match.params.id
  const string = moment('2020/05/12').toISOString(true)
  // console.log("string", match)
  const [taxesList, setTaxesList] = useState([])
  const [taxesApplied, setTaxesApplied] = useState([])
  const [formMode, setFormMode] = useState('new')
  const [form, setForm] = useState({
    date: moment().format('YYYY-MM-DD'),
    mda_name: '',
    project_description: '',
    contractor: '',
    amount: '',
    project_type: '',
    payment_type: '',
    project_classification: '',
    VAT: '',
    WHT: '',
    SD: '',
    EL: '',
    tender: '',
    WR: '',
    others: '',
    total_taxes: '',
  })

  const toggle = () => {
    setModal(!modal)
    if (modal === true) {
      history.push(Routes.contractorSchedule.index)
      // history.push('/dashboard/contract_schedule_List')
    }
  }

  const handleReset = () => {
    setForm((p) => ({
      ...p,
      project_description: '',
      amount: '',
      project_type: '',
      payment_type: '',
      project_classification: '',

      selectedPv: '',
      pv_code: '',
      contractor: '',
      contractor_name: '',
      contractor_phone: '',
      contractor_tin: '',
      contractor_email: '',
      contractor_address: '',
      contractor_bank_name: '',
      contractor_acc_name: '',
      contractor_acc_no: '',
      sort_code: '',
      contractor_code: '',
      certificate_no: '',
    }))
    // clearProjectTypeahead()
    clearContractorTypeahead()
    setTaxesApplied([])
    setFormMode('new')
    // clearEconomicTypeahead()
    // clearMdaTypeahead()
    // clearMdaCodeTypeahead()
  }

  const updateBudgetCode = (description, query_type) => {
    postApi(`update-budget-code`, {
      description,
      query_type,
    })
      .then((d) => {
        console.log(d)
        if (d.result) {
          setBatchCode(d.contractor_code)
          console.log('batchCode', batchCode)
        }
      })
      .catch((err) => console.log(err))
  }
  const handleSubmitReset = () => {
    setForm((p) => ({
      ...p,
      date: '',
      mda_name: '',
      project_description: '',
      contractor: '',
      amount: '',
      project_type: '',
      payment_type: '',
      project_classification: '',
      VAT: '',
      WHT: '',
      SD: '',
      EL: '',
      tender: '',
      WR: '',
      others: '',
    }))

    clearEconomicTypeahead()
    clearMdaTypeahead()
    clearMdaCodeTypeahead()
    clearTreasuryTypeahead()
  }
  const getList1 = (economic_code) => {
    fetchApi(
      `get-budget-summary?query_type=select_distinct&mda_code=${form.mda_code}&economic_code=${economic_code}`,
    )
      .then((d) => {
        console.log(d)
        if (d.result) {
          // setBudgetSummary(d.result)
          setForm((p) => ({
            ...p,
            description: d.result[0].budget_description,
            mda_budget_balance: d.result[0].budget_amount,
          }))
        }
      })
      .catch((err) => console.log(err))
  }

  // useEffect(() => {

  //   getList1()

  // }, [])

  const getList = () => {
    fetchApi(`get-budget-summary?query_type=list`)
      .then((d) => {
        console.log('d', d)
        if (d.result) {
          setBudgetSummary(d.result)
        }
      })
      .catch((err) => console.log(err))
  }
  //

  useEffect(() => {
    getPvList((d) => {
      if (d && d.result) {
        setPvList(d.result)
      }
    })
    getList()
  }, [])

  useEffect(() => {
    postApi(`select_mda_bank_details`, {
      query_type: 'select',
    }).then((data) => {
      if (data.result) {
        console.log(data.result)

        const treasuryAccount = data.result.filter(
          (item) => item.account_type === 'treasury_source_account',
        )
        setTreasuryAccount(treasuryAccount)

        const mdaAccount = data.result.filter(
          (item) => item.account_type === 'mda_source_account',
        )
        setMdaAccount(mdaAccount)
      }
    })
  }, [])

  useEffect(() => {
    fetchApi(`post_project_type`, {
      query_type: 'select',
    })
      .then((data) => {
        if (data.result) {
          setProjectType(data.result)
        }
      })
      .catch((err) => console.log(err))
  }, [])

  useEffect(() => {
    postApi(`post_taxes`, {
      query_type: 'select',
    })
      .then((data) => {
        if (data.result) {
          // console.log(data)
          setTaxesList(data.result)
          // setTaxTable(data.result)
          // setProjectType(data.result)
        }
      })
      .catch((err) => console.log(err))
  }, [])

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    if (e.target.type === 'checkbox') {
      setForm((p) => ({ ...p, [name]: value }))
    } else {
      setForm((p) => ({ ...p, [name]: value }))
    }
  }

  const filterData = (economic_code) => {
    const newTresury = budgetSummary.filter(
      (item) =>
        item.economic_code == economic_code && item.mda_code == form.mda_code,
    )

    const selectedItem = newTresury[0]
    // setForm(p => ({...p,

    //     description: selectedItem.budget_description,
    // mda_budget_balance: selectedItem.budget_amount,

    //      }))

    console.log('new', newTresury.mda_code)
  }

  const handleMdaAccount = (selected) => {
    if (selected.length) {
      console.log('mda_co', selected)

      const selectedItem = selected[0]
      // setOptions(selected)
      setForm((p) => ({
        ...p,
        mda_bank_name: selectedItem.bank_name,
        mda_account_name: selectedItem.account_name,
        mda_account_no: selectedItem.account_number,
        child_code: selectedItem.bank_name,
        mda_acct_sort_code: selectedItem.sort_code,
        // mda_name : selectedItem.mda_info
      }))

      // const group_id =  selected[0].group_id
      // fetchEventList(group_id)
    } else {
      console.log('no item selected')
    }
    console.log(selected)
  }

  const handleTresuryAcct = (selected) => {
    if (selected.length) {
      console.log(selected)
      const selectedItem = selected[0]
      // setOptions(selected)
      setForm((p) => ({
        ...p,
        treasury_account_name: selectedItem.account_name,
        treasury_account_no: selectedItem.account_number,
        treasury_bank_name: selectedItem.bank_name,
        treasury_source_account: selectedItem.account_info,
      }))
      // const group_id =  selected[0].group_id
      // fetchEventList(group_id)
    } else {
      console.log('no item selected')
    }
    console.log(selected)
  }

  const getList3 = (economic_code) => {
    fetchApi(
      `get-budget-summary?query_type=fetch_budget&mda_code=${form.mda_code}&economic_code=${economic_code}`,
    )
      .then((d) => {
        console.log(d)
        if (d.result) {
          console.log('Budget', d)
          const selectedItem = d.result[0]
          setForm((p) => ({
            ...p,
            description: selectedItem.budget_description,
            mda_budget_balance:
              selectedItem.budget_amount == 0
                ? 'Budget Exausted'
                : selectedItem.budget_amount,
            budget:
              selectedItem.budget_amount == 0
                ? 'Budget Exausted'
                : selectedItem.budget_amount,
          }))
        }
      })
      .catch((err) => console.log(err))
  }

  const handleEconomicCode = (selected) => {
    if (selected.length) {
      console.log('selected budget', selected)
      const selectedItem = selected[0]
      // setOptions(selected)
      setForm((p) => ({
        ...p,
        mda_economic_code: selectedItem.economic_code,
        // budget : selectedItem.economic_code
      }))
      getList3(selectedItem.economic_code)
    } else {
      console.log('no item selected')
    }

    console.log(selected)
  }

  const getList2 = (mda_code) => {
    console.log('gettnig economic', mda_code)
    fetchApi(
      `get-budget-summary?query_type=select_active_economic_code&&mda_code=${mda_code}`,
    )
      .then((d) => {
        console.log(d)
        if (d.result) {
          console.log(d)
          setEconomicCode(d.result)
        }
      })
      .catch((err) => console.log(err))
  }
  //

  const handleMdaCode = (selected) => {
    console.log(selected)
    if (selected.length) {
      const selectedItem = selected[0]
      // setOptions(selected)
      setForm((p) => ({
        ...p,
        mda_code: selectedItem.mda_code,
        mda_name: selectedItem.mda_name,
        // mda_economic_code : selectedItem.economic_code
      }))

      getList2(selectedItem.mda_code)
    } else {
      console.log('no item selected')
    }
  }

  const handleContractorCode = (selected) => {
    if (selected.length) {
      console.log(selected)
      const selectedItem = selected[0]
      // setOptions(selected)
      setForm((p) => ({
        ...p,

        contractor: selectedItem.contractor_name,
        contractor_id: selectedItem.contractor_code,
        contractor_tin: selectedItem.contractor_tin_no,
      }))

      // getList2(selectedItem.mda_code)
    }
  }

  const handleProjectType = (selected) => {
    if (selected.length) {
      console.log(selected)
      const selectedItem = selected[0]
      // setOptions(selected)
      setForm((p) => ({
        ...p,
        project_type: selectedItem.project_type,
      }))
    }
  }

  const fetchBuget = (mda_code1, economic_code1) => {
    const newArray = budgetSummary.filter(
      (item, idx) =>
        item.mda_info === mda_code1 && item.economic_code === economic_code1,
    )
    const selectedItem = newArray[0]
    setForm((p) => ({
      ...p,
      description: selectedItem.budget_description,
      mda_budget_balance: selectedItem.budget_amount,
    }))
  }

  const handleDelete = (index) => {
    const newArray = contractScheduleTable.filter((itm, idx) => index !== idx)
    setContractScheduleTable(newArray)
  }

  const handleEdit = (index) => {
    const newArray = contractScheduleTable.find((itm, idx) => index === idx)
    setForm(newArray)
    setTaxesApplied(newArray.taxesApplied)
    setFormMode('edit')
    // form(newArray[0])
    console.log(newArray)
  }

  // const totalTaxes = (tax) => {
  //   let total = 0
  //   total += tax
  //   console.log(total)

  // }
  const handleArray = () => {
    console.log(formMode)
    let total_taxes = taxesApplied.reduce((a, b) => a + parseInt(b.amount), 0)
    if (formMode === 'new') {
      // const total_taxes = Math.round(form.VAT + form.WHT)

      // setForm((p) => ({ ...p, total_taxes }))
      setContractScheduleTable([
        ...contractScheduleTable,
        { ...form, total_taxes, taxesApplied },
      ])
    } else {
      let newArr = []
      contractScheduleTable.forEach((a) => {
        if (a.description === form.description && a.amount === form.amount) {
          console.log(a)
          newArr.push({ ...form, total_taxes, taxesApplied })
        } else {
          newArr.push(a)
        }
      })

      setContractScheduleTable(newArr)
    }
    handleReset()
  }

  const addBatchNo = () => {}

  const handleApi = () => {
    postApi(`post_contractor_schedule_array`, {
      contractScheduleTable,
      query_type: 'insert',
    })
      .then((data) => {
        if (data.success) {
          console.log(data)
          setBatchCode(data.contractor_code)
          updateBudgetCode('contractor_code', 'update')
          // handleSubmitReset()
          toggle()
          setPaymentScheduleTable([])
          history.push(Routes.batchProcessing.index)
        }
      })

      .catch((err) => {
        alert(err)
        // setLoadSpinner(false);
      })
  }

  const handleUpdate = () => {
    postApi(`post_payment_schedule`, {
      body: JSON.stringify({
        mda_account_name: form.mda_account_name,
        mda_account_no: form.mda_account_no,
        mda_bank_name: form.mda_bank_name,
        mda_acct_sort_code: form.mda_acct_sort_code,
        mda_code: form.mda_code,
        mda_name: form.mda_name,
        mda_description: form.mda_description,
        mda_budget_balance: form.mda_budget_balance,
        mda_economic_code: form.mda_economic_code,
        amount: form.amount,
        description: form.description,
        attachment: form.attachment,
        payment_type: form.payment_type,
        budget: form.budget,
        approval: form.approval,
        status: form.status,
        narration: form.narration,
        // budget: form.mda_budget_balance,
        // approval: form.approval,
        query_type: 'UPDATE_BY_ID',
        id,
      }),
    })
      .then((data) => {
        if (data.success) {
          console.log(data)
          handleSubmitReset()
          alert('Update Successfull')
          history.push('/dashboard/pays')
        }
      })

      .catch((err) => {
        alert(err)
        // setLoadSpinner(false);
      })
  }

  const fetchMdaBankDetails = () => {
    const query_type = 'select'
    postApi(`select_mda_bank_details`, {
      query_type,
    })
      .then((data) => {
        console.log(data)
        setChildCode(data.result)
      })

      .catch((err) => {
        console.log(err)
      })

    // console.log(childCode)
  }

  useEffect(() => {
    fetchMdaBankDetails()
  }, [])

  const clearTreasuryTypeahead = () => {
    tresuryTypeahead.current.clear()
  }

  const clearEconomicTypeahead = () => {
    economicTypeahead.current.clear()
  }

  // const clearProjectTypeahead = () => {
  //   // projectTypeahead.current.clear()
  // }

  const clearContractorTypeahead = () => {
    // contractorCodeTypeahead.current.clear()
  }

  const clearMdaTypeahead = () => {
    mdaTypeahead.current.clear()
  }

  const clearMdaCodeTypeahead = () => {
    mdaCodeTypeahead.current.clear()
  }

  useEffect(() => {
    postApi(`post_payment_schedule`, {
      query_type: 'select_by_id',
      id,
    }).then((data) => {
      // console.log("datay", data.result[0]);
      if (data?.result?.length) {
        setForm(data.result[0])
        setMdaBudgetBalance(data.result[0].mda_budget_balance)
        mdaTypeahead.current.setState({
          text: data.result[0].mda_account_name,
        })

        economicTypeahead.current.setState({
          text: data.result[0].mda_economic_code,
        })
        tresuryTypeahead.current.setState({
          text: data.result[0].treasury_account_name,
        })
        mdaCodeTypeahead.current.setState({ text: data.result[0].mda_name })
      }
    })
  }, [id])

  useEffect(() => {
    postApi(`post_contractor_details`, {
      query_type: 'select',
      // status : "Paid",
      // batch_no
    })
      .then((data) => {
        console.log('data', data)
        if (data.result) {
          setContractorTable(data.result)
        }
      })

      .catch((err) => {
        // alert(err);
        // setLoadSpinner(false);
      })
  }, [])

  return (
    <>
      <Row>
        <Col>
          <BackButton />
        </Col>
        <Col>
          <h5 className="mb-2">
            {/* <FontAwesomeIcon icon={faCheckDouble} className="me-2" />  */}
            Payment Schedule
          </h5>
        </Col>
      </Row>

      <ContractorModal toggle={toggle} modal={modal} batchCode={batchCode} />

      <Card>
        <Card.Body>
          <CustomForm
            handleChange={handleChange}
            fields={[
              {
                label: 'Date',
                value: form.date,
                name: 'date',
                type: 'date',
                disabled: id ? true : false,
              },
              {
                type: 'custom',
                component: (item) => (
                  <>
                    <Form.Label>MDA Name</Form.Label>

                    <Typeahead
                      size="sm"
                      // disabled={id ? true : false}
                      id="20"
                      onChange={(selected) => handleMdaCode(selected)}
                      onInputChange={(text) => console.log(text)}
                      options={budgetSummary}
                      labelKey={(option) => `${option.mda_info}`}
                      placeholder="Select---"
                      ref={mdaCodeTypeahead}
                    />
                  </>
                ),
                //   value: form.account_number,
                //   name: 'account_number',
              },
              {
                type: 'custom',
                component: (item) => (
                  <>
                    <Form.Label>Select Economic Code</Form.Label>

                    <Typeahead
                      size="sm"
                      // disabled={id ? true : false}
                      id="20"
                      onChange={(selected) => {
                        if (selected && selected.length) {
                          let it = selected[0]
                          setForm((p) => ({
                            ...p,
                            economic_code: it.economic_code,
                            budget_description: it.description,
                            mda_budget_balance: it.budget_amount,
                          }))
                        }
                      }}
                      options={economicCode}
                      labelKey={(option) =>
                        `${option.description} - ${option.economic_code}`
                      }
                      placeholder="Select---"
                      ref={economicTypeahead}
                    />
                  </>
                ),
                //   value: form.account_number,
                //   name: 'account_number',
              },
              {
                label: 'Description',
                value: form.description,
                name: 'description', diabled: true
              },
              {
                label: 'Budget Balance',
                value: formatNumber(form.mda_budget_balance),
                name: 'mda_budget_balance', disabled: true
              },
              {
                type: 'custom',
                component: (item) => (
                  <>
                    <Form.Label>Select PV</Form.Label>

                    <Typeahead
                      size="sm"
                      // disabled={id ? true : false}
                      id="20"
                      onChange={(selected) => {
                        if (selected && selected.length) {
                          // amount: "8000"
                          // date: "2022-01-17"
                          // description: "some other descr"
                          // id: 1
                          // mda_name: "Ministry of Planning"
                          // payment_type: "Direct Payment"
                          // project_type: "Special Consultation"
                          // pv_code: "672"
                          // pv_date: "0000-00-00"
                          let item = selected[0]
                          console.log(item)
                          setForm((p) => ({
                            ...p,
                            selectedPv: item.pv_code,
                            pv_code: item.pv_code,
                            amount: item.amount,
                            project_description: item.description,
                            project_type: item.project_type,
                            payment_type: item.payment_type,
                            project_classification: item.project_class,
                            contractor: item.contractor_name,
                            contractor_name: item.contractor_name,
                            contractor_phone: item.contractor_phone,
                            contractor_tin: item.contractor_tin,
                            contractor_email: item.contractor_email,
                            contractor_address: item.contractor_address,
                            contractor_bank_name: item.contractor_bank_name,
                            contractor_acc_name: item.contractor_acc_name,
                            contractor_acc_no: item.contractor_acc_no,
                            sort_code: item.sort_code,
                            contractor_code: item.contractor_code,
                            certificate_no: item.certificate_no,
                          }))
                        }
                      }}
                      onInputChange={(text) => console.log(text)}
                      options={pvList}
                      labelKey={(option) =>
                        `${option.description} (${option.pv_code})`
                      }
                      placeholder="Select---"
                      // ref={mdaCodeTypeahead}
                    />
                  </>
                ),
                //   value: form.account_number,
                //   name: 'account_number',
              },
              {
                label: 'Project Description',
                value: form.project_description,
                name: 'project_description',
              },

              {
                label: `Amount (${formatNumber(form.amount)})`,
                value: form.amount,
                name: 'amount',
              },
              {
                label: 'Payment Type',
                value: form.payment_type,
                name: 'payment_type',
                type: 'select',
                options: paymentTypes,
                col: 4,
              },
              {
                label: 'Project Type',
                value: form.project_type,
                name: 'project_type',
                type: 'select',
                options: projectTypes,
                col: 4,
              },
              {
                label: 'Project Classification',
                value: form.project_classification,
                name: 'project_classification',
                type: 'select',
                options: projectClassifications,
                col: 4,
              },
              {
                label: 'Enter Certificate Number',
                value: form.certificate_no,
                name: 'certificate_no',
                type:
                  form.project_classification === 'Certificate Number'
                    ? 'text'
                    : 'hidden',
              },
              {
                label: 'Project Description',
                value: form.project_description,
                name: 'project_description',
              },
              {
                label: 'Contractor Name',
                value: form.contractor_name,
                name: 'contractor_name',
              },
              {
                label: 'Contractor TIN',
                value: form.contractor_tin,
                name: 'contractor_tin',
              },
              {
                label: 'Contractor Bank',
                value: form.contractor_bank_name,
                name: 'contractor_bank_name',
              },
              {
                label: 'Contractor Account No.',
                value: form.contractor_acc_no,
                name: 'contractor_acc_no',
              },
            ]}
          />

<div className='mt-1'>
  <h6>Applicable Taxes</h6>
          <Row>
            {taxesList.map((item, index) => (
              <TaxItem
                key={index}
                index={index}
                item={item}
                form={form}
                taxesApplied={taxesApplied}
                setTaxesApplied={setTaxesApplied}
              />
            ))}
          </Row></div>
          {JSON.stringify(taxesApplied)}

          {!id ? (
            <>
              <br />
              <center>
                <Button
                  size="sm"
                  className="bg-success text-white"
                  onClick={handleArray}
                >
                  {formMode === 'new' ? 'Add to Batch' : 'Save update'}
                </Button>
              </center>

              <ListTable
                contractScheduleTable={contractScheduleTable}
                handleDelete={handleDelete}
                handleEdit={handleEdit}
              />
            </>
          ) : null}

          <center>
            {!id ? (
              <Button onClick={handleApi} size="" className="btn-success">
                Submit
              </Button>
            ) : (
              <Button onClick={handleUpdate} size="" className="btn-success">
                Update
              </Button>
            )}
          </center>
        </Card.Body>
      </Card>
    </>
  )
}

export const ViewImage = ({
  attachmentModalIsOpen = false,
  attachmentModalToggle = (f) => f,
  approvalImageInfo = [],
  imageExtensions = [],
}) => {
  return (
    <Modal show={attachmentModalIsOpen} onHide={attachmentModalToggle}>
      <Modal.Header show={attachmentModalIsOpen} onHide={attachmentModalToggle}>
        Approval No.: {approvalImageInfo.approval_no}
      </Modal.Header>
      <Modal.Body className="text-center">
        <Container>
          <div className="image-container">
            {approvalImageInfo.length ? (
              approvalImageInfo.map((item, index) =>
                imageExtensions.some((extension) =>
                  item.image_url.toLowerCase().endsWith(extension),
                ) ? (
                  <Card.Img
                    top
                    src={`${apiURL}/uploads/${item.image_url}`}
                    alt={'approval'}
                  />
                ) : (
                  <Card.Body>
                    <Card.Link
                      href={`${apiURL}/uploads/${item.image_url}`}
                      target="_blank"
                    >
                      View PDF
                    </Card.Link>
                  </Card.Body>
                ),
              )
            ) : (
              <h4>No attachment found.</h4>
            )}
          </div>
        </Container>
        {/* <h6>MDA: {approvalImageInfo.mda_name}</h6> */}
      </Modal.Body>
    </Modal>
  )
}
