import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import BudgetChart from './charts/BarChart'
import moment from 'moment'
import { useCallback } from 'react'
import PieChartComponent from './charts/PieChart'
import LineChartComponent from './charts/LineChart'
import { fetchApi } from '../../../redux/api'
import { Card } from '@themesberg/react-bootstrap'

const today = moment().format('YYYY-MM-DD')

function BudgetUtilizationChart({
  title = '',
  query_type = 'budget',
  from = today,
  to = today,
  type = 'bar',
}) {
  const [report, setReprt] = useState([])
  const [chart, setChart] = useState([])
  const [pie, setPie] = useState([])
  const [loading, setLoading] = useState(false)

  const getReport = useCallback(() => {
    setLoading(true)
    fetchApi(
      `reports/budget-report-ag?query_type=${query_type}&fromDate=${from}&toDate=${to}`,
    )
      .then((resp) => {
        setLoading(false)
        if (resp.results && resp.results.length) {
          setReprt(resp.results)
          if (type === 'pie') {
            setPie(
              Object.keys(resp.results[0]).map((a) => ({
                name: a,
                value: parseFloat(resp.results[0][a]),
              })),
            )
          } else {
            setChart(
              resp.results.map((a) => ({
                name: a.mda_name,
                'Approved Budget': a.sum_approved,
                'Amount Utilized': a.sum_utilized_calc,
              })),
            )
          }
        }
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
  }, [query_type, from, to, type])

  useEffect(() => {
    getReport()
  }, [getReport])

  return (
    <Card body className="my-2">
      <h6 className="text-center fw-bold">{title}</h6>
      {loading && <p>Please wait...</p>}
      {type === 'bar' ? (
        <BudgetChart
          data={chart}
          bars={[
            { title: 'Approved Budget', label: false },
            { title: 'Amount Utilized', label: true },
          ]}
        />
      ) : type === 'line' ? (
        <LineChartComponent
          data={chart}
          bars={[
            { title: 'Approved Budget', label: false },
            { title: 'Amount Utilized', label: true },
          ]}
        />
      ) : type === 'pie' ? (
        <PieChartComponent data={pie} />
      ) : null}
      {/* {JSON.stringify(report)} */}
    </Card>
  )
}

export default BudgetUtilizationChart
