import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faCog,
  faHome,
  faPlus,
  faSearch,
  faTasks,
} from '@fortawesome/free-solid-svg-icons'
import {
  Col,
  Row,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,
} from '@themesberg/react-bootstrap'

import TsaFundList from './FundList'
import { useHistory, useParams } from 'react-router-dom'
import { useState } from 'react'
import { fetchApi } from '../../../redux/api'
import { useEffect } from 'react'
import useQuery from '../../../hooks/useQuery'
import { useCallback } from 'react'
import { Routes } from '../../../routes'

function FaacTsaFunding({ match }) {
  const history = useHistory()
  const batch_no = match.params.batch_no
  const query = useQuery()
  const {types} = useParams()
  const [searchTerm, setSearchTerm] = useState('')
  const [tsaFundingList, setTsaFundingList] = useState([])
  const [loading, setLoading] = useState(false)

  const getTsaAccounts = useCallback(() => {
    fetchApi(`tsa-account?query_type=summary&types=${types}`)
      .then((data) => {
        if (data.results) {
          setLoading(false)
          setTsaFundingList(data.results)
          // setTresuryDetails(data.results[0]);
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }, [types])

  useEffect(() => {
    getTsaAccounts()
  }, [getTsaAccounts])

  let rows = []

  tsaFundingList &&
    tsaFundingList.forEach((item) => {
      if (
        item.funding_code &&
        item.funding_code.toLowerCase().indexOf(searchTerm) === -1 &&
        item.total_amount.indexOf(searchTerm) === -1 &&
        item.total_amount.toLowerCase().indexOf(searchTerm) === -1
      ) {
        return
      }

      rows.push(item)
    })
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <Button
            className="me-2"
            onClick={() =>
              history.push(`${Routes.tsafunding.newfunding}/${types}`)
            }
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" /> Add Funding
          </Button>
        <div className="d-block mb-4 mb-md-0">
          <h4>{types}</h4>
          {/* <p className="mb-0">Your web analytics dashboard template.</p> */}
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">
              Share
            </Button>
            <Button variant="outline-primary" size="sm">
              Export
            </Button>
          </ButtonGroup>
        </div>
      </div>

      <TsaFundList list={rows} />
    </>
  )
}

export default FaacTsaFunding
