import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  Card,
  Button,
  Form,
  Modal,
  Container,
} from '@themesberg/react-bootstrap'

import BackButton from '../../../components/BackButton'
import CustomForm from '../../../components/CustomForm'
import { apiURL, fetchApi, postApi } from '../../../redux/api'
import {
  faCheckDouble,
  faEdit,
  faList,
} from '@fortawesome/free-solid-svg-icons'
import { formatNumber } from '../../../redux/utils'
import Attachment from '../../../components/Attachment'
import { useDropzone } from 'react-dropzone'
import useQuery from '../../../hooks/useQuery'
import { useHistory } from 'react-router-dom'
import moment from 'moment-timezone'
import { Typeahead } from 'react-bootstrap-typeahead'
import { Routes } from '../../../routes'
import CustomButton from '../../../components/CustomButton'
import NewContractorForm from './NewContractorForm'

export const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif']

export const paymentTypes = ['Direct Payment', 'Power of Attorney', 'Others']
export const projectTypes = ['Special Project', 'Constituency Project', 'Other']
export const projectClassifications = [
  'Certificate Number',
  'APG Number',
  'Mobilization',
  'Retention',
  'Land and Building',
  'Plant and Machinery',
  'Furniture and Fittings',
  'Others',
]

export default ({ match }) => {
  const [
    pvCode,
    // setPvCode
  ] = useState('')
  const mdaCodeTypeahead = useRef()
  const [modal, setModal] = useState(false)
  const [mdaSourceAcctlist, setMdaSourceAcctlist] = useState([])
  const history = useHistory()
  const [batchCode, setBatchCode] = useState('')
  const [loading, setLoading] = useState(false)

  const toggle = () => {
    setModal(!modal)
    if (modal === true) {
      history.push('/dashboard/pv_collection')
    }
  }
  const [pvImageInfo, setPvImageInfo] = useState([])
  const [attachmentModalIsOpen, setAttachmentModalIsOpen] = useState(false)
  const query = useQuery()
  const imageId1 = query.get('imageId')
  const _id = query.get('id')
  const imageId = moment().format('YYYYMMDDhhmmss')

  const [form, setForm] = useState({
    pv_code: '',
    date: moment().format('YYYY-MM-DD'),
    project_type: '',
    payment_type: '',
    mda_name: '',
    amount: '',
    query_type: 'insert',
    imageId
  })

  const handleReset = () => {
    setForm((p) => ({
      ...p,
      date: '',
      project_type: '',
      payment_type: '',
      mda_name: '',
      amount: '',
    }))

    // clearEconomicTypeahead()
    // clearMdaTypeahead()
  }

  const id = match.params.id

  const param = Object.entries(match.params).length

  const updateBudgetCode = (description, query_type) => {
    postApi(`update-budget-code`, {
      description,
      query_type,
    })
      .then((d) => {
        console.log(d)
        if (d.result) {
          setBatchCode(d.pv_code)
          console.log('batchCode', batchCode)
        }
      })
      .catch((err) => console.log(err))
  }

  const getList = () => {
    // fetch(`${api}/get_mdabank_details?query_type=select`)
    fetchApi(`get-budget-summary?query_type=list`)
      .then((d) => {
        console.log('d', d)
        if (d.result) {
          setMdaSourceAcctlist(d.result)
        }
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    getList()
  }, [])

  const handleChange = ({ target: { name, value } }) => {
    setForm((prev) => ({ ...prev, [name]: value }))
  }

  const handleApi1 = (pv_no) => {
    // console.log(form)

    // if (form.approved_amount && form.approved_amount !== '') {
      const formData = new FormData()
      form.pv_no = pv_no
      for (let i = 0; i < acceptedFiles.length; i++) {
        formData.append('files', acceptedFiles[i])
      }

      if (!acceptedFiles.length) {
        setForm((p) => ({ ...p, approval: 'not approved' }))
        formData.append('form', JSON.stringify(form))
        // // formData.append('event_name', options[0].event_name)

        fetch(`${apiURL}/post_images_pv`, {
          method: 'POST',

          body: formData,
        })
          .then((response) => response.json())
          .then((result) => {
            // handleApi()
            // setLoadSpinner(false)
            console.log(result)
            // removeAll()
            // toggle()
            // let acceptedFiles = []

            // localStorage.setItem("key", JSON.stringify(result.token));
            // setName(result.user)
            // history.push("/homepage/payment_table");

            // console.log(result);
          })

          .catch((err) => {
            console.log(err)
            // alert(err);
            // setLoadSpinner(false)
          })
      } else {
        formData.append('form', JSON.stringify(form))
        // // formData.append('event_name', options[0].event_name)

        fetch(`${apiURL}/post_images_pv`, {
          method: 'POST',

          body: formData,
        })
          .then((response) => response.json())
          .then((result) => {
            // handleApi()
            // setLoadSpinner(false)
            console.log(result)
            // removeAll()
            // toggle()
            // let acceptedFiles = []

            // localStorage.setItem("key", JSON.stringify(result.token));
            // setName(result.user)
            // history.push("/homepage/payment_table");

            // console.log(result);
          })

          .catch((err) => {
            console.log(err)
            // alert(err);
            // setLoadSpinner(false)
          })
      }
      // Object.keys(form).forEach(item => {
      //   formData.append(item, form[item])
      // })

      // formData.append('user', name.email)
      //  formData.append('group_id', groupName)
    // } else {
    //   alert('Please enter a valid amount')
    // }
  }

  const handleApi = () => {
    setLoading(true)
    postApi(`post_pv_collection`, {
      form,

      // status: 'pending',
    })
      .then((data) => {
        handleApi1(data.approval_no)
        updateBudgetCode('pv_code', 'update')
        setBatchCode(data.pv_code)
        console.log(data)
        toggle()
        handleReset()
        history.push(Routes.pv.pvlIndex)
      })
      .catch((error) => {
        setLoading(false)
        console.error(error)
      })
  }

  const handleMdaCode = (selected) => {
    if (selected.length) {
      console.log('selected', selected)
      const selectedItem = selected[0]
      // setOptions(selected)
      setForm((p) => ({
        ...p,
        mda_name: selectedItem.mda_name,
        mda_acc: selectedItem.mda_code,
        // : selectedItem.mda_name,
        // mda_economic_code : selectedItem.economic_code
      }))

      // getList2(selectedItem.mda_code)
    } else {
      console.log('no item selected')
    }
  }

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: 'image/*, .pdf, text/plain' })

  const attachmentModalToggle = () => {
    setAttachmentModalIsOpen((p) => !p)
  }

  const viewAttachment = () => {
    fetchApi(`fetch-pv-images?pv_no=${imageId1}`, (resp) => {
      attachmentModalToggle()
      if (resp.results && resp.results.length) {
        setPvImageInfo(resp.results)
      } else {
        setPvImageInfo({})
      }
    })
  }

  return (
    <>
      <BackButton />

      <Card>
        <Card.Body>
          <h5 className="mb-4">New PV Collection</h5>
          <Form>
            <CustomForm
              handleChange={handleChange}
              fields={[
                {
                  label: 'Date',
                  value: form.date,
                  name: 'date',
                  type: 'date',
                },
                {
                  label: 'PV Number',
                  value: form.pv_code,
                  name: 'pv_code',
                },
                {
                  label: 'Project Type',
                  name: 'project_type',
                  value: form.project_type,
                  options: projectTypes,
                  type: 'select',
                  col: 4,
                },
                {
                  label: 'Payment Type',
                  name: 'payment_type',
                  value: form.payment_type,
                  options: paymentTypes,
                  type: 'select',
                  col: 4,
                },
                {
                  label: 'Payment Class',
                  name: 'project_classification',
                  value: form.project_classification,
                  options: projectClassifications,
                  type: 'select',
                  col: 4,
                },
                {
                  label: 'Enter Certificate Number',
                  value: form.certificate_no,
                  name: 'certificate_no',
                  type:
                    form.project_classification === 'Certificate Number'
                      ? 'text'
                      : 'hidden',
                },

                {
                  type: 'custom',
                  component: (item) => (
                    <>
                      <Form.Label>Select MDA Name</Form.Label>
                      <Typeahead
                        size="sm"
                        // disabled={id ? true : false}
                        id="20"
                        onChange={(selected) => handleMdaCode(selected)}
                        onInputChange={(text) => console.log(text)}
                        options={mdaSourceAcctlist}
                        labelKey={(option) => `${option.mda_info}`}
                        // labelKey={(option) => `${option.account_name}`}
                        placeholder="Select---"
                        ref={mdaCodeTypeahead}
                      />
                    </>
                  ),
                  //   value: form.account_description,
                  //   name: 'account_description',
                },

                {
                  label: 'Admin Code',
                  value: form.mda_acc,
                  name: 'mda_acc',
                  disabled: true,
                },
                {
                  label: `Amount (${formatNumber(form.amount)})`,
                  value: form.amount,
                  name: 'amount',
                },
                {
                  label: 'Description',
                  value: form.description,
                  name: 'description',
                },
              ]}
            />

{!id ? (
              <>
                <Attachment
                  acceptedFiles={acceptedFiles}
                  getRootProps={getRootProps}
                  getInputProps={getInputProps}
                  isDragActive={isDragActive}
                  isDragAccept={isDragAccept}
                  isDragReject={isDragReject}
                />
              </>
            ) : null}
            {_id ? (
              <Button variant="link" onClick={viewAttachment}>
                View Attachment
              </Button>
            ) : null}

            <NewContractorForm form={form} setForm={setForm} />

            <center className="mt-2">
              <CustomButton
                className="bg-success text-white px-2"
                onClick={handleApi}
                loading={loading}
              >
                {param > 0 ? 'Update' : 'Save'}
              </CustomButton>
            </center>
          </Form>
          <ViewImage
              attachmentModalIsOpen={attachmentModalIsOpen}
              attachmentModalToggle={attachmentModalToggle}
              pvImageInfo={pvImageInfo}
              imageExtensions={imageExtensions}
            />
        </Card.Body>
      </Card>
    </>
  )
}

export const ViewImage = ({
  attachmentModalIsOpen = false,
  attachmentModalToggle = (f) => f,
  pvImageInfo = [],
  imageExtensions = [],
}) => {
  return (
    <Modal show={attachmentModalIsOpen} onHide={attachmentModalToggle}>
      <Modal.Header show={attachmentModalIsOpen} onHide={attachmentModalToggle}>
        PV No.: {pvImageInfo.pv_no}
      </Modal.Header>
      <Modal.Body className="text-center">
        <Container>
          <div className="image-container">
            {pvImageInfo.length ? (
              pvImageInfo.map((item, index) =>
                imageExtensions.some((extension) =>
                  item.image_url.toLowerCase().endsWith(extension),
                ) ? (
                  <Card.Img
                    top
                    src={`${apiURL}/uploads/${item.image_url}`}
                    alt={'pv'}
                  />
                ) : (
                  <Card.Body>
                    <Card.Link
                      href={`${apiURL}/uploads/${item.image_url}`}
                      target="_blank"
                    >
                      View PDF
                    </Card.Link>
                  </Card.Body>
                ),
              )
            ) : (
              <h4>No attachment found.</h4>
            )}
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  )
}