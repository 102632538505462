import { Form } from '@themesberg/react-bootstrap'
import React from 'react'

function TextInput(props) {
  const {
    label = '',
    labelClass = '',
    required = false,
    type = 'text',
    placeHolder = '', name=''
  } = props
  return (
    <Form.Group id={name+"city"}>
      <Form.Label className={labelClass}>{label}</Form.Label>
      <Form.Control
        type={type} size='sm'
        placeholder={placeHolder}
        required={required}
        {...props}
      />
    </Form.Group>
  )
}

export default TextInput
