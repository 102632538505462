import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@themesberg/react-bootstrap'
import React from 'react'
import { useHistory } from 'react-router-dom'

function BackButton() {
  const history = useHistory()
  return (
    <Button
      variant={'primary'}
      size="sm"
      className="my-1"
      onClick={() => history.goBack()}
    >
      <FontAwesomeIcon icon={faArrowLeft} className="me-1" />
      Click here to go back
    </Button>
  )
}

export default BackButton
