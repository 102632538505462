import React from 'react'
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
} from 'recharts'

// const data = [
//   {
//     name: 'JAN',

//     2023: 100,
//     2022: 90,
//   },
//   {
//     name: 'FEB',

//     2023: 80,
//     2022: 180,
//   },
//   {
//     name: 'MAR',

//     2023: 90,
//     2022: 90,
//   },
//   {
//     name: 'APR',

//     2023: 150,
//     2022: 250,
//   },
//   {
//     name: 'MAY',

//     2023: 93,
//     2022: 113,
//   },
//   {
//     name: 'JUN',

//     2023: 85,
//     2022: 150,
//   },
//   {
//     name: 'JUL',
//     2023: 79,
//     2022: 89,
//   },
//   {
//     name: 'AUG',
//     2023: 106,
//     2022: 136,
//   },
//   {
//     name: 'SEP',
//     2023: 90,
//     2022: 130,
//   },
//   {
//     name: 'OCT',
//     2023: 100,
//     2022: 100,
//   },
//   {
//     name: 'NOV',
//     2023: 130,
//     2022: 130,
//   },
//   {
//     name: 'DEC',
//     2023: 200,
//     2022: 200,
//   },
// ]

const colors = ['#F2BA4B', '#5887B8']

function BudgetChart({ data = [], bars = [] }) {
  // Custom tick formatter function
  const formatYTick = (value) => {
    if (value >= 1000000000000) {
      return (value / 1000000000000).toFixed(1) + 'T'
    } else if (value >= 1000000000) {
      return (value / 1000000000).toFixed(1) + 'B'
    } else if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + 'M'
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + 'K'
    }
    return value
  }

  const formatXTick = (value) => {
    const maxLength = 7
    if (value.length > maxLength) {
      const words = value.split(' ')
      const abbreviated = words.map((word) => word.charAt(0)).join('')
      //   return abbreviated
      return value.substring(0, maxLength) + '...'
    }
    return value
  }

  const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value, data } = props
    const radius = 10

    return (
      <g>
        {/* <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" /> */}
        <text
          x={x + width / 2}
          y={y - radius}
          fill="#000"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {value}
        </text>
      </g>
    )
  }

  return (
    <div className="bg-light py-2">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          //   right: 30,
          left: 10,
          bottom: 5,
        }}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis dataKey="name" tickFormatter={formatXTick} />
        <YAxis tickFormatter={formatYTick} />
        <Tooltip formatter={formatYTick} />
        <Legend />
        {bars.map((b, i) => {
          if (b.hide) return null
          return (
            <Bar dataKey={b.title} fill={colors[i]} layout="vertical">
              {/* {b.label ? <LabelList dataKey={b.title} content={renderCustomizedLabel} /> : null} */}
            </Bar>
          )
        })}

        {/* <Bar dataKey="2023" fill="#2db200" /> */}
      </BarChart>
    </div>
  )
}

export default BudgetChart
