import React from 'react'
import { Table } from '@themesberg/react-bootstrap'
import { checkStrEmpty } from '../redux/utils'

function CustomTable(props) {
  const {
    fields = [],
    data = [],
    className = '',
    tableHeadStyle = {},
    headBackground = '#099019',
    headColor = 'white',
  } = props
  // styles = {color : 'black'}
  return (
    <Table
      hover
      className={`user-table align-items-center ${className}`}
      {...props}
      bordered
    >
      <thead className="table-header">
        <tr className="file-list-item">
          {fields.map((_item, _idx) => (
            <th
              key={_idx}
              style={{
                ...tableHeadStyle,
                backgroundColor: headBackground,
                color: headColor,
                textAlign: 'center',
              }}
            >
              {_item.title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item, idx) => (
          <tr key={idx}>
            {fields.map((_item, _idx) => {
              let val = item[_item.value] || ''
              let value_alt = (_item.value_alt && item[_item.value_alt]) || ''
              if (_item.component) {
                return (
                  <td
                    key={_idx}
                    className={`${_item.className}`}
                    style={
                      typeof _item.style === 'function'
                        ? _item.style(item)
                        : _item.style
                    }
                  >
                    {_item.component(item, idx)}
                  </td>
                )
              } else {
                return (
                  <td
                    key={_idx}
                    className={`${_item.className}`}
                    style={
                      typeof _item.style === 'function'
                        ? _item.style(item)
                        : _item.style
                    }
                  >
                    {checkStrEmpty(val.toString()) ? value_alt : val.toString()}
                  </td>
                )
              }
            })}
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default CustomTable
