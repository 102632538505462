import React from 'react'
import {
  Nav,
  Card,
  Button,
  Pagination,
} from '@themesberg/react-bootstrap'
import { useHistory } from 'react-router-dom'

import { formatNumber, getColor } from '../../../../redux/utils'
import CustomTable from '../../../../components/CustomTable'
import { Routes } from '../../../../routes'

const BudgetUtilizationTable = ({
  list = [],
  currentPage = 1,
  handlePageChange = (f) => f,
}) => {
  const history = useHistory()

  const fields = [
    {
      title: 'Action',
      className: 'text-right',
      custom: true,
      style: (i) => ({ backgroundColor: getColor(i.percent) }),
      component: (item) => (
        <div style={{ textAlign: 'right' }}>
          <Button
            item={item}
            onClick={() =>
              history.push(
                `${Routes.budget.budgetUtlizationReport}/${item.mda_economic_code}?mda_name=${item.mda_name}&mda_code=${item.mda_child_code}&description=${item.description}&amount=${item.amount}&balance=${item.budget}&percentage=${item.percent}&budget_year=${item.buget_year}`,
              )
            }
            className="bg-success w-110 text-white"
            // size="sm"
          >
            View
          </Button>
        </div>
      ),
    },
    {
      title: 'S/N',
      custom: true,
      component: (item, idx) => <span>{idx + 1}</span>,
      style: (i) => ({ backgroundColor: getColor(i.percent) }),
    },

    {
      title: 'MDA',
      value: 'mda_name',
      className: 'text-right',
      style: (i) => ({ backgroundColor: getColor(i.percent) }),
    },
    {
      title: 'Budget Code',
      value: 'mda_economic_code',
      className: 'text-right',
      style: (i) => ({ backgroundColor: getColor(i.percent) }),
    },
    {
      title: 'Budget Description',
      value: 'description',
      //   className: 'text-right',
      style: (i) => ({ backgroundColor: getColor(i.percent) }),
    },
    {
      title: 'Approved Budget',
      tableHeadStyle: { textAlign: 'right', color: 'black' },
      custom: true,
      component: (item) => (
        <div style={{ textAlign: 'right' }}>{formatNumber(item.amount)}</div>
      ),
      style: (i) => ({ backgroundColor: getColor(i.percent) }),
    },
    {
      title: 'Utilized',
      tableHeadStyle: { textAlign: 'right', color: 'black' },
      custom: true,
      component: (item) => (
        <div style={{ textAlign: 'right' }}>
          {formatNumber(parseFloat(item.amount) - parseFloat(item.budget))}
        </div>
      ),
      style: (i) => ({ backgroundColor: getColor(i.percent) }),
    },

    {
      title: 'Variance',
      tableHeadStyle: { textAlign: 'right', color: 'black' },
      custom: true,
      component: (item) => (
        <div style={{ textAlign: 'right' }}>{formatNumber(item.budget)}</div>
      ),
      style: (i) => ({ backgroundColor: getColor(i.percent) }),
    },
    {
      title: '% Utilized',
      component: (i) => (
        <div className="text-center">{parseFloat(i.percent).toFixed(2)}</div>
      ),
      className: 'text-center',
      style: (i) => ({ backgroundColor: getColor(i.percent) }),
    },
  ]

  //   const TableRow = (props) => {
  //     const {
  //         funding_code,
  //         mda_source_account,
  //       price,
  //       issueDate,
  //       dueDate,
  //       status,
  //     } = props
  //     const statusVariant =
  //       status === 'Paid'
  //         ? 'success'
  //         : status === 'Due'
  //         ? 'warning'
  //         : status === 'Canceled'
  //         ? 'danger'
  //         : 'primary'

  //     return (
  //       <tr>
  //         <td>
  //           <Card.Link as={Link} to={Routes.Invoice.path} className="fw-normal">
  //             {props.i + 1}
  //           </Card.Link>
  //         </td>
  //         <td>
  //           <span className="fw-normal">{funding_code}</span>
  //         </td>
  //         <td>
  //           <span className="fw-normal">{mda_source_account}</span>
  //         </td>
  //         <td>
  //           <span className="fw-normal">{dueDate}</span>
  //         </td>
  //         <td>
  //           <span className="fw-normal">${parseFloat(price).toFixed(2)}</span>
  //         </td>
  //         <td>
  //           <span className={`fw-normal text-${statusVariant}`}>{status}</span>
  //         </td>
  //         <td>
  //           <Dropdown as={ButtonGroup}>
  //             <Dropdown.Toggle
  //               as={Button}
  //               split
  //               variant="link"
  //               className="text-dark m-0 p-0"
  //             >
  //               <span className="icon icon-sm">
  //                 <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
  //               </span>
  //             </Dropdown.Toggle>
  //             <Dropdown.Menu>
  //               <Dropdown.Item>
  //                 <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
  //               </Dropdown.Item>
  //               <Dropdown.Item>
  //                 <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
  //               </Dropdown.Item>
  //               <Dropdown.Item className="text-danger">
  //                 <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
  //               </Dropdown.Item>
  //             </Dropdown.Menu>
  //           </Dropdown>
  //         </td>
  //       </tr>
  //     )
  //   }

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="p-0">
        <CustomTable
          size="sm"
          className="file-list"
          fields={fields}
          data={list}
        />
        {/* <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">S/N</th>
              <th className="border-bottom">Account Name</th>
              <th className="border-bottom">Account Number</th>
              <th className="border-bottom">Bank Name</th>
              <th className="border-bottom">Balance</th>
              <th className="border-bottom">Action</th>
            </tr>
          </thead>
          <tbody>
            {list.map((t, i) => (
              <TableRow i={i} key={i} {...t} />
            ))}
          </tbody>
        </Table> */}
        <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          <Nav>
            <Pagination className="mb-2 mb-lg-0">
              {/* <Pagination.Prev>Previous</Pagination.Prev> */}

              {list.map((_, index) => (
                <Pagination.Item
                  key={index}
                  active={currentPage === index + 1}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
              {/* <Pagination.Next>Next</Pagination.Next> */}
            </Pagination>
          </Nav>
          {/* <small className="fw-bold">
            Showing <b>{totalTransactions}</b> out of <b>25</b> entries
          </small> */}
        </Card.Footer>
      </Card.Body>
    </Card>
  )
}

export default BudgetUtilizationTable
