import React from 'react'
import { Button, ButtonGroup, Card } from '@themesberg/react-bootstrap'

import { useState } from 'react'
import {  useHistory, useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import {  postApi } from '../../../redux/api'
import CustomTable from '../../../components/CustomTable'
import { Routes } from '../../../routes'
import { useContext } from 'react'
import { SearchContext } from '../../HomePage'
import { useSelector } from 'react-redux'
import { MODULE_TYPES } from '../../../redux/utils'

function BatchProcessingIndex({
  status = 'pending',
  pageType = 'batch',
  type = 'main_treasury',
}) {
  const user = useSelector((state) => state.auth.user)
  const { searchTerm } = useContext(SearchContext)
  const location = useLocation()
  const history = useHistory()
  const isST = location.pathname.includes('batch-process-st')
  // const [name, setName] = useContext(UserContext)
  // const details = useHistory()
  const [paymentSchedule, setPaymentScheduleTable] = useState([])

  const isUpdatePage = pageType === 'update'
  const isCommitPage = pageType === 'commit'
  const isCommittedPage = pageType === 'committed'
  const isRemittancePage = pageType === 'remittance'

  // const [searchTerm, setSearchTerm] = useState('')
  const fields = [
    {
      title: 'S/N',
      custom: true,
      component: (item, idx) => <span>{idx + 1}</span>,
      style: (item) =>
        item.status === 'returned' ? { backgroundColor: 'orange' } : {},
    },
    {
      title: 'Batch No',
      // value: 'batch_number',
      custom: true,
      component: (item) => (
        <div>{item.batch_number}</div>
      ),
      className: 'text-center',
      style: (item) =>
        item.status === 'returned' ? { backgroundColor: 'orange' } : {},
    },
    {
      title: 'No. Vouchers',
      custom: true,
      component: (item) => <span>{item.no_of_payments}</span>,
      style: (item) =>
        item.status === 'returned'
          ? { backgroundColor: 'orange', textAlign: 'center' }
          : { textAlign: 'center' },
    },
    {
      title: 'Total(N)',
      tableHeadStyle: { textAlign: 'right', color: 'black' },
      custom: true,
      component: (item) => (
        <div style={{ textAlign: 'right' }}>
          {parseFloat(item.total_amount).toLocaleString('en')}
        </div>
      ),
      style: (item) =>
        item.status === 'returned' ? { backgroundColor: 'orange' } : {},
    },
    {
      title: 'Action',
      className: 'text-center',
      custom: true,
      component: (item) => (
        <div>
          {/* <Button className="bg-warning w-110 text-white me-1" size="sm">
              View Approval
            </Button> */}
          <Button
            item={item}
            onClick={() => {
              if (isUpdatePage) {
                history.push(
                  `${Routes.batchProcessing.viewPaymentList}/${item.batch_number}`,
                )
              } else if (isCommitPage) {
                history.push(`${Routes.payments.process}/${item.batch_number}`)
              } else if (isRemittancePage) {
                if (type === MODULE_TYPES.SUB_TREASURY) {
                  history.push(
                    `${Routes.remittance.viewPaymentAdvice}/${item.batch_number}`,
                  )
                } else {
                  history.push(
                    `${Routes.remittance.viewPDF}/${item.batch_number}`,
                  )
                }
              } else {
                if (status.toLowerCase() === 'committed') {
                  history.push(`${Routes.payments.viewPDF}/${item.batch_number}`)
                } else {
                  history.push(
                    `${Routes.batchProcessing.process}/${item.batch_number}`,
                  )
                  // history.push(`/dashboard/process/${item.batch_number}`)
                }
              }
            }}
            className="bg-success w-110 text-white"
            size="sm"
          >
            {isUpdatePage
              ? 'View Batch'
              : isCommitPage
              ? 'Process Approval'
              : isRemittancePage
              ? 'Generate Slip'
              : status.toLowerCase() === 'committed'
              ? 'View Schedule'
              : 'Process'}
          </Button>
        </div>
      ),
      style: (item) =>
        item.status === 'returned' ? { backgroundColor: 'orange' } : {},
    },
  ]

  useEffect(() => {
    postApi(`get_batch_list`, {
      query_type: isUpdatePage ? 'select_uncommitted' : 'select',
      status:
        isUpdatePage || isRemittancePage
          ? 'Committed'
          : isCommitPage
          ? 'auditor_approved'
          : status,
      type: user.role,
    })
      .then((data) => {
        console.log(data)
        if (data.result) {
          setPaymentScheduleTable(data.result)
        }
      })

      .catch((err) => {})
  }, [user.role, status, isUpdatePage, isCommitPage, isRemittancePage])

  let rows = []

  paymentSchedule &&
    paymentSchedule.forEach((item) => {
      if (
        item?.batch_number &&
        item?.batch_number?.toLowerCase().indexOf(searchTerm.toLowerCase()) === -1 /// &&
        // item?.no_of_payments?.indexOf(searchTerm.toLowerCase()) === -1 &&
        // item?.customer_code?.toLowerCase().indexOf(searchTerm.toLowerCase()) === -1
      ) {
        return
      }

      rows.push(item)
    })

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>
            {/* <FontAwesomeIcon icon={faHourglass} className="me-2" />{' '} */}
            {isUpdatePage
              ? 'Payment Update'
              : isCommitPage
              ? 'Commit Payment'
              : isRemittancePage
              ? 'Remittance Slip'
              : 'Batch Processing'}
          </h4>
        </div>
      </div>
      {/* {JSON.stringify(paymentSchedule)} */}
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="p-0">
          <CustomTable
            size="sm"
            fields={fields}
            data={rows}
            // currentPage={currentPage}
            // handlePageChange={handlePageChange}
          />
        </Card.Body>
      </Card>
    </>
  )
}

export default BatchProcessingIndex
