import { Button, Table } from '@themesberg/react-bootstrap'
import React from 'react'

function ScheduleTable({ paymentTable, handleDelete, handleEdit }) {
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>S/N</th>
            <th>MDA</th>
            <th>Acct No</th>
            <th>Sort Code</th>
            <th>Description</th>
            <th>Amount</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {paymentTable.map((item, index) => (
            <tr>
              <th>{index + 1}</th>
              <td>{item.mda_name}</td>
              <td>{item.mda_account_no}</td>
              <td>{item.mda_acct_sort_code}</td>
              <td>{item.description}</td>
              <td>{item.amount}</td>
              <td>
                <Button
                  className="btn"
                  size="sm"
                  style={{ marginRight: '6px', display: 'inLine' }}
                  color="primary"
                  onClick={() => handleEdit(index)}
                >
                  Edit
                </Button>

                <Button
                  color="danger"
                  className="btn"
                  size="sm"
                  style={{ marginRight: '6px' }}
                  onClick={() => handleDelete(index)}
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <br />
    </>
  )
}

export default ScheduleTable
