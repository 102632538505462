
import React, { useState } from "react";
import SimpleBar from 'simplebar-react';
import { useLocation } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faBoxOpen, faChartPie, faCog, faFileAlt, faHandHoldingUsd, faSignOutAlt, faTable, faTimes, faCalendarAlt, faMapPin, faInbox, faRocket, faMoneyBill, faUserTag, faPiggyBank, faListUl, faChartLine, faPeopleCarry, faCaretDown, faArrowDown, faCaretUp, faUsers } from "@fortawesome/free-solid-svg-icons";
import { Nav, Badge, Image, Button, Dropdown, Accordion, Navbar } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

import { Routes } from "../routes";
import ReactHero from "../assets/img/state-govt-logo.png";
import ProfilePicture from "../assets/img/default_avatar.png";
import {  faGratipay, faStickerMule } from "@fortawesome/free-brands-svg-icons";
import { useSelector } from "react-redux";
import { faCaretSquareDown } from "@fortawesome/free-regular-svg-icons";

export default (props = {}) => {
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";
  const user = useSelector(state => state.auth.user)

  const onCollapse = () => setShow(!show);

  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon, children = null } = props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";
    const [isOpen, setIsOpen] = useState(false)

    return (
      <div >
        {/* <Accordion.Item eventKey={eventKey}> */}
          <Nav.Item className="" onClick={() => setIsOpen(p=>!p)}>
          <Nav.Link className="d-flex flex-direction-row justify-content-between align-items-center">
            <span>
              <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span>
              <span className="sidebar-text">{title}</span>
            </span>
            <FontAwesomeIcon icon={isOpen ? faCaretUp : faCaretDown} size="20" className="ms-1"/>
            </Nav.Link>
          </Nav.Item>
         {isOpen && 
            <Nav className="flex-column ms-2">
              {children}
            </Nav>
        }
        {/* </Accordion.Item> */}
      </div>
    );
  };

  const NavItem = (props) => {
    const { title, link, external, target, icon, image, badgeText, badgeBg = "secondary", badgeColor = "primary", imageHeight,
    imageWidth, imageStyle={} } = props;
    const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span> : null}
            {image ? <Image src={image} width={imageHeight || 20}
                height={imageWidth || 20} className="sidebar-icon svg-icon" style={imageStyle} /> : null}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar color='success' expand={false} collapseOnSelect variant="primary" className="navbar-theme-success px-4 d-md-none">
        <Navbar.Brand className="me-lg-5" as={Link} to={Routes.DashboardOverview.path}>
          <Image src={ReactHero} height={50} />
        </Navbar.Brand>
        <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}>
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <Image src={ProfilePicture} className="card-img-top rounded-circle border-white" />
                </div>
                <div className="d-block">
                  <h6>Hi, Abubakar</h6>
                  <Button as={Link} variant="secondary" size="xs" to={Routes.Signin.path} className="text-dark">
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> Sign Out
                  </Button>
                </div>
              </div>
              <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              {/* <NavItem title="T - BEAMS" link={Routes.Presentation.path} image={ReactHero} /> */}
              <NavItem
                title="T-BEAMS"
                link={'/'}
                image={ReactHero}
                imageHeight={100}
                imageWidth={100} imageStyle={{borderRadius: "90px", }}
              />

              <NavItem title="Dashboard" link={Routes.DashboardOverview.path} icon={faChartPie} />

               {user.role === 'main_treasury' ? <>
               {user.accessTo?.includes("TSA Funding") && (<CollapsableNavItem eventKey="tsa-funding/" title="TSA Funding" icon={faMoneyBill}>
                <NavItem title="FAAC TSA Funding" link={Routes.tsafunding.faacTsaFunding+"/FAAC TSA Funding"} />
                <NavItem title="JAAC TSA Funding" link={Routes.tsafunding.faacTsaFunding+"/JAAC TSA Funding"} />
              </CollapsableNavItem>)}

              {user?.accessTo?.includes("Budget") && (<CollapsableNavItem eventKey="budget/" title="Budget" icon={faUserTag}>
                <NavItem title="Budget Utilization" link={Routes.budget.budgetUtlization} />
                <NavItem title="Upload Budget" link={Routes.budget.uploadBudget} />
              </CollapsableNavItem>)}

            {user?.accessTo?.includes("Bank") && (  <CollapsableNavItem eventKey="bank/" title="Bank" icon={faPiggyBank}>
                <NavItem title="Update Bank Details" link={Routes.bank.bankIndex} />
              </CollapsableNavItem>)}

              {user?.accessTo?.includes("Approval Collection") &&(<CollapsableNavItem eventKey="approval/" title="Approval Collection" icon={faListUl}>
                <NavItem title="Approval Collection" link={Routes.approval.approvalIndex} />
              </CollapsableNavItem>)}

             {user.accessTo?.includes("Create Schedule") && ( <CollapsableNavItem eventKey="payment-schedule/" title="Payment Schedule" icon={faGratipay}>
                <NavItem title="Create Payment Schedule" link={Routes.paymentSchedule.index} />
                <NavItem title="View Payment Schedules" link={Routes.paymentSchedule.viewSchedule} />
              </CollapsableNavItem>)}

              {/* <CollapsableNavItem eventKey="recurrent-payments/" title="Recurrent Payments" icon={faFileAlt}>
                <NavItem title="Overhead" link={Routes.tsafunding.faacTsaFunding+"?types=FAAC TSA Funding"} />
                <NavItem title="Standing Orders" link={Routes.tsafunding.jaacTsaFunding+"?types=JAAC TSA Funding"} />
              </CollapsableNavItem> */}

              {user?.accessTo?.includes("Internal Audit") && (<CollapsableNavItem eventKey="internal-audit/" title="Internal Audit" icon={faChartLine}>
                <NavItem title="Batch Processing" link={Routes.batchProcessing.index} />
                <NavItem title="Payment Update" link={Routes.batchProcessing.update} />
              </CollapsableNavItem>)}

              {user?.accessTo?.includes("Payments") && (<CollapsableNavItem eventKey="payments/" title="Payments" icon={faFileAlt}>
                <NavItem title="Commit Payments" link={Routes.payments.index} />
              </CollapsableNavItem>)}

             {user?.accessTo?.includes("Remittance") &&( <CollapsableNavItem eventKey="remittance/" title="Remittance" icon={faInbox}>
                <NavItem title="Remittance Slip" link={Routes.remittance.index} />
              </CollapsableNavItem>)}

             {user?.accessTo?.includes("Reports") && ( <CollapsableNavItem eventKey="reports/" title="Reports" icon={faFileAlt}>
                <NavItem title="Budget Utilization Reports" link={Routes.budget.budgetUtlization} />
              </CollapsableNavItem>)}



              {/* <NavItem title="Transactions" icon={faHandHoldingUsd} link={Routes.Transactions.path} /> */}
               </>
                :
               <>
               {user?.accessTo?.includes("PVCollection") && ( <CollapsableNavItem eventKey="collection/" title="PV Collection" icon={faFileAlt}>
                  <NavItem title="PV Collection" link={Routes.pv.pvlIndex} />
                </CollapsableNavItem>)}

               {user?.accessTo?.includes("Payment Schedule") &&( <CollapsableNavItem eventKey="payment-schedule/" title="Payment Schedule" icon={faGratipay}>
                  <NavItem title="Payment Schedule" link={Routes.contractorSchedule.index} />
                  {/* <NavItem title="View Payment Schedules" link={Routes.tsafunding.jaacTsaFunding+"?types=JAAC TSA Funding"} /> */}
                </CollapsableNavItem>)}

                {user?.accessTo?.includes("Internal Audit") && <CollapsableNavItem eventKey="internal-audit/" title="Internal Audit" icon={faChartLine}>
                  <NavItem title="Batch Processing" link={Routes.batchProcessing.index} />
                  {/* <NavItem title="Payment Update" link={Routes.batchProcessing.update} /> */}
                </CollapsableNavItem>}

               {user?.accessTo?.includes("Commit Payment") && <CollapsableNavItem eventKey="payments/" title="Commit Payment" icon={faFileAlt}>
                  <NavItem title="Commit Payments" link={Routes.payments.index} />
                </CollapsableNavItem>}

               {user?.accessTo?.includes("Remittance") && <CollapsableNavItem eventKey="remittance/" title="Remittance" icon={faInbox}>
                  <NavItem title="Remittance Slip" link={Routes.remittance.paymentAdviceIndex} />
                </CollapsableNavItem>}

                {user?.accessTo?.includes("Reports") && <CollapsableNavItem eventKey="reports/" title="Reports" icon={faFileAlt}>
                  <NavItem title="Expenditure Reports" link={Routes.budget.budgetUtlization} />
                </CollapsableNavItem>}
               </>}

              {/* <CollapsableNavItem eventKey="tables/" title="Tables" icon={faTable}>
                <NavItem title="Bootstrap Table" link={Routes.BootstrapTables.path} />
              </CollapsableNavItem> */}

              {/* <CollapsableNavItem eventKey="examples/" title="Page Examples" icon={faFileAlt}>
                <NavItem title="Sign In" link={Routes.Signin.path} />
                <NavItem title="Sign Up" link={Routes.Signup.path} />
                <NavItem title="Forgot password" link={Routes.ForgotPassword.path} />
                <NavItem title="Reset password" link={Routes.ResetPassword.path} />
                <NavItem title="Lock" link={Routes.Lock.path} />
                <NavItem title="404 Not Found" link={Routes.NotFound.path} />
                <NavItem title="500 Server Error" link={Routes.ServerError.path} />
              </CollapsableNavItem> */}

            {user?.accessTo?.includes("User Admin") && (  <NavItem title="User Management" icon={faUsers} link={Routes.userAdmin.index} />)}

              <Dropdown.Divider className="my-3 border-indigo" />

              {/* <CollapsableNavItem eventKey="documentation/" title="Getting Started" icon={faBook}>
                <NavItem title="Overview" link={Routes.DocsOverview.path} />
                <NavItem title="Download" link={Routes.DocsDownload.path} />
                <NavItem title="Quick Start" link={Routes.DocsQuickStart.path} />
                <NavItem title="License" link={Routes.DocsLicense.path} />
                <NavItem title="Folder Structure" link={Routes.DocsFolderStructure.path} />
                <NavItem title="Build Tools" link={Routes.DocsBuild.path} />
                <NavItem title="Changelog" link={Routes.DocsChangelog.path} />
              </CollapsableNavItem>
              <CollapsableNavItem eventKey="components/" title="Components" icon={faBoxOpen}>
                <NavItem title="Accordion" link={Routes.Accordions.path} />
                <NavItem title="Alerts" link={Routes.Alerts.path} />
                <NavItem title="Badges" link={Routes.Badges.path} />
                <NavItem external title="Widgets" link="https://demo.themesberg.com/volt-pro-react/#/components/widgets" target="_blank" badgeText="Pro" />
                <NavItem title="Breadcrumbs" link={Routes.Breadcrumbs.path} />
                <NavItem title="Buttons" link={Routes.Buttons.path} />
                <NavItem title="Forms" link={Routes.Forms.path} />
                <NavItem title="Modals" link={Routes.Modals.path} />
                <NavItem title="Navbars" link={Routes.Navbars.path} />
                <NavItem title="Navs" link={Routes.Navs.path} />
                <NavItem title="Pagination" link={Routes.Pagination.path} />
                <NavItem title="Popovers" link={Routes.Popovers.path} />
                <NavItem title="Progress" link={Routes.Progress.path} />
                <NavItem title="Tables" link={Routes.Tables.path} />
                <NavItem title="Tabs" link={Routes.Tabs.path} />
                <NavItem title="Toasts" link={Routes.Toasts.path} />
                <NavItem title="Tooltips" link={Routes.Tooltips.path} />
              </CollapsableNavItem> */}
              </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
