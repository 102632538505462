import React from 'react'
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Row,
  Table,
} from '@themesberg/react-bootstrap'

import { useState } from 'react'
import { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import BudgetUtilizationTable from './components/BudgetUtilizationTable'
import { useEffect } from 'react'
import { apiURL, fetchApi, postApi } from '../../../redux/api'
import useQuery from '../../../hooks/useQuery'
import { formatNumber } from '../../../redux/utils'
import CustomTable from '../../../components/CustomTable'
import BackButton from '../../../components/BackButton'

function BudgetUtilizationReport() {
  const history = useHistory()
  const query = useQuery()
  const _mda_name = query.get('mda_name')
  const description = query.get('description')
  const amount = query.get('amount')
  const balance = query.get('balance')
  const percentage = query.get('percentage')
  const mda_code = query.get('mda_code')
  const budget_year = query.get('budget_year')
  // const usei = useQuery.get()
  const { mda_name } = useParams()
  console.log('mda_name', mda_name)
  const [budgetUtilizationReport, setBudgetUtilizationReport] = useState([])
  const [tresuryDetails, setTresuryDetails] = useState({})
  const [changeAmount, setChangeAmount] = useState(false)
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)
  const [amountIndex, setAmountIndex] = useState(0)
  const [form, setForm] = useState({
    batch_number: '',
    date: '',
    no_of_mda: '',
    no_of_payments: '',
    total_amount: '',
    cheque_number: '',
    query_type: 'insert',
    status: 'uncommited',
    amount: '',
  })

  useEffect(() => {
    postApi('get_batch_list', {
      query_type: 'select_payment_list',
      status: 'pending',
    })
      .then((data) => {
        console.log('gg', data)
        if (data.result) {
          // setPaymentDetails(data.result)
          const formData = data.result[0]
          setForm((p) => ({ ...p, ...formData }))
          // setPaymentScheduleTable(data.result);
        }
      })

      .catch((err) => {
        // alert(err);
        // setLoadSpinner(false);
      })
  }, [])

  useEffect(() => {
    postApi('post_payment_schedule', {
      query_type: 'select_budget_utilization_by_id',
      mda_economic_code: mda_name,
      mda_code,
      budget_year,
      // status : "Paid",
      // batch_no
    })
      .then((data) => {
        console.log('data', data)
        if (data.result && data.result.length) {
          setForm(data.result[0])
          setBudgetUtilizationReport(data.result)
        }
      })

      .catch((err) => {
        // alert(err);
        // setLoadSpinner(false);
      })
  }, [mda_name, budget_year, mda_code])

  return (<>
  <BackButton />
    <Card className='px-4'>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center my-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Budget Utilization</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">
              Share
            </Button>
            <Button variant="outline-primary" size="sm">
              Export
            </Button>
          </ButtonGroup>
        </div>
      </div>

      <Row>
        <Col md={6}>
          <h6>MDA: {_mda_name}</h6>

          <h6>Budget Code: {mda_name}</h6>
          <h6>Balance: ₦{formatNumber(balance)}</h6>
        </Col>
        <Col md={6}>
          <h6>Description: {description}</h6>

          <h6>Budget Amount: ₦{formatNumber(amount)}</h6>
          <h6>Percentage Utilization: {percentage}%</h6>
        </Col>
      </Row>

      <div className="mt-2">
        <CustomTable reponsive
          fields={[
            {
              title: 'S/N',
              custom: true,
              component: (item, index) => <div>{index + 1}</div>,
            },
            { title: 'Date', value: 'entries_date' },
            {
              title: 'Debit (₦)',
              custom: true,
              component: (item, index) => (
                <div className="text-end">{formatNumber(item.dr)}</div>
              ),
            },
            {
              title: 'Credit (₦)',
              custom: true,
              component: (item, index) => (
                <div className="text-end">{formatNumber(item.cr)}</div>
              ),
            },
          ]}
          data={budgetUtilizationReport}
        />
      </div>
    </Card></>
  )
}

export default BudgetUtilizationReport
