import React from 'react'
import { formatNumber } from '../../../../redux/utils'
import { Button, Table } from '@themesberg/react-bootstrap'

function TsaFundingBatchList({ tsaBatch, handleDelete, handleEdit }) {
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>S/N</th>
            <th>Date</th>
            <th>Source Account</th>
            <th>TSA Account</th>
            <th>Amount</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {tsaBatch.map((item, index) => (
            <tr>
              <th>{index + 1}</th>
              <td>{item.fund_date}</td>
              <td>{item.mda_source_account}</td>
              <td>{item.treasury_account_name}</td>
              <td style={{ textAlign: 'right' }}>
                {formatNumber(item.amount)}
              </td>

              <td>
                <Button
                  className="btn"
                  size="sm"
                  style={{ marginRight: '6px', display: 'inLine' }}
                  color="primary"
                  // onClick={() => handleEdit(index)}
                >
                  Edit
                </Button>

                <Button
                  color="danger"
                  className="btn"
                  size="sm"
                  style={{ marginRight: '6px' }}
                  onClick={() => handleDelete(index)}
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <br />
    </>
  )
}
export default TsaFundingBatchList
