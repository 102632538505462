import React from 'react'
import { Button, Card, Col, Form, Modal, Row } from '@themesberg/react-bootstrap'

import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import { postApi } from '../../../redux/api'
import CustomTable from '../../../components/CustomTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartLine } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import BackButton from '../../../components/BackButton'
import { Routes } from '../../../routes'
import PaymentModal from './PaymentModal'
import { MODULE_TYPES } from '../../../redux/utils'

function Process({ match, pageType = 'batch', type = null }) {
  const user = useSelector((state) => state.auth.user)

  const [deleteAllWarningModalIsOpen, setDeleteAllWarningModalOpen] = useState(false)
  const [deleteSignleWarningModalIsOpen, setDeleteSignleWarningModalOpen] = useState(false)
  const toggleDeleteAll = () => setDeleteAllWarningModalOpen(p=>!p)
  const toggleDeleteSingle = () => setDeleteSignleWarningModalOpen(p=>!p)
  const [itemToDelete, setItemToDelete] = useState("")
  const [_loading, _setLoading] = useState(false)

  const history = useHistory()
  const batch_no = match.params.batch_no
  const [paymentScheduleTable, setPaymentScheduleTable] = useState([])
  const [tresuryDetails, setTresuryDetails] = useState({})
  const [paymentDetails, setPaymentDetails] = useState([])

  const [changeAmount, setChangeAmount] = useState(false)
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(p => !p)

  const [form, setForm] = useState({
    batch_number: '',
    date: '',
    no_of_mda: '',
    no_of_payments: '',
    total_amount: '',
    cheque_number: '',
    query_type: 'insert',
    status: 'uncommited',
    amount: '',
  })
  const isST = user.role === 'sub_treasury'
  const isUpdatePage = pageType === 'update'
  const isCommitPage = pageType === 'commit'

  useEffect(() => {
    postApi(`get_batch_list`, {
      query_type: isUpdatePage
        ? 'select_payment_skipped_list'
        : 'select_by_batch_no',
      status: isUpdatePage
        ? 'Committed'
        : isCommitPage
        ? 'auditor_approved'
        : 'pending',
      batch_no,
      type: user.role,
    })
      .then((data) => {
        console.log('data', data)
        if (data.result && data.result.length) {
          setPaymentScheduleTable(data.result)
          setTresuryDetails(data.result[0])
        }
      })

      .catch((err) => {
        // alert(err);
        // setLoadSpinner(false);
      })
  }, [batch_no, user.role, isUpdatePage, isCommitPage])

  const handleApi = () => {
    if (isST) {
      postApi(`post_contractor_payment_schedule_array`, {
        paymentScheduleTable,
        query_type: 'update',
        status: 'auditor_approved',
        type: user.role,
      })
        .then((data) => {
          if (data.success) {
            alert('Auditor Approved Successfully')
            history.goBack()
          }
        })

        .catch((err) => {
          alert(err)
        })
    } else {
      postApi(`post_payment_schedule_array`, {
        paymentScheduleTable,
        query_type: 'update',
        status: 'auditor_approved',
        type: user.role,
      })
        .then((data) => {
          if (data.success) {
            alert('Auditor Approved Successfully')
            history.goBack()
          }
        })

        .catch((err) => {
          alert(err)
        })
    }
  }

  const handleAllReturned = () => {
    if (isST) {
      postApi(`post_contractor_payment_schedule_array`, {
        paymentScheduleTable,
        query_type: 'update',
        status: 'remove',
        type: user.role,
        contractor_code: batch_no
      })
        .then((data) => {
          if (data.success) {
            console.log(data)
            history.goBack()
          }
        })

        .catch((err) => {
          console.log(err)
          alert('An error occurred')
        })
      } else {
    postApi(`post_payment_schedule_array`, {
      paymentScheduleTable,
      query_type: 'update',
      status: 'remove',
      type: user.role,
      contractor_code: batch_no
    })
      .then((data) => {
        if (data.success) {
          console.log(data)
          history.goBack()
        }
      })

      .catch((err) => {
        console.log(err)
        alert('An error occurred')
      })
    }
  }

  const submitIndividualItem = (id) => {
    const newArray = paymentScheduleTable.filter((itm, idx) => itm.id === id)
    if (isST) {
    postApi(`post_contractor_payment_schedule_array`, {
      paymentScheduleTable: newArray,
      query_type: 'update',
      type: user.role,
      status: 'remove',
    })
      .then((data) => {
        if (data.success) {
          alert('Deleted Successfully')
          history.goBack()
        }
      })

      .catch((err) => {
        alert(err)
      })
  } else {
    console.log('newArray', newArray)
    postApi(`post_payment_schedule_array`, {
      paymentScheduleTable: newArray,
      query_type: 'update',
      type: user.role,
      status: 'remove',
    })
      .then((data) => {
        if (data.success) {
          console.log(data)
          history.goBack()
        }
      })

      .catch((err) => {
        alert(err)
      })
    }
  }

  const handleChange = (id, name, value, e) => {
    // const {name, value} = e.target

    // const value1 = addCommas(removeNonNumeric(value))
    // const value1 = addCommas(value)
    const newArray = []

    paymentScheduleTable.forEach((itm, idx) => {
      if (itm.id === id) {
        newArray.push({ ...itm, [name]: value })
      } else {
        newArray.push(itm)
      }
    })

    setPaymentScheduleTable(newArray)
  }

  const handleChange1 = (e) => {
    const { name, value } = e.target

    setForm((p) => ({ ...p, [name]: value }))
  }

  useEffect(() => {
    postApi(`get_batch_list`, {
      query_type: 'select_payment_list',
      status: isCommitPage ? 'auditor_approved' : 'pending',
      batch_no,
      type: user.role,
    })
      .then((data) => {
        console.log('gg', data)
        if (data.result) {
          setPaymentDetails(data.result)
          const formData = data.result[0]
          setForm((p) => ({ ...p, ...formData }))
          // setPaymentScheduleTable(data.result);
        }
      })

      .catch((err) => {
        // alert(err);
        // setLoadSpinner(false);
      })
  }, [batch_no, user.role, isCommitPage])

  const updatePaymentSchedule = () => {
    if (isST) {
      postApi(`post_contractor_payment_schedule_array`, {
        paymentScheduleTable,
        query_type: 'update_by_batch_no',
        status: 'Committed',
        cheque_number: form.cheque_number,
        arabic_date: form.arabic_date,
        type: user.role,
      })
        .then((data) => {
          if (data.success) {
            console.log('hell', data)
            // setOpenPdf(true)
            // if (type === MODULE_TYPES.MAIN_TREASURY) {
            //   history.push(`${Routes.payments.viewPDF}/${batch_no}`)
            // } else if (type === MODULE_TYPES.SUB_TREASURY) {
              history.push(`${Routes.payments.viewStPDF}/${batch_no}`)
            // }
          }
        })

        .catch((err) => {
          alert(err)
        })
    } else {
      postApi(`post_payment_schedule_array`, {
        paymentScheduleTable,
        query_type: 'update_by_batch_no',
        status: 'Committed',
        cheque_number: form.cheque_number,
        arabic_date: form.arabic_date,
        type: user.role,
      })
        .then((data) => {
          if (data.success) {
            console.log('hell', data, type)
            // setOpenPdf(true)
            if (type === MODULE_TYPES.MAIN_TREASURY) {
              history.push(`${Routes.payments.viewPDF}/${batch_no}`)
            } else if (type === MODULE_TYPES.SUB_TREASURY) {
              history.push(`${Routes.payments.viewStPDF}/${batch_no}`)
            }
          }
        })

        .catch((err) => {
          alert(err)
        })
    }
  }

  const submitChequeNo = () => {
    postApi(`post_check_details`, {
      form,
      type: user.role,
    })
      .then((data) => {
        updatePaymentSchedule()
        if (data.success) {
          console.log('hello', data)

          // toggle()
          // history.push("/dashboard/intAudit")
        }
      })

      .catch((err) => {
        alert(err)
      })
  }

  const fields = [
    {
      title: 'S/N',
      custom: true,
      component: (item, idx) => <span>{idx + 1}</span>,
    },
    {
      title: 'MDA',
      value: 'mda_name',
      //   className: 'text-center',
    },
    {
      title: 'Description',
      custom: true,
      component: (item) => (
        <span>{isST ? item.project_description : item.description}</span>
      ),
    },
    {
      title: isST ? 'Contractor' : 'Approval',
      tableHeadStyle: { textAlign: 'right', color: 'black' },
      custom: true,
      component: (item) => (
        <>
          <label style={{ float: 'left' }}>
            {isST ? item.contractor : item.approval}
          </label>
          {item.approval === 'No Approval' ? (
            <Form.Check
              type="checkbox"
              name="approval"
              value="skipped"
              onChange={(e) =>
                handleChange(item.id, e.target.name, e.target.value, e)
              }
            />
          ) : null}
        </>
      ),
    },
    {
      title: 'Attachment',
      className: 'text-center',
      custom: true,
      hidden: isST,
      component: (item) => (
        <>
          {item.attachment}
          {item.attachment === 'No Attachment' ? (
            <Form.Check
              type="checkbox"
              name="attachment"
              value="skipped"
              checked={item.attachment === 'skipped'}
              onChange={(e) =>
                handleChange(item.id, e.target.name, e.target.value, e)
              }
            />
          ) : null}
        </>
      ),
    },
    {
      title: 'Budget',
      hidden: isST,
      tableHeadStyle: { textAlign: 'right', color: 'black' },
      custom: true,
      component: (item) => (
        <>
          {item.budget}
          {item.budget === 'No Budget' || item.budget === 'Budget Exausted' ? (
            <Form.Check
              type="checkbox"
              name="budget"
              value="skipped"
              onChange={(e) =>
                handleChange(item.id, e.target.name, e.target.value, e)
              }
            />
          ) : null}
        </>
      ),
    },
    {
      title: 'Amount',
      custom: true,
      component: (item) => (
        <div className="text-end">
          {!changeAmount ? (
            parseFloat(item.amount).toLocaleString('en')
          ) : (
            // item.amount.toLocaleString()
            <Form.Control
              type="text"
              name="amount"
              value={item.amount}
              onChange={(e) =>
                handleChange(item.id, e.target.name, e.target.value, e)
              }
            />
          )}
        </div>
      ),
    },
    {
      title: 'Action',
      tableHeadStyle: { textAlign: 'right', color: 'black' },
      custom: true,
      component: (item) => (
        <>
          {isUpdatePage ? (
            <>
              <Button
                size="sm"
                onClick={() =>
                  history.push(
                    `${Routes.batchProcessing.processUpdate}/${item.id}?imageId=${item.imageId}`,
                  )
                }
              >
                Update
              </Button>
            </>
          ) : (
            <>
              <Button
                className="btn-danger"
                size="sm"
                onClick={() => {setItemToDelete(item.id); toggleDeleteSingle()}}
              >
                {isCommitPage ? 'Return' : 'Delete'}
              </Button>
              <Button
                className="btn-success mx-1"
                size="sm"
                onClick={() => {
                  if (isST) {
                    history.push(
                      `${Routes.paymentSchedule.viewStScheduleDetails}/${item.contract_code}`
                    )
                  } else {
                    history.push(
                      // `${Routes.paymentSchedule.view}/${item.id}`
                      `/dashboard/view_individual_batch/${item.id}`)
                  }
                }}
              >
                View
              </Button>
            </>
          )}
        </>
      ),
    },
  ]

  return (
    <>
      <Row>
        <Col md={3} className="">
          <BackButton />
        </Col>
        <Col md={6}>
          <h4 className="text-center">
            <FontAwesomeIcon icon={faChartLine} className="me-2" />{' '}
            {isUpdatePage || isCommitPage ? 'Payment List' : 'Internal Audit'}
          </h4>
        </Col>
      </Row>
      {/* {JSON.stringify(type)} */}
      {isUpdatePage ? null : (
        <Card body>
          <Row>
            <Col md={6}>
              {isST ? null : (
                <h6>Bank Name : {tresuryDetails.treasury_bank_name}</h6>
              )}

              <h6>
                Batch No. : {isST ? form.batch_number : tresuryDetails.batch_no}
              </h6>
              {
                //<h6>Balance :</h6>
              }
            </Col>
            <Col md={6}>
              {isST ? null : (
                <h6>
                  Source Account : {tresuryDetails.treasury_source_account}
                </h6>
              )}

              <h6>Total Amount : {form.total_amount}</h6>
            </Col>
          </Row>
        </Card>
      )}
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="p-0">
          <CustomTable
            size="sm"
            fields={fields}
            data={paymentScheduleTable}
            // currentPage={currentPage}
            // handlePageChange={handlePageChange}
          />
        </Card.Body>
        {isUpdatePage ? null : (
          <Card.Footer>
            <center>
              <Button
                className="btn-success me-3"
                onClick={isCommitPage ? toggle : handleApi}
              >
                {isCommitPage ? 'Continue' : 'Approve'}
              </Button>

              {isCommitPage ? null : (
                <Button className="btn-danger " onClick={() => {setDeleteAllWarningModalOpen(true)}}>
                  Delete Schedule
                </Button>
              )}
            </center>
          </Card.Footer>
        )}
      </Card>
      <PaymentModal
        toggle={toggle}
        submitChequeNo={submitChequeNo}
        modal={modal}
        paymentDetails={paymentDetails}
        form={form}
        handleChange={handleChange1}
      />
      <Modal show={deleteAllWarningModalIsOpen} onHide={toggleDeleteAll}>
          <Modal.Header onHide={toggleDeleteAll}>Delete</Modal.Header>
          <Modal.Body className="text-center">
            <h5>Are you sure you want to delete all the items in this schedule? </h5>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <Button outline className="m-1 p-1 px-3" onClick={toggleDeleteAll}>
                Cancel
              </Button>
              <Button
                color="danger"
                className="m-1 p-1 px-3"
                onClick={handleAllReturned}
                loading={_loading}
              >
                Delete
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal show={deleteSignleWarningModalIsOpen} onHide={toggleDeleteSingle}>
          <Modal.Header onHide={toggleDeleteSingle}>Delete</Modal.Header>
          <Modal.Body className="text-center">
            <h5>Are you sure you want to delete this item? </h5>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <Button outline className="m-1 p-1 px-3" onClick={toggleDeleteAll}>
                Cancel
              </Button>
              <Button
                color="danger"
                className="m-1 p-1 px-3"
                onClick={() => submitIndividualItem(itemToDelete)}
                loading={_loading}
              >
                Delete
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
    </>
  )
}

export default Process
