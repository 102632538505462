import React from "react";
import moment from "moment";
import { Col, Form, Row } from "@themesberg/react-bootstrap";

const today = moment().format("YYYY-MM-DD");

export default function DaterangeSelector({
  from = today,
  to = today,
  handleChange = (f) => f,
}) {
  return (
    <Row>
      <Col md={4}>
        <Form.Group>
          <Form.Label style={{ fontWeight: "bold" }}>From:</Form.Label>
          <Form.Control type="date" value={from} name="from" onChange={handleChange} />
        </Form.Group>
      </Col>

      <Col md={4} />

      <Col md={4}>
        <Form.Group>
          <Form.Label style={{ fontWeight: "bold" }}>To:</Form.Label>
          <Form.Control type="date" value={to} name="to" onChange={handleChange} />
        </Form.Group>
      </Col>
    </Row>
  );
}