import React from 'react'
import { Button } from '@themesberg/react-bootstrap'
import CustomTable from '../../../../components/CustomTable'
import { formatNumber } from '../../../../redux/utils'

export default function ({
  contractScheduleTable = [],
  handleDelete = (f) => f,
  handleEdit = (f) => f,
}) {
  return (
    <CustomTable
      data={contractScheduleTable}
      fields={[
        {
          title: 'S/N',
          custom: true,
          component: (item, index) => <div>{index + 1}</div>,
        },
        { title: 'MDA', value: 'mda_name' },
        {
          title: 'Project Description',
          value: 'project_description',
        },
        { title: 'Contractor', value: 'contractor' },
        { title: 'Payment Type', value: 'payment_type' },
        {
          title: 'Gross Amount',
          custom: true,
          component: (item) => (
            <div className="text-end">{formatNumber(item.amount)}</div>
          ),
        },
        {
          title: 'Total Taxes',
          custom: true,
          component: (item) => (
            <div className="text-end">
              {formatNumber(parseFloat(item.total_taxes).toFixed(2))}
            </div>
          ),
        },
        {
          title: 'Net Amount',
          custom: true,
          component: (item) => (
            <div className="text-end">
              {formatNumber(
                parseInt(item.amount) - parseInt(item.total_taxes).toFixed(2),
              )}
            </div>
          ),
        },
        {
          title: 'Action',
          custom: true,
          component: (item, index) => (
            <>
              <Button
                className="btn"
                size="sm"
                style={{ marginRight: '6px', display: 'inLine' }}
                color="primary"
                onClick={() => handleEdit(index)}
              >
                Edit
              </Button>

              <Button
                color="danger"
                className="btn"
                size="sm"
                style={{ marginRight: '6px' }}
                onClick={() => handleDelete(index)}
              >
                Delete
              </Button>
            </>
          ),
        },
      ]}
    />
  )
}
