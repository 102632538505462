import React from 'react'

import {  Form } from '@themesberg/react-bootstrap'

import CustomForm from '../../../components/CustomForm'

export default ({ form = {}, setForm = (f) => f }) => {
  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    if (e.target.type === 'checkbox') {
      setForm((p) => ({ ...p, [name]: value }))
    } else {
      setForm((p) => ({ ...p, [name]: value }))
    }
  }

  return (
    <>
      <h6 className="my-2 text-center">Contractor Bank Details</h6>
      <Form>
        <CustomForm
          handleChange={handleChange}
          fields={[
            {
              label: 'Account Name',
              value: form.account_name,
              name: 'account_name',
            },
            {
              label: 'Bank Name',
              name: 'bank_name',
              value: form.bank_name,
            },
            {
              label: 'Account Number',
              name: 'account_number',
              value: form.account_number,
            },
            {
              label: 'Sort code',
              value: form.sort_code,
              name: 'sort_code',
            },
          ]}
        />
      </Form>
    </>
  )
}
