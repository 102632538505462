export function checkStrEmpty(str) {
  return !(str && str.length > 1 && str.split(' ').join('').length > 0)
}

export function formatNumber(n) {
  let num = Math.round(parseInt(n), 0)
  if (num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  } else {
    return '0'
  }
}

export const getColor = (val) => {
  console.log(val);
  if (parseInt(val) >= 70 && parseInt(val) < 85) {
    return "yellow";
  } else if (parseInt(val) >= 85 && parseInt(val) < 99) {
    return "orange";
  } else if (parseInt(val) >= 99) {
    return "red";
  }
};

export const MODULE_TYPES = {
  MAIN_TREASURY: 'MAIN_TREASURY',
  SUB_TREASURY: 'SUB_TREASURY'
}

export const padAccountNo = (val) => {
  if(val ==='N/A'){
    return val
  } else {
    return val.padStart(10, '0')
  }
}