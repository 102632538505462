import React from 'react'
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
} from 'recharts'

const COLORS = ['#00C49F', '#FF8042']

// const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}

function PieChartComponent({ data = [], bars = [] }) {
  // Custom tick formatter functio
  const formatYTick = (value) => {
    if (value >= 1000000000000) {
      return (value / 1000000000000).toFixed(1) + 'T'
    } else if (value >= 1000000000) {
      return (value / 1000000000).toFixed(1) + 'B'
    } else if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + 'M'
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + 'K'
    }
    return value
  }

  return (
    <div className="bg-light py-2" style={{ width: '100%', height: 300 }}>
      {/* {JSON.stringify(data)} */}
      <ResponsiveContainer>
        <PieChart>
          {/* <Tooltip formatter={formatYTick} /> */}
          <Legend />
          <Pie
            dataKey="value"
            data={data}
            fill="#8884d8"
            // labelLine={false}
            // label={renderCustomizedLabel}
          >
            {data?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  )
}

export default PieChartComponent
