import React, { useContext } from 'react'
import {
  Button,
  ButtonGroup,
  Card,
  Container,
  Dropdown,
  Modal,
} from '@themesberg/react-bootstrap'

import { useState } from 'react'
import { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { apiURL, deleteApi, fetchApi, postApi } from '../../../redux/api'
import CustomTable from '../../../components/CustomTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCaretDown,
  faEdit,
  faEye,
  faListUl,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { Routes } from '../../../routes'
import { formatNumber } from '../../../redux/utils'
import { SearchContext } from '../../HomePage'

function ApprovalCollectionIndex({ match, type = null }) {
  const history = useHistory()
  const location = useLocation()
  const isPending = location.pathname.includes('payapp')
  const { searchTerm, setSearchTerm } = useContext(SearchContext)
  const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif']
  const [filterText, setFilterText] = useState('')
  const batch_no = match.params.batch_no
  const [budgetUtilization, setBudgetUtilization] = useState([])
  const [modal, setModal] = useState(false)
  const [filter, setFilter] = useState(type || 'All')
  const [attachmentModalIsOpen, setAttachmentModalIsOpen] = useState(false)
  const [selectedApproval, setApprovalNo] = useState('')
  const [approvalImageInfo, setApprovalImageInfo] = useState([])
  const [item, setItem] = useState({})
  const [_delete, setDelete] = useState(false)
  const [_loading, _setLoading] = useState(false)
  const toggleDelete = (item) => {
    setItem(item)
    setDelete(!_delete)
  }

  const attachmentModalToggle = () => {
    setAttachmentModalIsOpen((p) => !p)
  }

  const viewAttachment = (imageid, approval_no) => {
    setApprovalNo(approval_no)
    fetchApi(`fetch-approval-images?approval_no=${imageid}`).then((resp) => {
      attachmentModalToggle()
      if (resp.results && resp.results.length) {
        setApprovalImageInfo(resp.results)
      } else {
        setApprovalImageInfo({})
      }
    })
  }

  const getApprovalCollection = useCallback(() => {
    postApi(`post_approval_collection`, {
      form: { query_type: isPending ? 'pending' : 'by_filter', filter },
      // status : "Paid",
      // batch_no
    })
      .then((data) => {
        console.log('data', data)
        if (data.result) {
          const arr = []
          data.result.forEach((item) => {
            arr.push({ ...item, isOpen: false })
          })
          setBudgetUtilization(data.result)
        }
      })

      .catch((err) => {
        // alert(err);
        // setLoadSpinner(false);
      })
  }, [filter, isPending])

  useEffect(() => {
    getApprovalCollection()
  }, [getApprovalCollection])

  let list = []
  budgetUtilization.forEach((item) => {
    if (
      item.approval_no.indexOf(filterText) === -1 &&
      item.mda_name.toLowerCase().indexOf(filterText.toLowerCase()) === -1
    )
      return
    else list.push(item)
  })

  const handleDelete = () => {
    _setLoading(true)
    deleteApi(`delete-approve-collection`, item)
      .then((res) => {
        alert('Deleted Successfully')
        _setLoading(false)
        getApprovalCollection()
        toggleDelete({})
      })
      .catch((err) => {
        alert(`error occured ${JSON.stringify(err)}`)
        _setLoading(false)
      })
  }

  const fields = [
    {
      title: 'Action',
      custom: true,
      component: (item, index) => (
        <Dropdown>
          <Dropdown.Toggle as={Button} variant="primary" className="me-2">
            <span>Action</span>
            <FontAwesomeIcon icon={faCaretDown} className="ms-2" />
          </Dropdown.Toggle>
          <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-2">
            <Dropdown.Item
              onClick={() => viewAttachment(item.imageId, item.approval_no)}
            >
              <FontAwesomeIcon icon={faEye} className="me-2" /> View Attachment
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                history.push(
                  `${Routes.approval.newApproval}/${item.approval_no}`,
                )
              }
            >
              <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
            </Dropdown.Item>
            <Dropdown.Item onClick={() => toggleDelete(item)}>
              <FontAwesomeIcon icon={faTrash} className="me-2" /> Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
    },
    // {
    //   title: 'S/N',
    //   custom: true,
    //   component: (item, index) => <div>{index + 1}</div>,
    // },
    {
      title: 'Economic Code',
      value: 'economic_code',
    },
    {
      title: 'MDA Name',
      value: 'mda_name',
    },
    {
      title: 'Collection Date',
      value: 'collection_date',
    },
    {
      title: 'Approval Date',
      value: 'approval_date',
    },
    {
      title: 'Approval No',
      value: 'approval_no',
    },

    {
      title: 'Approval Type',
      value: 'approved_by',
    },
    {
      title: 'Status',
      value: 'approval_status',
    },
    {
      title: 'Amount',
      custom: true,
      component: (item) => (
        <div className="text-end">{formatNumber(item.amount)}</div>
      ),
      //   value: 'amount',
    },
  ]

  const finalList = [];
  list.forEach(item => {
    if(item.mda_name.toLowerCase().indexOf(searchTerm.toLowerCase()) === -1) return;
    finalList.push(item)
  })

  useEffect(() => {
    return () => {
      setSearchTerm("")
    }
  }, [setSearchTerm])

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <Button
          onClick={() => history.push(Routes.approval.newApproval)}
          className="me-2"
        >
          Add Approval
        </Button>
        <div className="d-block mb-4 mb-md-0">
          <h4>
            {/* <FontAwesomeIcon icon={faListUl} className="me-2" />  */}
            Approval Collection
          </h4>
        </div>
        {/* {JSON.stringify(searchTerm)} */}
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">
              Share
            </Button>
            <Button variant="outline-primary" size="sm">
              Export
            </Button>
          </ButtonGroup>
        </div>
      </div>

      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="p-0">
          <CustomTable
            fields={fields}
            data={finalList}
            // currentPage={currentPage}
            // handlePageChange={handlePageChange}
          />
        </Card.Body>

        <Modal show={attachmentModalIsOpen} onHide={attachmentModalToggle}>
          <Modal.Header onHide={attachmentModalToggle}>
            Approval No.: {selectedApproval}
          </Modal.Header>
          <Modal.Body className="text-center">
            <Container>
              <div className="image-container">
                {approvalImageInfo.length ? (
                  approvalImageInfo.map((item, index) =>
                    imageExtensions.some((extension) =>
                      item.image_url.toLowerCase().endsWith(extension),
                    ) ? (
                      <Card.Img
                        top
                        src={`${apiURL}/uploads/${item.image_url}`}
                        alt={'approval'}
                      />
                    ) : (
                      <Card.Body>
                        <Card.Link
                          href={`${apiURL}/uploads/${item.image_url}`}
                          target="_blank"
                        >
                          View PDF
                        </Card.Link>
                      </Card.Body>
                    ),
                  )
                ) : (
                  <h4>No attachment found.</h4>
                )}
              </div>
            </Container>
            {/* <h6>MDA: {approvalImageInfo.mda_name}</h6> */}
          </Modal.Body>
        </Modal>

        <Modal show={_delete} onHide={toggleDelete}>
          <Modal.Header onHide={toggleDelete}>Delete</Modal.Header>
          <Modal.Body className="text-center">
            <h5>Are you sure you want to delete {item.approval_no} </h5>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <Button outline className="m-1 p-1 px-3" onClick={toggleDelete}>
                Cancel
              </Button>
              <Button
                color="danger"
                className="m-1 p-1 px-3"
                onClick={handleDelete}
                loading={_loading}
              >
                Delete
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </Card>
    </>
  )
}

export default ApprovalCollectionIndex
