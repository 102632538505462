import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleDown,
  faAngleUp,
  faArrowDown,
  faArrowUp,
  faEdit,
  faEllipsisH,
  faExternalLinkAlt,
  faEye,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons'
import {
  Col,
  Row,
  Nav,
  Card,
  Image,
  Button,
  Table,
  Dropdown,
  ProgressBar,
  Pagination,
  ButtonGroup,
} from '@themesberg/react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import { Routes } from '../../../routes'
import CustomTable from '../../../components/CustomTable'
import { formatNumber } from '../../../redux/utils'

const TsaFundList = ({ list = [] }) => {
    const history = useHistory()
  const totalTransactions = list.length

  const fields = [
    {
      title: "S/N",
      custom: true,
      component: (item, idx) => <span>{idx + 1}</span>,
    },
    { title: "Account Name", value: "account_name", className: "text-right" },
    {
      title: "Account Number",
      value: "account_number",
      className: "text-right",
    },
    { title: "Bank Name", value: "bank_name", className: "text-right" },

    {
      title: "Balance",
      tableHeadStyle: { textAlign: "right", color: "black" },
      custom: true,
      component: (item) => (
        <div style={{ textAlign: "right" }}>{formatNumber(item.balance)}</div>
      ),
    },
    {
      title: "Action",
      className: "text-right",
      custom: true,
      component: (item) => (
        <div style={{ textAlign: "right" }}>
          <Button
            item={item}
            onClick={() =>
              history.push(
                `${Routes.tsafunding.fundingReport}/${item.account_number}/${item.account_name}`
              )
            }
            className="bg-success w-110 text-white"
            size="sm"
          >
            View
          </Button>
        </div>
      ),
    },
  ];

//   const TableRow = (props) => {
//     const {
//         funding_code,
//         mda_source_account,
//       price,
//       issueDate,
//       dueDate,
//       status,
//     } = props
//     const statusVariant =
//       status === 'Paid'
//         ? 'success'
//         : status === 'Due'
//         ? 'warning'
//         : status === 'Canceled'
//         ? 'danger'
//         : 'primary'

//     return (
//       <tr>
//         <td>
//           <Card.Link as={Link} to={Routes.Invoice.path} className="fw-normal">
//             {props.i + 1}
//           </Card.Link>
//         </td>
//         <td>
//           <span className="fw-normal">{funding_code}</span>
//         </td>
//         <td>
//           <span className="fw-normal">{mda_source_account}</span>
//         </td>
//         <td>
//           <span className="fw-normal">{dueDate}</span>
//         </td>
//         <td>
//           <span className="fw-normal">${parseFloat(price).toFixed(2)}</span>
//         </td>
//         <td>
//           <span className={`fw-normal text-${statusVariant}`}>{status}</span>
//         </td>
//         <td>
//           <Dropdown as={ButtonGroup}>
//             <Dropdown.Toggle
//               as={Button}
//               split
//               variant="link"
//               className="text-dark m-0 p-0"
//             >
//               <span className="icon icon-sm">
//                 <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
//               </span>
//             </Dropdown.Toggle>
//             <Dropdown.Menu>
//               <Dropdown.Item>
//                 <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
//               </Dropdown.Item>
//               <Dropdown.Item>
//                 <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
//               </Dropdown.Item>
//               <Dropdown.Item className="text-danger">
//                 <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
//               </Dropdown.Item>
//             </Dropdown.Menu>
//           </Dropdown>
//         </td>
//       </tr>
//     )
//   }


  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
      <CustomTable
          size="sm"
          className="file-list"
          fields={fields}
          data={list}
        />
        {/* <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">S/N</th>
              <th className="border-bottom">Account Name</th>
              <th className="border-bottom">Account Number</th>
              <th className="border-bottom">Bank Name</th>
              <th className="border-bottom">Balance</th>
              <th className="border-bottom">Action</th>
            </tr>
          </thead>
          <tbody>
            {list.map((t, i) => (
              <TableRow i={i} key={i} {...t} />
            ))}
          </tbody>
        </Table> */}
        {/* <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          <Nav>
            <Pagination className="mb-2 mb-lg-0">
              <Pagination.Prev>Previous</Pagination.Prev>
              <Pagination.Item active>1</Pagination.Item>
              <Pagination.Item>2</Pagination.Item>
              <Pagination.Item>3</Pagination.Item>
              <Pagination.Next>Next</Pagination.Next>
            </Pagination>
          </Nav>
          <small className="fw-bold">
            Showing <b>{totalTransactions}</b> out of <b>25</b> entries
          </small>
        </Card.Footer> */}
      </Card.Body>
    </Card>
  )
}

export default TsaFundList
