import { Button, Modal } from '@themesberg/react-bootstrap'
import React, { useState } from 'react'

function ContractorModal({
  className,

  modal,
  toggle,
  batchCode,
}) {
  const [backdrop] = useState(true)
  const [keyboard] = useState(true)

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      className={className}
      backdrop={backdrop}
      keyboard={keyboard}
    >
      <Modal.Body
        style={{ color: 'white', backgroundColor: 'rgb(25, 135, 84)' }}
        className="text-center"
      >
        <h4>Submitted Successfully</h4>

        <h4>The Batch Code is: {batchCode}</h4>
      </Modal.Body>
      <Modal.Footer
        style={{ color: 'white', backgroundColor: 'rgb(25, 135, 84)' }}
      >
        <Button
          onClick={toggle}
          style={{ backgroundColor: 'white', color: 'black' }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ContractorModal
