import React from 'react'
import BudgetUtilizationChart from './BudgetUtilizationChart'
import { Col, Row } from '@themesberg/react-bootstrap'
import DaterangeSelector from '../../../components/DaterangeSelector'

function AgDashboard() {
  return (
    <div>
      {/* <h1 className='text-center'>Dashboard</h1> */}
      <DaterangeSelector />
      <Row className="m-0 p-0">
        <Col md={6}>
          <BudgetUtilizationChart title="UTILIZATION" query_type="budget" />
        </Col>
        <Col md={6}>
          <BudgetUtilizationChart
            title="PERCENTAGE UTILIZED"
            query_type="percentage"
            type="pie"
          />
        </Col>
        <Col md={6}>
          <BudgetUtilizationChart
            type="line"
            title="TOP SPENDING MDAs"
            query_type="top_spender"
          />
        </Col>
        <Col md={6}>
          <BudgetUtilizationChart title="UTILIZATION BY MDAs" query_type="top_spender" />
        </Col>
      </Row>
    </div>
  )
}

export default AgDashboard
