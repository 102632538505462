export const Routes = {
  tsafunding: {
    faacTsaFunding: '/tsa-funding/faac',
    faacTsaFundingWithParams: '/tsa-funding/faac/:types',
    newfunding: '/tsa-funding/new',
    newfundingWithParams: '/tsa-funding/new/:types',
    fundingReport: '/tsa-funding/report',
    fundingReportWithParams: '/tsa-funding/report/:account_code/:account_title',
  },

  budget: {
    budgetUtlization: '/budget-utilization/index',
    uploadBudget: '/budget-utilization/upload-budget',
    budgetUtlizationReport: '/budget-utilization/report',
    budgetUtlizationReportDynamicRoute: '/budget-utilization/report/:mda_name',
  },

  bank: {
    bankIndex: '/bank/index',
    editBank: '/bank/edit',
    editBankDynamicRoute: '/bank/edit/:id',
  },

  approval: {
    approvalIndex: '/approval-collection/index',
    newApproval: '/approval-collection/new',
    editApproval: '/approval-collection/new/:approval_no',
  },

  pv: {
    pvlIndex: '/pv-collection/index',
    newPv: '/pv-collection/new',
    editPv: '/pv-collection/new/:approval_no',
  },

  paymentSchedule: {
    index: '/payment-schedule/index',
    viewSchedule: '/payment-schedule/view',
    viewScheduleDynamic: '/payment-schedule/view/:batch_no',
    newSchedule: '/payment-schedule/new',
    newScheduleWithApproval: '/payment-schedule/new/:mda_code/:name/:economic_code/:approval_no/:imageId/:description_code/:mda_name/:amount',
    // newScheduleWithoutApproval: '/payment-schedule/:name',

    stIndex: '/contractor-schedule/index',
    newStSchedule: '/contractor-schedule/new',
    viewStSchedule: '/contractor-schedule/view',
    viewStScheduleDynamic: '/contractor-schedule/view/:contractor_code',
    viewStScheduleDetails: '/contractor-schedule/view-tax-details',
    viewStScheduleDetailsDynamic: '/contractor-schedule/view-tax-details/:id',
  },

  contractorSchedule: {
    index: '/contractor-schedule/index',
    newSchedule: '/contractor-schedule/new'
  },

  batchProcessing: {
    index: '/batch-processing/index',
    process: '/batch-processing/process-payment',
    processWithParams: '/batch-processing/process-payment/:batch_no',
    update: '/batch-processing/update-payment',
    viewPaymentList: '/batch-processing/update-payment/view-payment-list',
    viewPaymentListWithParams: '/batch-processing/update-payment/view-payment-list/:batch_no',
    processUpdate: '/batch-processing/update-payment/update',
    processUpdateWithParams: '/batch-processing/update-payment/update/:batch_no',
  },

  payments : {
    index: '/commit-payment/index',
    process: '/commit-payment/process',
    processWithParams: '/commit-payment/process/:batch_no',

    viewPDF: '/commit-payment/process/view-pdf',
    viewPDFWithParams: '/commit-payment/process/view-pdf/:id',

    viewStPDF: '/commit-payment/process/view-st-pdf',
    viewStPDFWithParams: '/commit-payment/process/view-st-pdf/:id',
  },

  remittance : {
    index: '/remittance/index',
    process: '/remittance/process',
    processWithParams: '/remittance/process/:batch_no',

    viewPDF: '/remittance/process/view-pdf',
    viewPDFWithParams: '/remittance/process/view-pdf/:batch_no',

    paymentAdviceIndex: '/remittance/payment-advice/index',
    viewPaymentAdvice: '/remittance/process/view-payment-advice-pdf',
    viewPaymentAdviceWithParams: '/remittance/process/view-payment-advice-pdf/:batch_no',
  },

  userAdmin: {
    index: '/user-admin/index',
    editUser: '/user-admin/edit',
    editUserDynamicRoute: '/user-admin/edit/:id'
  },

  // pages
  Presentation: { path: '/' },
  DashboardOverview: { path: '/dashboard/overview' },
  Transactions: { path: '/transactions' },
  Settings: { path: '/settings' },
  Upgrade: { path: '/upgrade' },
  BootstrapTables: { path: '/tables/bootstrap-tables' },
  Billing: { path: '/examples/billing' },
  Invoice: { path: '/examples/invoice' },
  Signin: { path: '/sign-in' },
  Signup: { path: '/sign-up' },
  ForgotPassword: { path: '/examples/forgot-password' },
  ResetPassword: { path: '/examples/reset-password' },
  Lock: { path: '/examples/lock' },
  NotFound: { path: '/examples/404' },
  ServerError: { path: '/examples/500' },

  // docs
  DocsOverview: { path: '/documentation/overview' },
  DocsDownload: { path: '/documentation/download' },
  DocsQuickStart: { path: '/documentation/quick-start' },
  DocsLicense: { path: '/documentation/license' },
  DocsFolderStructure: { path: '/documentation/folder-structure' },
  DocsBuild: { path: '/documentation/build-tools' },
  DocsChangelog: { path: '/documentation/changelog' },

  // components
  Accordions: { path: '/components/accordions' },
  Alerts: { path: '/components/alerts' },
  Badges: { path: '/components/badges' },
  Widgets: { path: '/widgets' },
  Breadcrumbs: { path: '/components/breadcrumbs' },
  Buttons: { path: '/components/buttons' },
  Forms: { path: '/components/forms' },
  Modals: { path: '/components/modals' },
  Navs: { path: '/components/navs' },
  Navbars: { path: '/components/navbars' },
  Pagination: { path: '/components/pagination' },
  Popovers: { path: '/components/popovers' },
  Progress: { path: '/components/progress' },
  Tables: { path: '/components/tables' },
  Tabs: { path: '/components/tabs' },
  Tooltips: { path: '/components/tooltips' },
  Toasts: { path: '/components/toasts' },
  WidgetsComponent: { path: '/components/widgets' },
}
