import React, { useCallback, useEffect, useRef, useState } from 'react'

import {
  Card,
  Button,
  Form,
  Modal,
  Container,
  Row,
  Col,
  Image,
} from '@themesberg/react-bootstrap'

import BackButton from '../../../components/BackButton'
import CustomForm from '../../../components/CustomForm'
import { apiURL, fetchApi, postApi } from '../../../redux/api'
import { formatNumber } from '../../../redux/utils'
import Attachment from '../../../components/Attachment'
import { useDropzone } from 'react-dropzone'
import useQuery from '../../../hooks/useQuery'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment-timezone'
import { Typeahead } from 'react-bootstrap-typeahead'
import { getApprovalCollectList } from './api'
import RadioGroup from '../../../components/RadioGroup'
import ScheduleTable from './components/table'
import BatchModal from '../tsa-funding/components/BatchModal'
import { Routes } from '../../../routes'

export const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif']

export default ({ match }) => {
  const query = useQuery()
  const {
    imageId1,
    mda_code,
    economic_code,
    mda_name,
    imageId,
    description_code,
    approval_no,
    amount,
    name,
  } = useParams()

  const tsa_default = 'TSA FCMB Account (2004262028)'
  const [approvalList, setApprovalList] = useState([])
  const history = useHistory()
  const [treasuryAccount, setTreasuryAccount] = useState([])
  const [mdaAccount, setMdaAccount] = useState([])
  const [paymentScheduleTable, setPaymentScheduleTable] = useState([])
  const [budgetSummary, setBudgetSummary] = useState([])
  const [attachmentModalIsOpen, setAttachmentModalIsOpen] = useState(false)
  const [economicCode, setEconomicCode] = useState([])
  const [childCode, setChildCode] = useState([])
  const [
    batch_no,
    // setBatch_no
  ] = useState('')
  const [approvalImages, setApprovedImages] = useState([])
  const tresuryTypeahead = useRef()
  const economicTypeahead = useRef()
  const mdaCodeTypeahead = useRef()
  const mdaTypeahead = useRef()
  const [modal, setModal] = useState(false)
  const [viewImage, setViewImage] = useState(false)
  const [batchCode, setBatchCode] = useState('')
  const [mdaBudgetBalance, setMdaBudgetBalance] = useState(true)

  const id = match.params.id

  // newFetch(`${api}/update-budget-code`,{paymentScheduleTable,
  //       batch_no,
  //       query_type: 'insert',
  //       status: 'pending'
  //     },(res)=>{
  //   if(res.success){
  //     console.log(res)
  //   }
  // },
  // (err)=>{
  //     console.log(err)
  //   }
  // )
  const attachmentModalToggle = () => {
    setAttachmentModalIsOpen((p) => !p)
  }
  const [form, setForm] = useState({
    date: moment().format('YYYY-MM-DD'),
    batch_no: '',
    treasury_source_account: '',
    treasury_account_name: '',
    treasury_account_no: '',
    imageId: '',
    treasury_bank_name: '',
    mda_account_name: '',
    mda_account_no: '',
    mda_bank_name: '',
    mda_acct_sort_code: '',
    mda_code: '',
    mda_name: '',
    mda_description: '',
    mda_budget_balance: '',
    mda_economic_code: '',
    amount: '',
    description: '',
    attachment: 'No Attachment',
    payment: '',
    budget: 'No Budget',
    approval: '',
    approval_no: '',
    id: '',
    status: 'pending',
    narration: 'Being payment for ',
    formType: name,
  })

  const isNew = form.formType === 'new'
  const [approvalImageInfo, setApprovalImageInfo] = useState([])
  const toggle = () => {
    setModal(!modal)
    if (modal === true) {
      history.push(Routes.paymentSchedule.index)
    }
  }

  const handleReset = () => {
    setForm((p) => ({
      ...p,
      mda_account_name: '',
      mda_account_no: '',
      mda_bank_name: '',
      mda_acct_sort_code: '',
      mda_code: '',
      mda_name: '',
      mda_description: '',
      mda_budget_balance: '',
      mda_economic_code: '',
      amount: '',
      description: '',
      attachment: 'No Attachment',
      payment_type: 'No Approval',
      budget: 'No Budget',
      approval: '',
      narration: 'Being payment for ',
    }))

    if (isNew) {
      clearEconomicTypeahead()
      clearMdaTypeahead()
      clearMdaCodeTypeahead()
    }
  }

  const updateBudgetCode = (description, query_type) => {
    postApi(`update-budget-code`, {
      description,
      query_type,
    })
      .then((d) => {
        console.log(d)
        if (d.result) {
          setBatchCode(d.batch_code1)
          console.log('batchCode', batchCode)
        }
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    postApi(`select_mda_bank_details`, {
      query_type: 'select',
    }).then((data) => {
      console.log(data)
      setMdaAccount(data.result)
    })
  }, [])

  const viewAttachment = () => {
    fetchApi(
      `fetch-approval-images?approval_no=${imageId1 ? imageId1 : form.imageId}`,
    ).then((resp) => {
      attachmentModalToggle()
      if (resp.results && resp.results.length) {
        setApprovalImageInfo(resp.results)
      } else {
        setApprovalImageInfo({})
      }
    })
  }

  const handleSubmitReset = () => {
    setForm((p) => ({
      ...p,
      date: '',
      batch_no: '',

      treasury_account_name: '',
      treasury_account_no: '',
      treasury_bank_name: '',
      mda_account_name: '',
      mda_account_no: '',
      mda_bank_name: '',
      mda_acct_sort_code: '',
      mda_code: '',
      mda_name: '',
      mda_description: '',
      mda_budget_balance: '',
      mda_economic_code: '',
      amount: '',
      description: '',
      attachment: 'No Attachment',
      payment_type: 'No Approval',
      budget: 'No Budget',
      approval: '',
      narration: '',
      approval_no: '',
    }))

    clearEconomicTypeahead()
    clearMdaTypeahead()
    clearMdaCodeTypeahead()
    clearTreasuryTypeahead()
  }
  const getList1 = (economic_code) => {
    fetchApi(
      `get-budget-summary?query_type=select_distinct&mda_code=${form.mda_code}&economic_code=${economic_code}`,
    )
      //   fetch(`${api}/get-budget-summary1`,{
      //   method: "POST",
      //   headers: {
      //     "Content-type": "application/json",
      //   },
      //   body: JSON.stringify({
      //     query_type:'select',
      //     mda_code : form.mda_code,
      //     economic_code

      //   }),
      // })
      .then((d) => {
        console.log(d)
        if (d.result) {
          // setBudgetSummary(d.result)
          setForm((p) => ({
            ...p,
            description: d.result[0].budget_description,
            mda_budget_balance: d.result[0].budget_amount,
          }))
        }
      })
      .catch((err) => console.log(err))
  }

  // useEffect(() => {

  //   getList1()

  // }, [])

  const getList = () => {
    fetchApi(`get-budget-summary?query_type=active_mdas`)
      .then((d) => {
        console.log('d', d)
        if (d.result) {
          setBudgetSummary(d.result)
        }
      })
      .catch((err) => console.log(err))
  }
  //

  useEffect(() => {
    getList()
    getApprovalCollectList({ query_type: 'select' }, (d) => {
      if (d && d.result) {
        setApprovalList(d.result)
      }
    })
  }, [])

  useEffect(() => {
    postApi(`select_mda_bank_details`, {
      query_type: 'select',
    }).then((data) => {
      if (data.result) {
        console.log(data.result)

        const treasuryAccount = data.result.filter(
          (item) => item.account_type === 'treasury_source_account',
        )
        setTreasuryAccount(treasuryAccount)
        handleTresuryAcct(treasuryAccount)

        const mdaAccount = data.result.filter(
          (item) => item.account_type === 'mda_source_account',
        )
        //setMdaAccount(mdaAccount)
      }
    })
  }, [])

  useEffect(() => {
    if (name === 'new') {
      mdaCodeTypeahead.current.focus()
    } else {
      mdaTypeahead.current.focus()
    }
  }, [name])

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    if (e.target.type === 'checkbox') {
      setForm((p) => ({ ...p, [name]: value }))
    } else {
      setForm((p) => ({ ...p, [name]: value }))
    }
  }

  const filterData = (economic_code) => {
    const newTresury = budgetSummary.filter(
      (item) =>
        item.economic_code === economic_code && item.mda_code === form.mda_code,
    )

    const selectedItem = newTresury[0]
    // setForm(p => ({...p,

    //     description: selectedItem.budget_description,
    // mda_budget_balance: selectedItem.budget_amount,

    //      }))

    console.log('new', newTresury.mda_code)
  }

  const handleMdaAccount = (selected) => {
    if (selected.length) {
      console.log('mda_co', selected)

      const selectedItem = selected[0]
      // setOptions(selected)
      setForm((p) => ({
        ...p,
        mda_bank_name: selectedItem.bank_name,
        mda_account_name: selectedItem.account_name,
        mda_account_no: selectedItem.account_number,
        child_code: selectedItem.bank_name,
        mda_acct_sort_code: selectedItem.sort_code,
        // mda_name : selectedItem.mda_info
      }))

      // const group_id =  selected[0].group_id
      // fetchEventList(group_id)
    } else {
      console.log('no item selected')
    }
    console.log(selected)
  }

  const handleTresuryAcct = (selected) => {
    if (selected.length) {
      console.log(selected)
      const selectedItem = selected[0]
      // setOptions(selected)
      setForm((p) => ({
        ...p,
        treasury_account_name: selectedItem.account_name,
        treasury_account_no: selectedItem.account_number,
        treasury_bank_name: selectedItem.bank_name,
        treasury_source_account: selectedItem.account_info,
      }))
      // const group_id =  selected[0].group_id
      // fetchEventList(group_id)
    } else {
      console.log('no item selected')
    }
    console.log(selected)
  }

  const getList3 = (economic_code) => {
    fetchApi(
      `get-budget-summary?query_type=fetch_active_budget&mda_code=${form.mda_code}&economic_code=${economic_code}`,
    )
      .then((d) => {
        console.log(d)
        if (d.result) {
          console.log('Budget', d)
          const selectedItem = d.result && d.result.length && d.result[0]
          setForm((p) => ({
            ...p,
            description: selectedItem.budget_description,
            mda_budget_balance:
              selectedItem.budget_amount === 0
                ? 'Budget Exausted'
                : selectedItem.budget_amount,
            budget:
              selectedItem.budget_amount === 0
                ? 'Budget Exausted'
                : selectedItem.budget_amount,
          }))
        }
      })
      .catch((err) => console.log(err))
  }

  const handleEconomicCode = (selected) => {
    if (selected.length) {
      console.log('selected budget', selected)
      const selectedItem = selected[0]
      // setOptions(selected)
      setForm((p) => ({
        ...p,
        mda_economic_code: selectedItem.economic_code,
        // description: selectedItem.description
        // budget : selectedItem.economic_code
      }))

      fetchApprovedImages(form.mda_code, selectedItem.economic_code)

      getList3(selectedItem.economic_code)
    } else {
      console.log('no item selected')
    }

    console.log(selected)
  }

  const getList2 = useCallback((mda_code, cb = (f) => f) => {
    fetchApi(
      `get-budget-summary?query_type=select_economic_code&&mda_code=${mda_code}`,
    )
      .then((d) => {
        console.log(d)
        if (d.result) {
          console.log(d)
          setEconomicCode(d.result)
          cb(d.result)
          // fetchApprovedImages(mda_code, d.result.economic_code)
        }
      })
      .catch((err) => console.log(err))
  }, [])

  const fetchApprovedImages = (mda_code, economic_code) => {
    postApi(`fetch_approval_images`, {
      query_type: 'select',
      mda_code,
      economic_code,
    })
      .then((data) => {
        console.log('oop', data)
        if (data.success) {
          setApprovedImages(data.result)
          if (data.result[0].approval === 'Approved') {
            setForm((p) => ({
              ...p,
              approval: data.result[0].approval || '',
              attachment: 'Seen',
            }))
          } else {
            setForm((p) => ({ ...p, approval: 'No Approval' }))
          }
        }
      })

      .catch((err) => {
        console.log(err)
        // setLoadSpinner(false);
      })
  }

  const handleMdaCode = (selected) => {
    if (selected.length) {
      console.log(selected)
      const selectedItem = selected[0]
      // setOptions(selected)
      // console.log(selected)
      setForm((p) => ({
        ...p,
        // mda_code: selectedItem.mda_child_code,
        // mda_name: selectedItem.mda_name,
        mda_code: selectedItem.mda_code,
        mda_name: selectedItem.mda_name,
        // mda_economic_code : selectedItem.economic_code
      }))

      getList2(selectedItem.mda_code)
    } else {
      console.log('no item selected')
    }
  }

  const fetchBuget = (mda_code1, economic_code1) => {
    const newArray = budgetSummary.filter(
      (item, idx) =>
        item.mda_info === mda_code1 && item.economic_code === economic_code1,
    )
    const selectedItem = newArray[0]
    setForm((p) => ({
      ...p,
      description: selectedItem.budget_description,
      mda_budget_balance: selectedItem.budget_amount,
    }))
  }

  const handleDelete = (index) => {
    const newArray = paymentScheduleTable.filter((itm, idx) => index !== idx)
    setPaymentScheduleTable(newArray)
  }

  const handleEdit = (index) => {
    const newArray = paymentScheduleTable.filter((itm, idx) => index === idx)
    // form(newArray[0])
    console.log(newArray[0])
  }

  const handleArray = () => {
    if (!isNew) {
      form.approval = 'Approved'
    }
    setPaymentScheduleTable([...paymentScheduleTable, form])
    handleReset()
  }

  const addBatchNo = () => {}

  const handleApi = () => {
    postApi(`post_payment_schedule_array`, {
      paymentScheduleTable,
      batch_no,
      query_type: 'insert',
      status: 'pending',
    })
      .then((data) => {
        updateBudgetCode('batch_code', 'update')
        if (data.success) {
          console.log(data)
          setBatchCode(data.batch_code1)

          // handleSubmitReset()
          toggle()
          setPaymentScheduleTable([])
        }
      })

      .catch((err) => {
        alert(err)
        // setLoadSpinner(false);
      })
  }

  const handleApi1 = () => {
    // console.log(form)

    if (form.amount && form.amount !== '') {
      const formData = new FormData()
      for (let i = 0; i < acceptedFiles.length; i++) {
        formData.append('files', acceptedFiles[i])
      }

      if (!acceptedFiles.length) {
        formData.append('form', JSON.stringify(form))

        fetch(`${apiURL}/post_images`, {
          method: 'POST',

          body: formData,
        })
          .then((response) => response.json())
          .then((result) => {
            handleUpdate()
            // setLoadSpinner(false)
            console.log(result)
            // removeAll()
            // toggle()
            // let acceptedFiles = []

            // localStorage.setItem("key", JSON.stringify(result.token));
            // setName(result.user)
            // history.push("/homepage/payment_table");

            // console.log(result);
          })

          .catch((err) => {
            console.log(err)
            // alert(err);
            // setLoadSpinner(false)
          })
      } else {
        formData.append('form', JSON.stringify(form))
        // // formData.append('event_name', options[0].event_name)

        fetch(`${apiURL}/post_images`, {
          method: 'POST',

          body: formData,
        })
          .then((response) => response.json())
          .then((result) => {
            handleUpdate()
            // setLoadSpinner(false)
            console.log(result)
            // removeAll()
            // toggle()
            // let acceptedFiles = []

            // localStorage.setItem("key", JSON.stringify(result.token));
            // setName(result.user)
            // history.push("/homepage/payment_table");

            // console.log(result);
          })

          .catch((err) => {
            console.log(err)
            // alert(err);
            // setLoadSpinner(false)
          })
      }
      // Object.keys(form).forEach(item => {
      //   formData.append(item, form[item])
      // })

      // formData.append('user', name.email)
      //  formData.append('group_id', groupName)
    } else {
      alert('Please enter a valid amount')
    }
  }

  const handleUpdate = () => {
    postApi(`post_payment_schedule`, {
      mda_account_name: form.mda_account_name,
      mda_account_no: form.mda_account_no,
      mda_bank_name: form.mda_bank_name,
      mda_acct_sort_code: form.mda_acct_sort_code,
      mda_code: form.mda_code,
      mda_name: form.mda_name,
      mda_description: form.mda_description,
      mda_budget_balance: form.mda_budget_balance,
      mda_economic_code: form.mda_economic_code,
      amount: form.amount,
      description: form.description,
      attachment: form.attachment,
      payment_type: form.payment_type,
      budget: form.budget,
      approval: form.approval,
      status: form.status,
      narration: form.narration,
      budget: form.mda_budget_balance,
      approval: form.approval,
      query_type: 'UPDATE_BY_ID',
      id,
    })
      .then((data) => {
        if (data.success) {
          console.log(data)
          handleSubmitReset()
          alert('Update Successfull')
          history.push('/dashboard/pays')
        }
      })

      .catch((err) => {
        alert(err)
        // setLoadSpinner(false);
      })
  }

  const fetchMdaBankDetails = useCallback(() => {
    const query_type = 'select'
    postApi(`select_mda_bank_details`, {
      query_type,
    })
      .then((data) => {
        console.log(data)
        setChildCode(data.result)
      })

      .catch((err) => {
        console.log(err)
      })

    console.log(childCode)
  }, [childCode])

  useEffect(() => {
    const query_type = 'select'
    postApi(`select_mda_bank_details`, {
      query_type,
    })
      .then((data) => {
        console.log(data)
        setChildCode(data.result)
      })

      .catch((err) => {
        console.log(err)
      })
  }, [])

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: 'image/*, .pdf, text/plain' })

  const clearTreasuryTypeahead = () => {
    tresuryTypeahead.current.clear()
  }

  const clearEconomicTypeahead = () => {
    economicTypeahead.current.clear()
  }

  const clearMdaTypeahead = () => {
    mdaTypeahead.current.clear()
  }

  const clearMdaCodeTypeahead = () => {
    mdaCodeTypeahead.current.clear()
  }

  useEffect(() => {
    postApi(`post_payment_schedule`, {
      query_type: 'select_by_id',
      id,
    }).then((data) => {
      console.log('datay', data)
      if (data?.result?.length) {
        setForm(data.result[0])
        setMdaBudgetBalance(data.result[0].mda_budget_balance)
        mdaTypeahead.current.setState({
          text: data.result[0].mda_account_name,
        })

        economicTypeahead.current.setState({
          text: data.result[0].mda_economic_code,
        })
        tresuryTypeahead.current.setState({
          text: data.result[0].treasury_account_name,
        })
        mdaCodeTypeahead.current.setState({ text: data.result[0].mda_name })
      }
    })
  }, [id])

  const getApproval = useCallback(() => {
    getList2(mda_code, (l) => {
      let selectedEconomic =
        l.find((a) => a.economic_code === economic_code) || {}

      // console.log(selectedEconomic)
      // console.log(item)

      setForm((p) => ({
        ...p,
        mda_name: mda_name,
        mda_code: mda_code,
        mda_economic_code: economic_code,
        description: description_code,
        amount: amount,
        approval_no: approval_no,
        approval_amount: amount,
        mda_budget_balance: selectedEconomic.budget_amount,
        imageId: imageId,
      }))
    })
  }, [
    amount,
    approval_no,
    description_code,
    economic_code,
    getList2,
    imageId,
    mda_code,
    mda_name,
  ])

  useEffect(() => {
    getApproval()
  }, [getApproval])

  return (
    <>
      <BatchModal toggle={toggle} modal={modal} batchCode={batchCode} />
      <ViewImage
        attachmentModalIsOpen={attachmentModalIsOpen}
        attachmentModalToggle={attachmentModalToggle}
        approvalImageInfo={approvalImageInfo}
        imageExtensions={imageExtensions}
      />
      <Row>
        <Col md={3}>
          <BackButton />
        </Col>
        <Col md={6}>
          <h5 className="mb-2 text-center">
            {/* <FontAwesomeIcon icon={faCheckDouble} className="me-2" />  */}
            Payment Schedule
          </h5>
        </Col>
      </Row>

      <Card>
        <Card.Body>
          <div>
            <RadioGroup
              radioBoxContainer="mb-0"
              label="Select Schedule Type"
              name="formType"
              value={form.formType || approval_no}
              options={[
                { name: 'new', label: 'Create New' },
                { name: 'existing', label: 'Select Existing Approval' },
              ]}
              onChange={(name, value) => {
                console.log(name, value)
                setForm((p) => ({ ...p, [name]: value }))
              }}
            />
          </div>

          {/* {JSON.stringify(budgetSummary)} */}

          <CustomForm
            handleChange={handleChange}
            fields={[
              {
                label: 'Date',
                value: form.date,
                name: 'collection_date',
                type: 'date',
                disabled: id ? true : false,
              },
              {
                type: 'custom',
                component: (item) => (
                  <>
                    <Form.Label>
                      Treasury Single Account (Central Account)
                    </Form.Label>

                    <Typeahead
                      size="sm"
                      disabled={id ? true : false}
                      id="20"
                      onChange={(selected) => handleTresuryAcct(selected)}
                      onInputChange={(text) => console.log(text)}
                      options={treasuryAccount}
                      labelKey={(option) => `${option.account_info}`}
                      placeholder="Select---"
                      ref={tresuryTypeahead}
                      //   defaultInputValue={tsa_default}
                    />
                  </>
                ),
                //   value: form.account_number,
                //   name: 'account_number',
              },
              {
                type: isNew ? 'hidden' : 'custom',
                component: (item) => (
                  <>
                    <Form.Label>Select Approval No.</Form.Label>
                    <Typeahead
                      size="sm"
                      disabled={id ? true : false}
                      id="20x"
                      onChange={(selected) => {
                        if (selected && selected.length) {
                          console.log(selected[0])
                          let item = selected[0]
                          getList2(item.mda_code, (l) => {
                            let selectedEconomic =
                              l.find(
                                (a) => a.economic_code === item.economic_code,
                              ) || {}

                            // console.log(selectedEconomic)
                            // console.log(item)

                            setForm((p) => ({
                              ...p,
                              // ...
                              mda_name: item.mda_name,
                              mda_code: item.mda_code,
                              mda_economic_code: item.economic_code,
                              description: item.description_code,
                              amount: item.amount,
                              approval_no: item.approval_no,
                              approval_amount: item.amount,
                              mda_budget_balance:
                                selectedEconomic.budget_amount,
                              imageId: item.imageId,
                            }))
                          })

                          // let selectedEconomic = economicCode.find(a => a.mda_economic_code === item.economic_code) || {}
                          // console.log(economicCode)
                        }
                      }}
                      onInputChange={(text) => console.log(text)}
                      options={approvalList}
                      labelKey={(option) => `${option.approval_no}`}
                      placeholder="Select---"
                      // defaultInputValue={approval_no}
                      ref={tresuryTypeahead}
                    />
                  </>
                ),
                //   value: form.account_number,
                //   name: 'account_number',
              },
              {
                label: 'Treasury Account Name',
                value: form.treasury_account_name,
                name: 'treasury_account_name',
                disabled: true,
              },

              {
                label: 'Treasury Account No',
                value: form.treasury_account_no,
                name: 'treasury_account_no',
                disabled: true,
              },
              {
                label: 'Treasury Bank Name',
                value: form.treasury_bank_name,
                name: 'treasury_bank_name',
                disabled: true,
              },
              {
                label: 'Select Economic Code',
                type: isNew ? 'custom' : 'text',
                component: (item) => (
                  <>
                    <Form.Label>Select MDA</Form.Label>

                    <Typeahead
                      size="sm"
                      // disabled={id ? true : false}
                      id="20"
                      onChange={(selected) => handleMdaCode(selected)}
                      onInputChange={(text) => console.log(text)}
                      options={budgetSummary}
                      labelKey={(option) => `${option.mda_name}`}
                      placeholder="Select---"
                      ref={mdaCodeTypeahead}
                    />
                  </>
                ),
                value: form.mda_name,
                name: 'mda_name',
                disabled: !isNew,
              },
              {
                label: 'MDA Administrative Code',
                value: form.mda_code,
                disabled: true,
              },
              {
                label: 'Select Economic Code',
                value: form.mda_economic_code,
                disabled: !isNew,
                type: isNew ? 'custom' : 'text',
                component: () => (
                  <>
                    <Form.Label>Select Economic Code</Form.Label>
                    <Typeahead
                      size="sm"
                      // disabled={id ? true : false}
                      id="20"
                      onChange={(selected) => handleEconomicCode(selected)}
                      onInputChange={(text) => console.log(text)}
                      options={economicCode}
                      labelKey={(option) =>
                        `${option.description} - ${option.economic_code}`
                      }
                      placeholder="Select---"
                      ref={economicTypeahead}
                    />
                  </>
                ),
              },
              {
                label: 'Description   ',
                value: form.description,
                name: 'description',
                disabled: true,
              },
              {
                label: 'Budget Balance',
                value: formatNumber(form.mda_budget_balance),
                name: 'mda_budget_balance',
                disabled: mdaBudgetBalance === 'Budget Exausted' ? false : true,
              },
              {
                label: 'MDA Account',
                type: 'custom',
                component: (item) => (
                  <>
                    <Form.Label>MDA Account</Form.Label>
                    <Typeahead
                      size="sm"
                      // disabled={id ? true : false}
                      id="20"
                      // disabled={id ? true : false}
                      onChange={(selected) => handleMdaAccount(selected)}
                      onInputChange={(text) => console.log(text)}
                      options={mdaAccount}
                      labelKey={(option) => `${option.account_name}`}
                      placeholder="Select---"
                      ref={mdaTypeahead}
                    />
                  </>
                ),
              },
              {
                label: 'MDA Bank Name',
                value: form.mda_bank_name,
                name: 'mda_bank_name',
                disabled: true,
              },
              {
                label: 'MDA Account No',
                value: form.mda_account_no,
                name: 'mda_account_no',
                disabled: true,
              },
              {
                label: 'MDA Account Name',
                value: form.mda_account_name,
                name: 'mda_account_name',
                disabled: true,
              },
              {
                label: 'Sort Code',
                value: form.mda_acct_sort_code,
                name: 'mda_acct_sort_code',
                disabled: true,
              },
              {
                label: `Amount ${formatNumber(form.amount)}`,
                value: form.amount,
                name: 'amount',
              },
              {
                label: 'Payment',
                type: 'select',
                value: form.payment_type,
                name: 'payment_type',
                options: ['full_payment', 'part_payment', 'balance'],
                col: 4,
              },
              {
                type: isNew ? 'select' : 'hidden',
                label: 'Approval',
                options: ['Approved', 'No Approval'],
                value: form.approval,
                name: 'approval',
                col: 4,
              },
              {
                type: isNew ? 'custom' : 'hidden',
                component: () => (
                  <Button
                    size="sm"
                    className="btn-success mt-4"
                    onClick={() => viewAttachment()}
                  >
                    View
                  </Button>
                ),
              },
              {
                label: 'Narration',
                value: form.narration,
                type: 'textarea',
                name: 'narration',
              },
              {
                label: `Amount Paid (${formatNumber(form.amount)})`,
                value: form.amount,
                //   type: 'select',
                name: 'amount',
              },
            ]}
          />

          {id ? (
            <>
              <Attachment
                acceptedFiles={acceptedFiles}
                getRootProps={getRootProps}
                getInputProps={getInputProps}
                isDragActive={isDragActive}
                isDragAccept={isDragAccept}
                isDragReject={isDragReject}
              />
            </>
          ) : null}

          {viewImage && (
            <Row>
              {approvalImages.map((item) => (
                <Col md="4" className="mb-2">
                  <Image
                    src={`http://localhost:3589/uploads/${item.image_url}`}
                    //   height="150px"
                    alt="logo"
                  />
                </Col>
              ))}
            </Row>
          )}

          {!id ? (
            <>
              <br />
              <center>
                <Button
                  size="sm"
                  className="bg-success text-white"
                  onClick={handleArray}
                >
                  Add to Batch
                </Button>
              </center>

              <ScheduleTable
                className="table"
                paymentTable={paymentScheduleTable}
                handleDelete={handleDelete}
                handleEdit={handleEdit}
              />
            </>
          ) : null}

          <center>
            {!id ? (
              <Button onClick={handleApi} className="btn-success">
                Submit
              </Button>
            ) : (
              <Button onClick={handleApi1} className="btn-success">
                Update
              </Button>
            )}
          </center>
        </Card.Body>
      </Card>
    </>
  )
}

export const ViewImage = ({
  attachmentModalIsOpen = false,
  attachmentModalToggle = (f) => f,
  approvalImageInfo = [],
  imageExtensions = [],
}) => {
  return (
    <Modal show={attachmentModalIsOpen} onHide={attachmentModalToggle}>
      <Modal.Header show={attachmentModalIsOpen} onHide={attachmentModalToggle}>
        Approval No.: {approvalImageInfo.approval_no}
      </Modal.Header>
      <Modal.Body className="text-center">
        <Container>
          <div className="image-container">
            {approvalImageInfo.length ? (
              approvalImageInfo.map((item, index) =>
                imageExtensions.some((extension) =>
                  item.image_url.toLowerCase().endsWith(extension),
                ) ? (
                  <Card.Img
                    top
                    src={`${apiURL}/uploads/${item.image_url}`}
                    alt={'approval'}
                  />
                ) : (
                  <Card.Body>
                    <Card.Link
                      href={`${apiURL}/uploads/${item.image_url}`}
                      target="_blank"
                    >
                      View PDF
                    </Card.Link>
                  </Card.Body>
                ),
              )
            ) : (
              <h4>No attachment found.</h4>
            )}
          </div>
        </Container>
        {/* <h6>MDA: {approvalImageInfo.mda_name}</h6> */}
      </Modal.Body>
    </Modal>
  )
}
