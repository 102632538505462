import React, { useState, useEffect, useContext } from 'react'
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer'

import { useHistory } from 'react-router-dom'
import { PDFViewer, Font } from '@react-pdf/renderer'
import moment from 'moment'
import { apiURL } from '../../../redux/api'
import { formatNumber } from '../../../redux/utils'
import { toNairaWords, toWordsconver } from './PaymentPDF'


// import font from './styles/localFonts/Poppins-Medium.ttf'
// Create styles
const COL1_WIDTH = 40
const COL_AMT_WIDTH = 20
const COLN_WIDTH = (100 - (COL1_WIDTH + COL_AMT_WIDTH)) / 2

Font.register({
  family: 'Roboto',
  src:
    'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
})

const styles = StyleSheet.create({
  page: {
    paddingTop: 70,
    paddingBottom: 70,
    paddingRight: 20,
    paddingLeft: 20,
    fontFamily: 'Times-Roman',
    // fontFamily: "Roboto"
  },

  headerPage: {},

  topRightHeader: {
    // display : 'flex',
    // justifyContent : 'between',
    //  flexDirection: 'row',
    textAlign: 'right',
    // backgroundColor: '#E4E4E4',
    // width : "900px"
  },

  topLeftHeader: {
    // flexDirection: 'row',
    textAlign: 'left',
    marginTop: '100px',
    marginBottom: '25px',
    fontSize: '14px',
    color: 'black',
    fontWeight: 'bold',
    lineHeight: 1.5,
    // backgroundColor: '#E4E4E4',
    // width : "900px"
  },
  middleHeader: {
    // flexDirection: 'row',
    padding: 10,
    fontSize: '14px',
    textAlign: 'center',
    textDecoration: 'underline',
    lineHeight: 1.2,
    fontWeight: 'bold',
    // backgroundColor: '#E4E4E4',
    // width : "900px"
  },

  body1: {
    textAlign: 'justify',
    marginTop: '10px',
    fontSize: 14,
    lineHeight: 1.5,
  },

  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },

  // table :{fontSize : '12px',
  // border : '1px solid black',
  // padding : 10},

  table: {
    display: 'table',
    width: '18%',
    fontSize: 10,
    // fontFamily: 'Roboto',
    // marginVertical: 6,
    borderBottom: '1px solid black',
    borderRight: '1px solid black',
    paddingHorizontal: 5,
    paddingVertical: 2,
    textAlign: 'center',
    fontWeight: 'bold',
  },

  tablel: {
    display: 'table',
    width: '18%',
    fontSize: 10,
    // fontFamily: 'Roboto',
    // marginVertical: 6,
    borderBottom: '1px solid black',
    borderRight: '1px solid black',
    paddingHorizontal: 5,
    paddingVertical: 2,
    textAlign: 'left',
  },

  tableTN: {
    display: 'table',
    width: '35.9%',
    fontSize: 12,
    // marginVertical: 6,
    borderBottom: '1px solid black',

    paddingHorizontal: 5,
    paddingVertical: 2,
    // textAlign : 'center'
  },

  tableSN: {
    display: 'table',
    width: '7%',
    fontSize: 10,
    // marginVertical: 6,
    // border : '1px solid black',
    borderBottom: '1px solid black',
    borderRight: '1px solid black',
    // borderBottom : '1px solid black',
    borderLeft: '1px solid black',

    paddingHorizontal: 5,
    paddingVertical: 2,
    textAlign: 'center',
  },
  tableLg: {
    display: 'table',
    width: '21%',
    fontSize: 10,
    // marginVertical: 6,
    // border : '1px solid black',
    borderBottom: '1px solid black',
    borderRight: '1px solid black',
    // borderBottom : '1px solid black',
    // borderLeft: '1px solid black',

    paddingHorizontal: 5,
    paddingVertical: 2,
    textAlign: 'center',
  },
  table2: {
    display: 'table',
    width: '18%',
    fontSize: 10,
    borderBottom: '1px solid black',
    borderRight: '1px solid black',

    // marginVertical: 6,
    // border : '1px solid black',
    paddingHorizontal: 5,
    paddingVertical: 2,
    textAlign: 'right',
    // textAlign : 'center'
  },

  table3: {
    display: 'table',
    width: '18%',
    fontSize: 12,
    borderBottom: '1px solid black',
    borderRight: '1px solid black',
    borderLeft: '1px solid black',

    // marginVertical: 6,
    // border : '1px solid black',
    padding: 5,
    textAlign: 'right',
    // textAlign : 'center'
  },

  table1: {
    display: 'table',
    width: '100%',
    fontSize: 12,
    // marginVertical: 6,
    border: '1px solid black',
    padding: 5,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  leftAlign: {
    textAlign: 'left',
  },

  tableRow: {
    flexDirection: 'row',
  },
  tableRowTotal: {
    flexDirection: 'row',
  },
  tableCol1Header: {
    width: COL1_WIDTH + '%',
  },
  tableColHeader: {
    width: COLN_WIDTH + '%',
  },
  tableColAmtHeader: {
    width: COL_AMT_WIDTH + '%',
  },
  tableCol1: {
    width: COL1_WIDTH + '%',
  },
  tableColAmt: {
    width: COL_AMT_WIDTH + '%',
  },
  tableCol: {
    width: COLN_WIDTH + '%',
  },
  tableColTotal: {
    width: 2 * COLN_WIDTH + '%',
  },
  tableCellHeader: {
    // marginRight: 5,
    fontWeight: 'bold',
  },
  tableCell: {
    marginVertical: 1,
    // marginRight: 4,
  },
})

// System for American Numbering
var th_val = ['', 'thousand', 'million', 'billion', 'trillion']
// System for uncomment this line for Number of English
// var th_val = ['','thousand','million', 'milliard','billion'];

var dg_val = [
  'zero',
  'one',
  'two',
  'three',
  'four',
  'five',
  'six',
  'seven',
  'eight',
  'nine',
]
var tn_val = [
  'ten',
  'eleven',
  'twelve',
  'thirteen',
  'fourteen',
  'fifteen',
  'sixteen',
  'seventeen',
  'eighteen',
  'nineteen',
]
var tw_val = [
  'twenty',
  'thirty',
  'forty',
  'fifty',
  'sixty',
  'seventy',
  'eighty',
  'ninety',
]

const toCamelCase = (str = '') => {
  return str && str[0].toUpperCase() + str.substr(1)
}

// Create Document Component
export default function STLetterPDF({ match, type = 'sub_treasury', }) {
  const history = useHistory()

  console.log('matched', match)
  // const batch_no = 90
  const batch_no = match.params.id
  const [paymentScheduleTable, setPaymentScheduleTable] = useState([])
  const [tresuryDetails, setTresuryDetails] = useState({})
  const [paymentDetails, setPaymentDetails] = useState([])
  const [taxSchedule, setTaxSchedule] = useState([])

  const [form, setForm] = useState({
    batch_number: '',
    date: '',
    no_of_mda: '',
    no_of_payments: '',
    total_amount: '',
    cheque_number: '',
    query_type: 'insert',
    status: 'uncommited',
  })

  console.log('batch_no', batch_no)

  useEffect(() => {
    fetch(`${apiURL}/get_batch_list`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        query_type: 'select_by_batch_no',
        status: 'Committed',
        batch_no,
        type,
      }),
    })
      .then(function (response) {
        return response.json()
      })
      .then((data) => {
        console.log('data', data)
        if (data.result && data.result.length) {
          setPaymentScheduleTable(data.result)
          setTresuryDetails(data.result[0])
        }
      })

      .catch((err) => {
        // alert(err);
        // setLoadSpinner(false);
      })
  }, [batch_no,type])

  useEffect(() => {
    fetch(`${apiURL}/get_batch_list`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        query_type: 'select_payment_list',
        status: 'Committed',
        batch_no,
        type,
      }),
    })
      .then(function (response) {
        return response.json()
      })
      .then((data) => {
        console.log('gg', data)
        if (data.result) {
          setPaymentDetails(data.result)
          const formData = data.result[0]
          setForm((p) => ({ ...p, ...formData }))
          // setPaymentScheduleTable(data.result);
        }
      })

      .catch((err) => {
        // alert(err);
        // setLoadSpinner(false);
        
      })
  }, [batch_no,type])

  // useEffect(() => {
  //   fetch(
  //     `${api}/get_taxes?contractor_code=${batch_no}&query_type=tax_schedule`,
  //   )
  //     .then(function (response) {
  //       return response.json()
  //     })
  //     .then((data) => {
  //       if (data.results) {
  //         setTaxSchedule(data.results)
  //       }
  //     })

  //     .catch((err) => {
  //       // alert(err);
  //       // setLoadSpinner(false);
  //     })
  // }, [batch_no, name.role])
  // console.log('paymentScheduleTable', paymentScheduleTable)

  useEffect(() => {
    fetch(
      `${apiURL}/tax-schedule?batch_id=${batch_no}&query_type=tax_schedule`,
    )
      .then(function (response) {
        return response.json()
      })
      .then((data) => {
        // console.log(data.schedule, 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx')
        if (data.schedule) {
          setTaxSchedule(data.schedule)
        }
      })

      .catch((err) => {
        console.log(err)
        // alert(err);
        // setLoadSpinner(false);
      })
  }, [batch_no])

  const newDate = moment(form.date).format('DD/MM/YYYY')

  const totalGross = paymentScheduleTable.reduce(
    (a, b) => a + parseFloat(b.amount),
    0,
  )
  const totalTaxes = paymentScheduleTable.reduce(
    (a, b) => a + parseFloat(b.total_taxes),
    0,
  )
  const totalNet = paymentScheduleTable.reduce(
    (a, b) => a + (parseFloat(b.amount) - parseFloat(b.total_taxes)),
    0,
  )

  // const totalWHT = taxSchedule.reduce((a, b) => a + parseFloat(b.WHT), 0)
  // const totalVAT = taxSchedule.reduce((a, b) => a + parseFloat(b.VAT), 0)
  // const totalSD = taxSchedule.reduce((a, b) => a + parseFloat(b.SD), 0)

  return (
    <>
      <PDFViewer height="1000" width="1000">
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={{ textAlign: 'right' }}></View>

            <View
            // style={styles.topRightHeader}
            >
              <View></View>

              <View
                style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  lineHeight: '1.5',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <View>
                  <Text>{''}</Text>
                  <Text></Text>
                </View>

                <View>
                  <Text
                    style={{
                      fontWeight: 'bold',
                      marginTop: 10,
                      marginLeft: 30,
                    }}
                  >
                    C.1/AG/VOL.30/13127
                  </Text>
                </View>

                <View>
                  <Text style={{ fontWeight: 'bold' }}>
                    {tresuryDetails.arabic_date}
                  </Text>
                  <Text>{newDate}</Text>
                </View>
              </View>
            </View>

            <View style={styles.topLeftHeader}>
              <Text>The Manager</Text>
              <Text>{tresuryDetails.treasury_bank_name}</Text>
            </View>

            <View style={styles.middleHeader}>
              <Text>RELEASE OF FUNDS TO CONTRACTORS</Text>
            </View>

            <View style={styles.body1}>
              <Text>
                {' '}
                Please, find attached our cheque No:{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {tresuryDetails.cheque_number}
                </span>{' '}
                dated {newDate} in the Sum of{' '}
                {toCamelCase(
                  toNairaWords(form.total_amount ? form.total_amount : 0),
                )}{' '}
                Naira Only (N
                {parseFloat(form.total_amount).toLocaleString('en')}) drawn in
                your favour for the credit of various Contractors Net payment
                and their taxes respectively to Relevant Tax Authority (RTA).
              </Text>
            </View>

            <View style={styles.body1}>
              <Text>
                2. Please provide office of Accountant General with the debit
                advice immediately
              </Text>
            </View>
            <View style={styles.body1}>
              <Text>3. Please, acknowledge reciepts</Text>

              <Text>Yours faithfully</Text>
            </View>

            <View style={(styles.body1, { marginTop: 50 })}>
              <Text>(KABIRU AHMAD GARKO)</Text>

              {/* <Text>{JSON.stringify(taxSchedule)}</Text> */}
              <Text>SUB-TREASURER</Text>

            </View>
          </Page>

          {/* NET SCHEDULE */}
          <Page size="A4" style={styles.page}>
            <View>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={styles.table1}>
                  NET SCHEDULE
                  {/* {''} {newDate} */}
                </Text>
              </View>

              <Text>{/* {JSON.stringify(paymentScheduleTable)} */}</Text>

              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={styles.tableSN}>S/N</Text>
                <Text style={styles.tableLg}>CONTRACTOR NAME</Text>
                <Text style={styles.table}>BANK</Text>
                <Text style={styles.table}>ACCOUNT NO</Text>
                <Text style={styles.table}>SORT CODE</Text>
                <Text style={styles.table}>
                  GROSS <span>(N)</span>
                </Text>
                <Text style={styles.table}>
                  TAXES <span>(N)</span>
                </Text>
                <Text style={styles.table}>
                  NET <span>(N)</span>
                </Text>
              </View>
              {paymentScheduleTable.map((item, index) => (
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <Text style={styles.tableSN}>{index + 1}</Text>

                  <Text style={[styles.tableLg, styles.leftAlign]}>
                    {item.contractor}
                  </Text>
                  <Text style={[styles.tablel, styles.leftAlign]}>
                    {item.contractor_bank}
                  </Text>
                  <Text style={[styles.table, styles.leftAlign]}>
                    {item.contractor_acc_no}
                  </Text>
                  <Text style={[styles.table, styles.leftAlign]}>
                    {item.contractor_acc_sort_code}
                  </Text>
                  <Text style={styles.table2}>{formatNumber(item.amount)}</Text>
                  <Text style={styles.table2}>
                    {formatNumber(item.total_taxes)}
                  </Text>
                  <Text style={styles.table2}>
                    {formatNumber(
                      parseFloat(item.amount) - parseFloat(item.total_taxes),
                    )}
                  </Text>
                </View>
              ))}
            </View>

            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={styles.tableSN}></Text>
              <Text style={styles.tableLg}></Text>
              <Text style={styles.table}></Text>
              <Text style={styles.table}></Text>
              <Text style={styles.table}>Total</Text>
              <Text style={[styles.table, { textAlign: 'right' }]}>
                {formatNumber(totalGross)}
              </Text>
              <Text style={[styles.table, { textAlign: 'right' }]}>
                {formatNumber(totalTaxes)}
              </Text>
              <Text style={[styles.table, { textAlign: 'right' }]}>
                {formatNumber(totalNet)}
              </Text>
            </View>
          </Page>

          {/* TAXES SCHEDULE */}
          {Object.keys(taxSchedule).map(taxItem => {
            let total = taxSchedule[taxItem].reduce((a,b)=> a + parseFloat(b.amount), 0)
            return (
            <Page size="A4" style={styles.page}>
            <View>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={styles.table1}>
                  TAX SCHEDULE ({taxItem})
                </Text>
              </View>

              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={styles.tableSN}>S/N</Text>
                <Text style={styles.tableLg}>BENEFICIARY NAME</Text>
                <Text style={styles.table}>BENEFICIARY TIN</Text>
                <Text style={styles.table}>AGENCY NAME</Text>
                <Text style={styles.table}>AGENCY TIN</Text>
                <Text style={styles.table}>
                  {taxItem} <span>(N)</span>
                </Text>
              </View>
              {taxSchedule[taxItem].map((item, index) => (
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <Text style={styles.tableSN}>{index + 1}</Text>

                  <Text style={[styles.tableLg, styles.leftAlign]}>
                    {item.contractor_name}
                  </Text>
                  <Text style={[styles.tablel, styles.leftAlign]}>
                    {item.contractor_tin_no}
                  </Text>
                  <Text style={[styles.table, styles.leftAlign]}>
                    {item.mda_name}
                  </Text>
                  <Text style={[styles.table, styles.leftAlign]}>
                    {item.mda_tin}
                  </Text>
                  <Text style={styles.table2}>{formatNumber(item.amount)}</Text>
                </View>
              ))}
            </View>

            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={styles.tableSN}></Text>
              <Text style={styles.tableLg}></Text>
              <Text style={styles.tablel}></Text>
              <Text style={styles.table}></Text>
              <Text style={styles.table}>Total</Text>
          
              <Text style={styles.table2}>{formatNumber(total)}</Text>
            </View>
          </Page>
          )})}
        </Document>
      </PDFViewer>
    </>
  )
}
