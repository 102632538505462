import { Button, Spinner } from '@themesberg/react-bootstrap'
import React from 'react'

function CustomButton(props) {
  const { loading = false, variant = 'primary', type = 'input' } = props
  return (
    <Button variant={variant} type={type} {...props} disabled={loading}>
      {loading ? 'Please wait...' : props.children}
    </Button>
  )
}

export default CustomButton
