import React, { useEffect, useState } from 'react'

import { Card, Form } from '@themesberg/react-bootstrap'

// import BackButton from '../../../components/BackButton'
import CustomForm from '../../../components/CustomForm'
import { postApi } from '../../../redux/api'
import { useHistory } from 'react-router-dom'
import { Typeahead } from 'react-bootstrap-typeahead'
import ContractorBankForm from './ContractorBankForm'

export default ({ form = {}, setForm = (f) => f }) => {
  // const [modal, setModal] = useState(false)
  const [batchCode, setBatchCode] = useState('')
  // const today = moment().format('YYYY-MM-DD')
  const history = useHistory()
  const [contractorList, setContractorList] = useState([])

  useEffect(() => {
    postApi(`post_contractor_details`, {
      query_type: 'select',
      // status : "Paid",
      // batch_no
    })
      .then((data) => {
        console.log('data', data)
        if (data.result) {
          setContractorList(data.result)
        }
      })

      .catch((err) => {
        // alert(err);
        // setLoadSpinner(false);
      })
  }, [])

  // const [form, setForm] = useState({
  //   date: today,
  //   contractor_name: '',
  //   contractor_phone: '',
  //   contractor_email: '',
  //   contractor_address: '',
  //   contractor_code: '',
  //   query_type: 'insert',
  //   contractor_tin: '',
  // })
  // const [showBank, setShowBank] = useState(false)
  const [bankList, setBankList] = useState([])

  //   const addBank = (bank) =>
  //     setBankList((p) => [
  //       ...p,
  //       { ...bank, contractor_name: form.contractor_name },
  //     ])

  const toggle = () => {
    alert('New Contractor Created')
    history.push('/dashboard/contractor_list')
  }

  const updateBudgetCode = (description, query_type) => {
    postApi(`update-budget-code`, {
      description,
      query_type,
    })
      .then((d) => {
        console.log(d)
        if (d.result) {
          setBatchCode(d.contractor_code)
          console.log('batchCode', batchCode)
        }
      })
      .catch((err) => console.log(err))
  }

  const handleApi = () => {
    postApi(`post_contractor_details`, {
      ...form,
      bankList,
    })
      .then((data) => {
        if (data.success) {
          console.log(data)
          updateBudgetCode('contractor_code', 'update')
          setBatchCode(data.contractor_code)
          toggle()
        }
      })

      .catch((err) => {
        alert(err)
        // setLoadSpinner(false);
      })
  }

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    if (e.target.type === 'checkbox') {
      setForm((p) => ({ ...p, [name]: value }))
    } else {
      setForm((p) => ({ ...p, [name]: value }))
    }
  }

  return (
    <>
      <Card body>
        <h5 className="mb-2 text-center">Contractor Bank Details</h5>
        <Form>
          <CustomForm
            handleChange={handleChange}
            fields={[
              {
                type: 'custom',
                component: (item) => (
                  <>
                    <Form.Label>Name</Form.Label>
                    <Typeahead
                      size="sm"
                      // disabled={id ? true : false}
                      id="20"
                      onInputChange={(text) =>
                        setForm((p) => ({ ...p, contractor_name: text }))
                      }
                      onChange={(s) => {
                        if (s.length) {
                          let selected = s[0]
                          setForm((p) => ({
                            ...p,
                            ...selected,
                            contractor_tin: selected.contractor_tin_no,
                          }))
                        }
                      }}
                      options={contractorList}
                      labelKey="contractor_name"
                      // labelKey={(option) => `${option.account_name}`}
                      placeholder="Select---"
                    />
                  </>
                ),
              },

              {
                label: 'Phone',
                value: form.contractor_phone,
                name: 'contractor_phone',
              },
              {
                label: 'Contractor TIN Number',
                name: 'contractor_tin',
                value: form.contractor_tin,
              },
              {
                label: 'Email (optional)',
                name: 'contractor_email',
                value: form.contractor_email,
              },
              {
                label: 'Address (optional)',
                value: form.contractor_address,
                name: 'contractor_address',
              },
            ]}
          />

          <ContractorBankForm form={form} setForm={setForm} />
        </Form>
      </Card>
    </>
  )
}
