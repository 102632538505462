import React, { useMemo } from 'react'

// import { api } from "./config/apiUrl";

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}

const activeStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

function Attachment({
  acceptedFiles = [],
  getRootProps = (f) => f,
  getInputProps = (f) => f,
  isDragActive = (f) => f,
  isDragAccept = (f) => f,
  isDragReject = (f) => f,
}) {
  // const {
  //   acceptedFiles,
  //   getRootProps,
  //   getInputProps,
  //   isDragActive,
  //   isDragAccept,
  //   isDragReject
  // } = useDropzone({accept: 'image/*'});

  // console.log(useDropzone().acceptedFiles)
  // const fileY = {
  //   acceptedFiles,
  //   getRootProps,
  //   getInputProps,
  //   isDragActive,
  //   isDragAccept,
  //   isDragReject
  // } = useDropzone({accept: 'image/*'});

  // console.log(fileY)

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  )

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes <span>X</span>
    </li>
  ))

  // const uploadImage = () => {
  //   const data = new FormData()
  //   for (let i = 0; i < acceptedFiles.length; i++) {
  //     data.append("file", acceptedFiles[0])

  //     data.append("upload_preset", "upyo82qd")
  //     data.append("cloud_name", "dgha88o7d")

  //     fetch("https://api.cloudinary.com/v1_1/dgha88o7d/image/upload", {
  //       method: "post",
  //       body: data
  //     })
  //       .then(resp => resp.json())
  //       .then(data => {
  //         // setUrl(data.url)
  //         console.log(data)
  //       })
  //       .catch(err => console.log(err))
  //   }
  // }

  // const handleDrop = () => {
  // const uploader = acceptedFiles.map((file) => {
  //   const data = new FormData()
  //   data.append("file", file)
  //   data.append("upload_preset", "upyo82qd")
  // data.append("cloud_name","dgha88o7d")

  //  return  fetch("  https://api.cloudinary.com/v1_1/dgha88o7d/image/upload",{
  // method:"post",
  // body: data
  // })
  // .then(resp => resp.json())
  // .then(data => {
  // // setUrl(data.url)
  // console.log(data)
  // // setUploadedImages([...uploadedImage, data.url])
  // // console.log(uploadedImage)
  // })
  // .catch(err => console.log(err))

  // })
  // }

  // const [imageUrl, setImageUrl] = useState([])
  // const ima = []
  // const handleDrop = files => {
  //   // Push all the axios request promise into a single array
  //   const uploaders = acceptedFiles.map(file => {
  //     // Initial FormData
  //     const formData = new FormData();
  //     formData.append("file", file);
  //     formData.append("tags", `codeinfuse, medium, gist`);
  //     formData.append("upload_preset", "upyo82qd") // Replace the preset name with your own
  //     // formData.append("api_key", "1234567"); // Replace API key with your own Cloudinary key
  //     formData.append("cloud_name", "dgha88o7d")
  //     formData.append("timestamp", (Date.now() / 1000) | 0);

  //     // Make an AJAX upload request using Axios (replace Cloudinary URL below with your own)
  //     return fetch("https://api.cloudinary.com/v1_1/dgha88o7d/image/upload", {
  //       method: "post",
  //       body: formData
  //     }).then(resp => resp.json())
  //       .then(data => {
  //         // setUrl(data.url)

  //         const fileUrl = data.url
  //         ima.push(fileUrl)
  //         console.log(ima)
  //         console.log(fileUrl)
  //         setImageUrl([...imageUrl, fileUrl])
  //         console.log(imageUrl)
  //         console.log(data)
  //         handleApi(ima)
  //         // setUploadedImages([...uploadedImage, data.url])
  //         // console.log(uploadedImage)

  //       })
  //       .catch(err => console.log(err))

  //   })
  //   console.log(ima)

  //   // Once all the files are uploaded
  //   // axios.all(uploaders).then(() => {
  //   //   // ... perform after upload is successful operation
  //   // });

  // }

  // const handleApi = () => {

  //   const formData = new FormData();
  //   for (let i = 0; i < acceptedFiles.length; i++) {
  //     formData.append("files", acceptedFiles[0]);
  //   }

  //   formData.append('form', JSON.stringify(form))

  //   fetch(`${api}/post_images`, {
  //     method: "POST",
  //     headers: {
  //       "Content-type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       formData
  //     }),
  //   })
  //     .then((response) => response.json())
  //     .then((result) => {

  //       console.log(result)
  //       // localStorage.setItem("key", JSON.stringify(result.token));
  //       // setName(result.user)
  //       // history.push("/homepage/payment_table");

  //       // console.log(result);
  //     })

  //     .catch((err) => {
  //       // console.log(err);
  //       alert(err);
  //     });

  // };

  return (
    <div className="mv-2">
        <h4>Upload Attachment</h4>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>

     {files.length ? <aside>
        <h4>Files</h4>
        <ul>{files}</ul>
      </aside> : null}
    </div>
  )
}

export default Attachment
