import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Card, Button, Form } from '@themesberg/react-bootstrap'

import BackButton from '../../../components/BackButton'
import CustomForm from '../../../components/CustomForm'
import { postApi } from '../../../redux/api'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router-dom'
import { Routes } from '../../../routes'

export default ({ match }) => {
  const [form, setForm] = useState({
    account_name: '',
    account_description: '',
    account_number: '',
    sort_code: '',
    bank_name: '',
    account_type: '',
  })
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const id = match.params.id || ''

  const param = Object.entries(match.params).length

  //   const [disabled] = useState(false)

  const handleChange = ({ target: { name, value } }) => {
    setForm((prev) => ({ ...prev, [name]: value }))
  }

  const handleReset = () => {
    setForm({
      account_name: '',
      account_description: '',
      account_number: '',
      sort_code: '',
      bank_name: '',
      
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const query_type = param > 0 ? 'UPDATE' : 'INSERT'
    setLoading(true)
    postApi(`select_mda_bank_details/${id}`, {
      // form: { ...form, id },
      query_type,
      ...form,
    })
      .then((data) => {
        setLoading(false)
        alert('Submitted!')
        console.log(data)
        handleReset()
        history.push(Routes.bank.bankIndex)
      })
      .catch((error) => {
        setLoading(false)
        console.error(error)
      })
  }

  useEffect(() => {
    postApi(`select_mda_bank_details/${id}`, {
      query_type: 'select_by_id',
    }).then((data) => {
      console.log(data)
      if (data?.result?.length) {
        setForm(data.result[0])
      }
    })
  }, [id])

  return (
    <>
      <BackButton />

      <Card>
        <Card.Body>
          <h5 className="mb-4">
            <FontAwesomeIcon icon={faEdit} className="me-2" /> Bank Details
          </h5>
          <Form onSubmit={handleSubmit}>
            <CustomForm
              handleChange={handleChange}
              fields={[
                {
                  label: 'Account Type',
                  value: form.account_name,
                  name: 'account_name',
                },
                {
                  label: 'Bank Name',
                  value: form.bank_name,
                  name: 'bank_name',
                },
                {
                  label: 'Account Number',
                  value: form.account_number,
                  name: 'account_number',
                },
                {
                  label: 'Sort code',
                  value: form.sort_code,
                  name: 'sort_code',
                },
                {
                  label: 'Account Description',
                  value: form.account_description,
                  name: 'account_description',
                },
              ]}
            />

            <div className="mt-3">
              <Button variant="primary" type="submit" loading={loading}>
                {param > 0 ? 'Update' : 'Save'}
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  )
}
