import React from 'react'
import { Button, ButtonGroup, Card } from '@themesberg/react-bootstrap'

import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHourglass } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment-timezone'
import { postApi } from '../../../redux/api'
import { Routes } from '../../../routes'
import CustomTable from '../../../components/CustomTable'

function ContractorScheduleIndex({ match, type = null }) {
  const history = useHistory()
  const batch_no = match.params.batch_no
  const [paymentScheduleTable, setPaymentScheduleTable] = useState([])
  const [tresuryDetails, setTresuryDetails] = useState({})
  const [changeAmount, setChangeAmount] = useState(false)
  const [modal, setModal] = useState(false)
  const [contractorTable, setContractorTable] = useState([])
  const toggle = () => setModal(!modal)
  const [amountIndex, setAmountIndex] = useState(0)
  const [paymentDetails, setPaymentDetails] = useState([])
  const [openPdf, setOpenPdf] = useState(true)
  // const [tsaFundingList, setTsaFundingList] = useState([])
  const [form, setForm] = useState({
    batch_number: '',
    date: '',
    no_of_mda: '',
    no_of_payments: '',
    total_amount: '',
    cheque_number: '',
    query_type: 'insert',
    status: 'uncommited',
    arabic_date: '',
  })

  console.log('batch_no', batch_no)

  useEffect(() => {
    postApi(`post_contractor_schedule`, {
      query_type: 'select_by_batch',
      // status : "Paid",
      // batch_no
    })
      .then((data) => {
        console.log('data', data)
        if (data.result) {
          setContractorTable(data.result)
        }
      })

      .catch((err) => {
        // alert(err);
        // setLoadSpinner(false);
      })
  }, [])

  //   let list = []
  //   data.forEach((item) => {
  //     if (
  //       item.approval_no.indexOf(filterText) === -1 &&
  //       item.mda_name.toLowerCase().indexOf(filterText.toLowerCase()) === -1
  //     )
  //       return
  //     else list.push(item)
  //   })

  const fields = [
    {
      title: 'S/N',
      //   value: 'collected_date',
      custom: true,
      component: (item, index) => <div>{index + 1}</div>,
    },
    {
      title: 'Date',
      //   value: 'collected_date',
      custom: true,
      component: (item, index) => (
        <div>{moment(item.date).format('YYYY-DD-MM')}</div>
      ),
    },
    {
      title: 'Batch Code',
      // value: 'batch_id',
      custom: true,
      component: (item) => (
        <div>{item.batch_id}</div>
      ),
    },
    {
      title: 'MDA Name',
      value: 'mda_name',
    },
    {
      title: 'Amount',
      custom: true,
      component: (item) => (
        <div className="text-right">
          {parseFloat(item.amount).toLocaleString('en')}
        </div>
      ),
    },

    {
      title: 'Action',
      custom: true,
      component: (item, index) => (
        <Button
          className="btn"
          size="sm"
          color="success"
          onClick={() => {
            history
              .push

              (`${Routes.paymentSchedule.viewStSchedule}/${item.batch_id}`)
              //   `/dashboard/payapp?mda_code=${item.mda_code}&name=existing&economic_code=${item.economic_code}&approval_no=${item.approval_no}&imageId=${item.imageId}&description_code=${item.description_code}&mda_name=${item.mda_name}&amount=${item.amount}`,
          }}
        >
          View
        </Button>
      ),
    },
    // {
    //   title: 'Amount',
    //   custom: true,
    //   component: (item) => (
    //     <div className="text-end">{formatNumber(item.amount)}</div>
    //   ),
    //   //   value: 'amount',
    // },
  ]

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Payment Schedule</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <Button
            onClick={() => history.push(Routes.paymentSchedule.newStSchedule)}
            className="me-2"
          >
            Create new
          </Button>
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">
              Share
            </Button>
            <Button variant="outline-primary" size="sm">
              Export
            </Button>
          </ButtonGroup>
        </div>
      </div>

      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="p-0">
          <CustomTable
            fields={fields}
            data={contractorTable}
            // currentPage={currentPage}
            // handlePageChange={handlePageChange}
          />
        </Card.Body>
      </Card>
    </>
  )
}

export default ContractorScheduleIndex
