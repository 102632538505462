import React from 'react'
import { Col, Row, Card, Form } from '@themesberg/react-bootstrap'
import SelectInput from '../../../components/SelectInput'
import { mainTreasuryModules, subTreasuryModules } from './modules'
import { useState } from 'react'
import { postApi } from '../../../redux/api'
import { useSelector } from 'react-redux'
import CustomButton from '../../../components/CustomButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import BackButton from '../../../components/BackButton'

export default () => {
  const user = useSelector((state) => state.auth.user)
  const [form, setForm] = useState({ accessTo: [], role: 'main_treasury' })
  const [loading, setLoading] = useState(false)

  const handleChange = ({ target: { name, value } }) =>
    setForm((p) => ({ ...p, [name]: value }))

  const handleCheckboxChange = (i) => {
    if (form.accessTo.includes(i)) {
      // console.log('exist')
      let newList = form.accessTo.filter((a) => a !== i)
      // console.log(newList)
      setForm((p) => ({ ...p, accessTo: newList }))
    } else {
      // console.log('new')
      let newList = [...form.accessTo, i]
      // console.log(newList)
      setForm((p) => ({ ...p, accessTo: newList }))
    }
  }

  const createUser = () => {
    setLoading(true)
    postApi(`treasury-app/sign_up`, {
      ...form,
      accessTo: form.accessTo.join(','),
      role: user.role,
    })
      .then((resp) => {
        setLoading(false)
        if (resp.success) {
          alert(resp.msg)
          setForm({ accessTo: [], role: user.role })
        } else {
          alert(resp.msg)
        }
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
        alert('An error occurred')
      })
  }

  return (
    <>
      <BackButton />
      <Card border="light" className="bg-white shadow-sm mb-4">
        {/* {JSON.stringify(form)} */}
        <Card.Body>
          <h5 className="mb-4">Create New User</h5>
          <Form>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group id="firstName">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="username"
                    value={form.username}
                    onChange={handleChange}
                    placeholder="Enter your username"
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="lastName">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="fullname"
                    value={form.fullname}
                    onChange={handleChange}
                    placeholder="Enter your Full Name"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6} className="mb-3">
                <Form.Group id="emal">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    required
                    type="password"
                    placeholder="***********"
                    name="password"
                    value={form.password}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="phone">
                  <SelectInput
                    label="Role"
                    size="md"
                    options={['main_treasury', 'sub_treasury']}
                    name="role"
                    value={form.role}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <h5 className="my-4">Permission and Access Level</h5>
            <Row style={{ margin: 0 }}>
              {form.role === 'main_treasury'
                ? mainTreasuryModules.map((it, i) => (
                    <Col md="4" key={i}>
                      <input
                        type="checkbox"
                        checked={form.accessTo.includes(it)}
                        onChange={() => handleCheckboxChange(it)}
                      />
                      <label>{it}</label>
                    </Col>
                  ))
                : subTreasuryModules.map((it, i) => (
                    <Col md="4" key={i}>
                      <input
                        type="checkbox"
                        checked={form.accessTo.includes(it)}
                        onChange={() => handleCheckboxChange(it)}
                      />
                      <label>{it}</label>
                    </Col>
                  ))}
                  <Col md="4">
                      <input
                        type="checkbox"
                        checked={form.accessTo.includes("User Admin")}
                        onChange={() => handleCheckboxChange("User Admin")}
                      />
                      <label>{"User Admin"}</label>
                    </Col>
            </Row>

            <div className="mt-3 text-center">
              <CustomButton
                variant="primary"
                type="submit"
                onClick={createUser}
                loading={loading}
              >
                <FontAwesomeIcon icon={faPlus} /> Create user
              </CustomButton>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  )
}
