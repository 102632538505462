import { Form } from '@themesberg/react-bootstrap'
import React from 'react'

function SelectInput(props) {
  const { options = [], label = null, labelClass, size } = props
  return (
    <Form.Group>
      {label && <Form.Label className={labelClass}>{label}</Form.Label>}
      <Form.Select
        defaultValue={props.defaultValue || '0'}
        {...props}
        size={size || 'sm'}
      >
        <option value="">--Select--</option>
        {options.map((o, i) => (
          <option key={i} value={o}>
            {o}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  )
}

export default SelectInput
