import React, { useCallback, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faCog,
  faHome,
  faPlus,
  faSearch,
  faTasks,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import {
  Col,
  Row,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,
  Card,
  Table,
} from '@themesberg/react-bootstrap'

import { useHistory, useParams } from 'react-router-dom'
import { useState } from 'react'
import { fetchApi } from '../../../redux/api'
import useQuery from '../../../hooks/useQuery'
import { Routes } from '../../../routes'
import { useSelector } from 'react-redux'

function UsersList({ match }) {
  const history = useHistory()
  const user = useSelector((state) => state.auth.user)
  const [userList, setUserList] = useState([])

  const getUsers = useCallback(() => {
    fetchApi(`users`)
      .then((d) => {
        if (d && d.users) {
          setUserList(d.users)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }, [])

  useEffect(() => {
    getUsers()
  }, [getUsers])

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <Button
          className="me-2"
          onClick={() => history.push(`${Routes.userAdmin.editUser}`)}
        >
          <FontAwesomeIcon icon={faPlus} className="me-2" /> Create new user
        </Button>
        <div className="d-block mb-4 mb-md-0">
          <h4>Users</h4>
          {/* <p className="mb-0">Your web analytics dashboard template.</p> */}
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">
              Share
            </Button>
            <Button variant="outline-primary" size="sm">
              Export
            </Button>
          </ButtonGroup>
        </div>
      </div>
      {/* {JSON.stringify(userList)} */}
      <Card>
        <Table>
          <thead>
            <tr>
              <th>S/N</th>
              <th>Name</th>
              <th>Username</th>
              <th>Department</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {userList.map((user, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{user.fullname}</td>
                <td>{user.username}</td>
                <td>{user.role}</td>
                <td>Active</td>
                <td>
                  <Button size='sm' className='btn-danger'><FontAwesomeIcon icon={faTrash}/> Delete</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>
    </>
  )
}

export default UsersList
