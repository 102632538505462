/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import { Button, Modal } from '@themesberg/react-bootstrap'
import React, { useState } from 'react'

const BatchModal = (props) => {
  const {
    className,

    modal,
    toggle,
    batchCode,
  } = props

  const [backdrop] = useState(true)
  const [keyboard] = useState(true)

  return (
    <div>
      <Modal
        show={modal}
        onHide={toggle}
        className={className}
        backdrop={backdrop}
        keyboard={keyboard}
      >
        <Modal.Body
          style={{ color: 'white', backgroundColor: 'rgb(25, 135, 84)' }}
          className="text-center"
        >
          <h4 className='text-white'>Submitted Successfully</h4>

          <h4 className='text-white'>Your Reference Number is: {batchCode}</h4>
        </Modal.Body>
        <Modal.Footer
          style={{ color: 'white', backgroundColor: 'rgb(25, 135, 84)' }}
        >
          <Button
            onClick={toggle}
            style={{ backgroundColor: 'white', color: 'black' }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default BatchModal
